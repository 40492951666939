import { FieldType } from '@counsel-project/counsel-external-api'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useCallback } from 'react'
import { fieldTypes, getFieldTypeLabel } from './_helpers'

type TemplateFieldTypeSelectorProps = {
  value: FieldType
  onChange: (value: FieldType) => void
}

const TemplateFieldTypeSelector = ({ value, onChange }: TemplateFieldTypeSelectorProps) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      onChange(event.target.value as FieldType)
    },
    [onChange]
  )

  return (
    <Select value={value} onChange={handleChange} fullWidth>
      {fieldTypes.map((fieldType) => (
        <MenuItem key={fieldType} value={fieldType}>
          {getFieldTypeLabel(fieldType)}
        </MenuItem>
      ))}
    </Select>
  )
}

export default TemplateFieldTypeSelector
