import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

const Footer = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        py: 4,
      }}
    >
      <Typography variant="body2" color="text.secondary" align="center">
        {'© '}
        {new Date().getFullYear()} Clinical Notes Inc.
        <br />
        By using this site, you agree to our{' '}
        <Link href="https://www.clinicalnotes.ai/terms-of-service.html">Terms of Service</Link>
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        See our <Link href="https://www.clinicalnotes.ai/privacy-policy.html">Privacy Policy</Link>
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        App Version 1
      </Typography>
    </Box>
  )
}

export default Footer
