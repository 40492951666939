import { RAccess, RateLimit, RateLimitType } from '@counsel-project/counsel-keys-api'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useState } from 'react'
import ClosableDialog from '../../components/ClosableDialog'
import { keysRequest } from '../../util/api/keys-api'
import getToken from '../../util/auth/getToken'
import { MS_INTERVALS } from './RateLimitCard'

export const options = Object.entries(MS_INTERVALS).map(([value, label]) => ({
  value: Number(value),
  label,
}))

type AddRateLimitDialogProps = {
  accessId: string
  limits: RateLimit[]
  open: boolean
  onClose: () => void
  onUpdateAccess: (access: RAccess) => void
}

const AddRateLimitDialog = ({
  accessId,
  limits,
  open,
  onClose,
  onUpdateAccess,
}: AddRateLimitDialogProps) => {
  const [limitValue, setLimitValue] = useState(100)
  const [intervalValue, setIntervalValue] = useState(1000)
  const [typeValue, setTypeValue] = useState<RateLimitType>('field_generation')
  const [loading, setLoading] = useState(false)

  const handleCreateRateLimit = useCallback(async () => {
    try {
      setLoading(true)

      const token = await getToken()
      if (!token) return

      const { result } = await keysRequest.admin.access.update({
        token,
        accessId,
        limits: [
          ...limits,
          {
            limit: limitValue,
            interval: intervalValue,
            type: typeValue,
            current: 0,
            reset: new Date(new Date().getTime() + intervalValue).toISOString(),
          },
        ],
      })

      onUpdateAccess(result)
      onClose()
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [accessId, limits, limitValue, intervalValue, onUpdateAccess, onClose, typeValue])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Add Rate Limit">
      <DialogContent>
        <Typography variant="body1" gutterBottom fontWeight={500}>
          Type
        </Typography>
        <Select
          value={typeValue}
          onChange={(e) => setTypeValue(e.target.value as RateLimitType)}
          fullWidth
        >
          <MenuItem value="field_generation">Field Generation</MenuItem>
          <MenuItem value="transcription_minutes">Transcription Minutes</MenuItem>
        </Select>
        <Typography variant="body1" gutterBottom fontWeight={500} sx={{ mt: 2 }}>
          Limit
        </Typography>
        <TextField
          placeholder="Limit"
          value={limitValue}
          onChange={(e) => setLimitValue(Number(e.target.value))}
          fullWidth
        />
        <Typography variant="body1" gutterBottom fontWeight={500} sx={{ mt: 2 }}>
          Interval
        </Typography>
        <Select
          value={intervalValue}
          onChange={(e) => setIntervalValue(e.target.value as number)}
          fullWidth
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleCreateRateLimit} disabled={loading}>
          Create
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default AddRateLimitDialog
