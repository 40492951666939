import { FieldType, InputField } from '@counsel-project/counsel-external-api'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import ClosableDialog from '../../components/ClosableDialog'
import TemplateFieldTypeSelector from './TemplateFieldTypeSelector'

type TemplateAddFieldDialogProps = {
  fields: InputField[]
  open: boolean
  onClose: () => void
  onAddField: (field: InputField) => void
}

const TemplateAddFieldDialog = ({
  fields,
  open,
  onClose,
  onAddField,
}: TemplateAddFieldDialogProps) => {
  const [stagedName, setStagedName] = useState('')
  const [stagedType, setStagedType] = useState<FieldType>('string')
  const [stagedId, setStagedId] = useState('')

  const handleAddField = useCallback(() => {
    if (fields.some((field) => field.id === stagedId)) {
      toast.error('Field ID must be unique')
      return
    }

    onAddField({
      id: stagedId,
      name: stagedName,
      type: stagedType,
      model: 0,
      instructions: '',
      temperature: 0.4,
    } as InputField)
    onClose()
  }, [onAddField, onClose, stagedId, stagedName, stagedType, fields])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Add Field">
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Field ID
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          sx={{ mb: 2 }}
          value={stagedId}
          onChange={(e) => setStagedId(e.target.value)}
        />
        <Typography variant="body1" gutterBottom>
          Field Name
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          sx={{ mb: 2 }}
          value={stagedName}
          onChange={(e) => setStagedName(e.target.value)}
        />
        <Typography variant="body1" gutterBottom>
          Field Type
        </Typography>
        <TemplateFieldTypeSelector value={stagedType} onChange={setStagedType} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAddField} disabled={!stagedId || !stagedName}>
          Add Field
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default TemplateAddFieldDialog
