import Skeleton from '@mui/material/Skeleton'
import StyledEmptyTableRow from './StyledEmptyTableRow'
import StyledTableCell from './StyledTableCell'

export type RowSkeletonProps = {
  colSpan: number
  height?: number
}

const RowSkeleton = ({ colSpan, height = 36 }: RowSkeletonProps) => {
  return (
    <StyledEmptyTableRow>
      <StyledTableCell colSpan={colSpan}>
        <Skeleton variant="text" height={height} />
      </StyledTableCell>
    </StyledEmptyTableRow>
  )
}

export default RowSkeleton
