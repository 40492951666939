import { RAccess } from '@counsel-project/counsel-keys-api'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useState } from 'react'
import ClosableDialog from '../../components/ClosableDialog'
import { keysRequest } from '../../util/api/keys-api'
import getToken from '../../util/auth/getToken'
import handleError from '../../util/handleError'

type AddCustomerDialogProps = {
  accessId: string
  open: boolean
  onClose: () => void
  onUpdateAccess: (access: RAccess) => void
}

const AddCustomerDialog = ({ accessId, open, onClose, onUpdateAccess }: AddCustomerDialogProps) => {
  const [customerId, setCustomerId] = useState('')

  const [loading, setLoading] = useState(false)

  const handleAddCustomer = useCallback(async () => {
    try {
      if (!customerId) return

      setLoading(true)

      const token = await getToken()
      if (!token) return

      const { result } = await keysRequest.admin.access.update({
        token,
        accessId,
        metronomeId: customerId,
      })

      onUpdateAccess(result)
      onClose()
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [accessId, customerId, onUpdateAccess, onClose])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Add Customer ID">
      <DialogContent>
        <Typography variant="body1" gutterBottom fontWeight={500}>
          Name
        </Typography>
        <TextField
          placeholder="Customer id here..."
          value={customerId}
          onChange={(e) => setCustomerId(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleAddCustomer}>
          Add
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default AddCustomerDialog
