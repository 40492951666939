import { InputField, OutputField, RTemplate } from '@counsel-project/counsel-external-api'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useRef, useState } from 'react'
import TemplateFieldExamples from './TemplateFieldExamples'
import TemplateFields from './TemplateFields'
import { ALL_TEMPLATE_OPTIONS, TemplateShowOptions } from './_helpers'

export type SavedOptions = {
  instructions: string
  fields: InputField[]
  examples?: OutputField[]
}

export type TemplateEditorProps = {
  properties?: TemplateShowOptions[]
  template: RTemplate
  onSave: (saved: SavedOptions) => void
  disabled?: boolean
  loading?: boolean
  disableExamples?: boolean
  onExamplesUpdated: (examples: OutputField[]) => void
}

const TemplateEditor = ({
  properties = ALL_TEMPLATE_OPTIONS,
  template,
  onSave,
  disabled,
  loading,
  disableExamples,
  onExamplesUpdated,
}: TemplateEditorProps) => {
  const [stagedFields, setStagedFields] = useState<InputField[]>(template.fields)
  const [examplesShown, setExamplesShown] = useState(false)
  const [stagedInstructions, setStagedInstructions] = useState<string>(template.instructions)
  const [stagedExamples, setStagedExamples] = useState<OutputField[]>(template.examples || [])

  const initialTemplate = useRef<RTemplate | null>(template)

  const handleSave = useCallback(() => {
    onSave({
      ...template,
      instructions: stagedInstructions,
      fields: stagedFields,
      examples: stagedExamples.length !== 0 ? stagedExamples : undefined,
    })
    initialTemplate.current = {
      ...template,
      instructions: stagedInstructions,
      fields: stagedFields,
      examples: stagedExamples,
    }
  }, [onSave, stagedInstructions, stagedFields, stagedExamples, template])

  const handleUpdateExamples = useCallback(
    (examples: OutputField[]) => {
      setStagedExamples(examples)
      onExamplesUpdated(examples)
    },
    [onExamplesUpdated]
  )

  useEffect(() => {
    if (!initialTemplate.current) return
    if (
      JSON.stringify(initialTemplate.current.fields) === JSON.stringify(stagedFields) &&
      initialTemplate.current.instructions === stagedInstructions
    )
      return

    const timeout = setTimeout(() => {
      handleSave()
    }, 4000)
    return () => clearTimeout(timeout)
  }, [handleSave, stagedFields, stagedInstructions])

  return (
    <Container sx={{ p: { xs: 1, sm: 2 } }}>
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={8}>
          <Grid container spacing={1} alignItems="start">
            <Grid item xs>
              <Typography variant="h4" color="text.primary">
                {template.name}
              </Typography>
              {properties.includes('id') && (
                <Typography variant="body1" color="text.primary">
                  ID: {template.identifier}
                </Typography>
              )}
            </Grid>
            {properties.includes('save') && (
              <Grid item>
                <Button onClick={handleSave} sx={{ mt: 2 }} disabled={loading || disabled}>
                  Save
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        {properties.includes('instructions') && (
          <Grid item xs={12} sm={8}>
            <Paper sx={{ p: 2, mb: 2 }} elevation={0}>
              <Typography variant="h6" color="text.primary">
                Template Instructions
              </Typography>
              <Typography variant="body1" gutterBottom color="text.secondary">
                Include general instructions for the AI to follow when generating fields.
              </Typography>

              <TextField
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={stagedInstructions}
                onChange={(e) => setStagedInstructions(e.target.value)}
                placeholder="These are general instructions for the AI to follow when generating fields."
              />
            </Paper>
          </Grid>
        )}
        <Grid item xs={12} sm={examplesShown ? 6 : 8}>
          <TemplateFields
            fields={stagedFields}
            setFields={setStagedFields}
            examplesShown={examplesShown}
            onToggleExamples={() => setExamplesShown((prev) => !prev)}
            properties={properties}
          />
        </Grid>
        {examplesShown && properties.includes('examples') && (
          <Grid item xs={12} sm={6}>
            <TemplateFieldExamples
              disableExamples={disableExamples}
              accessId={template.accessId}
              name={template.name}
              instructions={stagedInstructions}
              fields={stagedFields}
              initialExamples={template.examples}
              onExamplesUpdated={handleUpdateExamples}
            />
          </Grid>
        )}
        {properties.includes('save') && (
          <Grid item xs={12} sm={examplesShown ? 12 : 8} container justifyContent="end">
            <Button onClick={handleSave} sx={{ mt: 2 }} disabled={loading || disabled}>
              Save
            </Button>
          </Grid>
        )}
      </Grid>
    </Container>
  )
}

export default TemplateEditor
