import { RecorderShowOptions } from './_helpers'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { useMemo, useState } from 'react'
import TabsPanel from './TabsPanel'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/CloseRounded'
import Recorder from './Recorder'

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 10000,
  height: '100%',
  display: 'flex',
  width: '300px',
  flexDirection: 'column',
  justifyContent: 'start',
  backgroundColor: theme.palette.background.paper,
}))

export type RecorderPanelProps = {
  properties: RecorderShowOptions[]
  hidden?: boolean
  absolute?: boolean
  onClose?: () => void
}

const RecorderPanel = ({ properties, hidden, absolute, onClose }: RecorderPanelProps) => {
  const [activeTab, setActiveTab] = useState<'live-session' | 'dictation'>('live-session')

  const panelPosition = useMemo(() => {
    if (properties.includes('left')) {
      return 'left'
    } else {
      return 'right'
    }
  }, [properties])

  return (
    <StyledBox
      sx={{
        display: hidden ? 'none' : 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        width: '300px',
        position: !absolute ? 'fixed' : 'absolute',
        top: 0,
        right: panelPosition === 'right' ? 0 : 'auto',
        left: panelPosition === 'left' ? 0 : 'auto',
        zIndex: 10000,
        height: '100%',
        backgroundColor: 'transparent',
      }}
    >
      {properties.includes('top') && (
        <Box sx={{ p: 2, alignSelf: panelPosition === 'right' ? 'end' : 'start' }}>
          <IconButton onClick={onClose} sx={{ p: 2, m: 1 }}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: 'background.paper',
          boxShadow: 5,
          borderRadius: 1,
        }}
      >
        <TabsPanel
          tabs={[
            { id: 'live-session', label: 'Live Session' },
            { id: 'dictation', label: 'Dictation' },
          ]}
          activeTab={activeTab}
          onClick={(id) => {
            setActiveTab(id as 'live-session' | 'dictation')
          }}
        />
        <Box sx={{ p: 2 }}>
          <Recorder dictation={activeTab === 'dictation'} />
        </Box>
      </Box>
      {!properties.includes('top') && (
        <Box sx={{ p: 2, alignSelf: panelPosition === 'right' ? 'end' : 'start' }}>
          <IconButton onClick={onClose} sx={{ p: 2, m: 1 }}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </StyledBox>
  )
}

export default RecorderPanel
