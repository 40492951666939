import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded'
import { alpha, styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { NavItem } from '../_helpers'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

type FlippableChevronProps = {
  flipped: boolean
}

const FlippableChevron = styled(ChevronRightIcon, {
  shouldForwardProp: (prop) => prop !== 'flipped',
})<FlippableChevronProps>(({ flipped }) => ({
  transform: flipped ? 'rotate(90deg)' : 'rotate(0deg)',
}))

type PanelItemProps = {
  active: boolean
}

const PanelItem = styled('a')<PanelItemProps>(({ theme, active }) => ({
  color: active ? theme.palette.primary.main : theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.primary.main,
    ...(active && {
      backgroundColor: alpha(theme.palette.primary.main, 0.15),
    }),
  },
  backgroundColor: active ? alpha(theme.palette.primary.main, 0.1) : 'transparent',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5),
  paddingX: theme.spacing(1),
  transition: 'background-color 0.1s',
}))

type LeftPanelItemProps = {
  item: NavItem
  active: boolean
}

const LeftPanelItem = ({ item, active }: LeftPanelItemProps) => {
  const navigate = useNavigate()

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault()
    navigate(item.href)
  }

  return (
    <>
      <PanelItem href={item.href} active={active} onClick={onClick}>
        <Typography component="span" sx={{ mt: '6px', ml: 1 }}>
          {item.children && <FlippableChevron flipped={active} />}
          {item.icon}
        </Typography>

        <Typography component="span" fontWeight={500} sx={{ ml: 1 }}>
          {item.title}
        </Typography>
      </PanelItem>
      {item.children && active && (
        <Box sx={{ ml: 2, display: 'flex', flexDirection: 'column' }}>
          {item.children.map((child) => (
            <Box sx={{ pt: 1 }}>
              <LeftPanelItem
                item={child}
                active={window.location.pathname.startsWith(child.href)}
              />
            </Box>
          ))}
        </Box>
      )}
    </>
  )
}

export default LeftPanelItem
