import { RTemplate } from '@counsel-project/counsel-external-api/common/database/RTemplate'
import DeleteIcon from '@mui/icons-material/DeleteRounded'
import TemplateIcon from '@mui/icons-material/DescriptionRounded'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import StyledTableCell from './StyledTableCell'
import StyledTableRow from './StyledTableRow'

type TemplateNameChipProps = {
  name: string
}

const TemplateNameChip = ({ name }: TemplateNameChipProps) => {
  return (
    <Chip icon={<TemplateIcon />} label={name} color="default" size="small" sx={{ py: 2, px: 1 }} />
  )
}

export type TemplateRowProps = {
  isMobile?: boolean
  data: RTemplate
  onClick?: (data: RTemplate) => void
  onClickDelete?: (data: RTemplate) => void
}

const TemplateRow = ({ data, onClick, onClickDelete }: TemplateRowProps) => {
  const { name, type } = data

  const handleClickDelete = (e: React.MouseEvent) => {
    e.stopPropagation()
    onClickDelete?.(data)
  }

  return (
    <StyledTableRow onClick={() => onClick?.(data)}>
      <StyledTableCell
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 400,
        }}
      >
        <Typography
          variant="subtitle2"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          <TemplateNameChip name={name} />
        </Typography>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Typography variant="subtitle2">{type}</Typography>
      </StyledTableCell>
      <StyledTableCell align="right">
        <IconButton onClick={handleClickDelete}>
          <DeleteIcon />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default TemplateRow
