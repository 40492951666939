import React, { useState } from 'react'

import Button from '@mui/material/Button'
import { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import ClosableDialog from './ClosableDialog'

export type ConfirmDialogProps = {
  titleText?: string
  text: string
  buttonText?: string
  requiresInput?: string
  open: boolean
  color?: 'error' | 'success' | 'warning' | 'info'
  onClose: () => void
  onConfirm: () => void
  loading?: boolean
} & DialogProps

const ConfirmDialog = ({
  titleText = 'Confirmation',
  text,
  buttonText = 'Confirm',
  requiresInput,
  open,
  color = 'error',
  onClose,
  onConfirm,
  loading,
  ...props
}: ConfirmDialogProps) => {
  const [confirmInput, setConfirmInput] = useState('')

  const handleUpdateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmInput(e.target.value)
  }

  return (
    <ClosableDialog open={open} onClose={onClose} {...props} titleText={titleText}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">{text}</Typography>
          </Grid>
        </Grid>
        {!!requiresInput && (
          <Grid container justifyContent="space-between">
            <Grid item xs={12}>
              <Typography variant="body1" display="inline">
                To confirm, type{' '}
                <Typography fontWeight={500} display="inline">
                  {requiresInput}
                </Typography>{' '}
                below
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth value={confirmInput} onChange={handleUpdateInput} />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color={color} disabled={loading}>
          {buttonText}
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default ConfirmDialog
