import { alpha, styled } from '@mui/material/styles'
import TableRow from '@mui/material/TableRow'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: 44,
  ':hover': {
    '& td': {
      backgroundColor: alpha(theme.palette.background.paper, 0.3),
    },
  },
  '&:nth-of-type(odd) td': {
    backgroundColor: alpha(theme.palette.background.default, 0.5),
    '&:hover': {
      backgroundColor: alpha(theme.palette.background.default, 0.3),
    },
  },
  td: {
    cursor: 'pointer',
    border: 0,
    '&:first-child': {
      borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
    },
    '&:last-child': {
      borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
    },
    transition: 'background 0.1s ease-in-out',
  },
}))

export default StyledTableRow
