import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { useTitle } from 'react-use'
import PageContainer from '../components/layout/PageContainer'

const NotFoundPage = () => {
  useTitle('Clinical Notes AI - Page Not Found')

  return (
    <PageContainer>
      <Box sx={{ mt: 4, p: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom textAlign="center">
          Page not found
        </Typography>
        <Typography variant="body1" gutterBottom textAlign="center">
          Sorry, we couldn’t find the page you’re looking for.
        </Typography>
        <Typography variant="body1" gutterBottom textAlign="center">
          <Link href="/">Go to the home page</Link>
        </Typography>
      </Box>
    </PageContainer>
  )
}

export default NotFoundPage
