import { RAccess, RateLimit } from '@counsel-project/counsel-keys-api'
import DeleteIcon from '@mui/icons-material/DeleteRounded'
import EditIcon from '@mui/icons-material/EditRounded'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { keysRequest } from '../../util/api/keys-api'
import getToken from '../../util/auth/getToken'

export const formatLargeNumber = (num: number) => {
  if (num < 1000) return num
  if (num < 1000000) return `${Math.round(num / 1000)}K`
  return `${Math.round(num / 1000000)}M`
}

export const MS_INTERVALS = {
  1000: 'second',
  [1000 * 60]: 'minute',
  [1000 * 60 * 60]: 'hour',
  [1000 * 60 * 60 * 24]: 'day',
  [1000 * 60 * 60 * 24 * 7]: 'week',
  [1000 * 60 * 60 * 24 * 7 * 2]: '2 weeks',
  [1000 * 60 * 60 * 24 * 7 * 4]: '4 weeks',
  [1000 * 60 * 60 * 24 * 30]: 'month',
  [1000 * 60 * 60 * 24 * 30 * 3]: '3 months',
  [1000 * 60 * 60 * 24 * 30 * 6]: '6 months',
  [1000 * 60 * 60 * 24 * 365]: 'year',
}

type RateLimitCardProps = {
  admin: boolean
  accessId: string
  limits: RateLimit[]
  limit: RateLimit
  onUpdateAccess: (access: RAccess) => void
  onClickEdit: (limit: RateLimit) => void
}

const RateLimitCard = ({
  admin,
  accessId,
  limits,
  limit,
  onUpdateAccess,
  onClickEdit,
}: RateLimitCardProps) => {
  const [loading, setLoading] = useState(false)

  const handleEdit = useCallback(() => {
    onClickEdit(limit)
  }, [onClickEdit, limit])

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true)

      const token = await getToken()
      if (!token) return

      const { result } = await keysRequest.admin.access.update({
        token,
        accessId,
        limits: limits.filter((l) => l !== limit),
      })

      onUpdateAccess(result)
    } catch (error) {
      toast.error('Failed to delete rate limit')
    } finally {
      setLoading(false)
    }
  }, [accessId, limits, limit, onUpdateAccess])

  const intervalType = useMemo(() => {
    if (limit.type === 'field_generation') {
      return 'AI generated fields'
    } else if (limit.type === 'transcription_minutes') {
      return 'Transcription minutes'
    }
  }, [limit])

  const limitInterval = useMemo(() => {
    const interval = MS_INTERVALS[limit.interval]
    return interval ? `${intervalType} / ${interval}` : 'Unknown'
  }, [limit, intervalType])

  return (
    <Paper sx={{ p: 2 }} elevation={0}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Typography variant="h6">{limitInterval}</Typography>
        </Grid>
        {admin && (
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <IconButton onClick={handleEdit} disabled={loading}>
                  <EditIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton onClick={handleDelete} disabled={loading}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <LinearProgress
            variant="determinate"
            value={Math.round((limit.current / limit.limit) * 100)}
            sx={{
              height: 4,
              borderRadius: 5,
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="text.secondary">
            {formatLargeNumber(Math.round(limit.current))} / {formatLargeNumber(limit.limit)}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default RateLimitCard
