import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useCallback } from 'react'
import { ExampleSession, examples } from './examples'

type ExampleSelectorProps = {
  value: ExampleSession | null
  onChange: (value: ExampleSession) => void
}

const ExampleSelector = ({ value, onChange }: ExampleSelectorProps) => {
  const selectedExampleName = examples.find((example) => example.name === value?.name)?.name

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const example = examples.find((example) => example.name === event.target.value)
      if (example) onChange(example)
    },
    [onChange]
  )

  return (
    <Select
      value={selectedExampleName}
      onChange={handleChange}
      fullWidth
      placeholder="Select an example session"
      sx={{
        width: 200,
        height: 44,
      }}
      size="small"
    >
      {examples.map((example) => (
        <MenuItem key={example.name} value={example.name}>
          {example.name}
        </MenuItem>
      ))}
    </Select>
  )
}

export default ExampleSelector
