import { FieldType, InputField } from '@counsel-project/counsel-external-api'
import theme from '../../util/theme'

export const randomUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const getFieldTypeLabel = (type: FieldType) => {
  switch (type) {
    case 'number':
      return 'Number'
    case 'date':
      return 'Date'
    case 'end_time':
      return 'End Time'
    case 'start_time':
      return 'Start Time'
    case 'boolean':
      return 'Boolean'
    case 'single_choice':
      return 'Single Choice'
    case 'multiple_choice':
      return 'Multiple Choice'
    case 'string':
      return 'Text'
    case 'start_end_time':
      return 'Start/End Time'
    case 'template':
      return 'Template'
  }
}

export const areFieldsEqual = (a: InputField, b: InputField) => {
  if ('options' in a && 'options' in b) {
    if (a.options.join() !== b.options.join()) return false
  }
  if (!('options' in a) && 'options' in b) return false
  if ('options' in a && !('options' in b)) return false
  if ('template' in a && 'template' in b) {
    if (a.template !== b.template) return false
  }
  if ('format' in a && 'format' in b) {
    if (a.format !== b.format) return false
  }
  if (!('format' in a) && 'format' in b) return false
  if ('format' in a && !('format' in b)) return false
  if ('names' in a && 'names' in b) {
    if (a.names !== b.names) return false
  }
  if (!('names' in a) && 'names' in b) return false
  if ('names' in a && !('names' in b)) return false
  if ('template' in a && 'template' in b) {
    if (a.template !== b.template) return false
  }
  if (!('template' in a) && 'template' in b) return false
  if ('template' in a && !('template' in b)) return false

  return (
    a.id === b.id &&
    a.name === b.name &&
    a.type === b.type &&
    a.instructions === b.instructions &&
    a.temperature === b.temperature &&
    a.model === b.model
  )
}

export const fieldTypes: FieldType[] = [
  'boolean',
  'date',
  'end_time',
  'multiple_choice',
  'number',
  'single_choice',
  'start_end_time',
  'start_time',
  'string',
  // 'template',
]

export type TemplateShowOptions =
  | 'temperature'
  | 'models'
  | 'omit-names'
  | 'id'
  | 'instructions'
  | 'format'
  | 'options'
  | 'reorder'
  | 'remove'
  | 'name'
  | 'add'
  | 'examples'
  | 'save'

export const ALL_TEMPLATE_OPTIONS: TemplateShowOptions[] = [
  'temperature',
  'models',
  'omit-names',
  'id',
  'instructions',
  'format',
  'options',
  'reorder',
  'remove',
  'name',
  'add',
  'examples',
  'save',
]

export const DEFAULT_TEMPLATE_OPTIONS: TemplateShowOptions[] = [
  'temperature',
  'instructions',
  'format',
  'name',
  'examples',
  'omit-names',
  'save',
]

export const TEMPLATE_OPTIONS_LABELS: { id: TemplateShowOptions; label: string }[] = [
  { id: 'temperature', label: 'Creativity Slider' },
  { id: 'models', label: 'Model Selection' },
  { id: 'omit-names', label: 'Omit Names Option' },
  { id: 'instructions', label: 'Template Instructions' },
  { id: 'format', label: 'Format' },
  { id: 'name', label: 'Change Field Name' },
  { id: 'examples', label: 'Display Live Examples' },
]

export type TemplateStyles = {
  backgroundColor: string
  paperBackgroundColor: string
  primaryTextColor: string
  secondaryTextColor: string
  buttonTextColor: string
  buttonStyle: 'contained' | 'outlined' | 'ghost'
  primaryColor: string
  secondaryColor: string
  borderRadius: number
  borderColor: string
  fontFamily: string
}

export const DEFAULT_TEMPLATE_STYLES: TemplateStyles = {
  backgroundColor: theme.palette.background.default,
  paperBackgroundColor: theme.palette.background.paper,
  primaryTextColor: theme.palette.text.primary,
  secondaryTextColor: theme.palette.text.secondary,
  buttonTextColor: theme.palette.primary.contrastText,
  buttonStyle: 'ghost',
  primaryColor: theme.palette.primary.main,
  secondaryColor: theme.palette.action.hover,
  borderRadius: 12,
  borderColor: theme.palette.divider,
  fontFamily: 'Mukta, sans-serif',
}

export const DEFAULT_DARK_TEMPLATE_STYLES: TemplateStyles = {
  backgroundColor: '#121212',
  paperBackgroundColor: '#1e1e1e',
  primaryTextColor: '#ffffff',
  secondaryTextColor: '#a3a3a3',
  buttonTextColor: '#ffffff',
  buttonStyle: 'contained',
  primaryColor: '#2c7be5',
  secondaryColor: '#2c7be5',
  borderRadius: 4,
  borderColor: '#2c2c2c',
  fontFamily: 'Mukta, sans-serif',
}

export const TEMPLATE_STYLES_LABELS: {
  id: keyof TemplateStyles
  label: string
  options: (string | number)[]
}[] = [
  { id: 'backgroundColor', label: 'Background Color', options: [] },
  { id: 'paperBackgroundColor', label: 'Paper Background Color', options: [] },
  { id: 'primaryTextColor', label: 'Primary Text Color', options: [] },
  { id: 'secondaryTextColor', label: 'Secondary Text Color', options: [] },
  { id: 'buttonTextColor', label: 'Button Text Color', options: [] },
  { id: 'buttonStyle', label: 'Button Style', options: ['contained', 'outlined', 'ghost'] },
  { id: 'primaryColor', label: 'Primary Color', options: [] },
  { id: 'secondaryColor', label: 'Secondary Color', options: [] },
  { id: 'borderRadius', label: 'Border Radius', options: [0, 2, 4, 6, 8, 10, 12, 16, 20, 24] },
  { id: 'borderColor', label: 'Border Color', options: [] },
  {
    id: 'fontFamily',
    label: 'Font Family',
    options: [
      'Roboto, sans-serif',
      '"Times New Roman", Times, serif',
      'Lucida Console, Monaco, monospace',
      'Courier New, Courier, monospace',
      'Comfortaa, cursive',
      'Merriweather, serif',
      'Mukta, sans-serif',
      'Poppins, sans-serif',
      'Varela Round, sans-serif',
    ],
  },
]
