import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import ChevronDown from '@mui/icons-material/KeyboardArrowDownRounded'
import Box from '@mui/material/Box'

type FlippingChevronProps = {
  open: boolean
}

const FlippingChevron = styled(ChevronDown, {
  shouldForwardProp: (prop) => prop !== 'open',
})<FlippingChevronProps>(({ theme, open }) => ({
  transition: 'transform 0.2s ease-in-out',
  transform: !open ? 'rotate(180deg)' : undefined,
}))

type TextAccordionProps = {
  id?: string
  title: string | React.ReactNode
  children: React.ReactNode
  open?: boolean
  disabled?: boolean
  onClose?: () => void
  onOpen?: () => void
}

const TextAccordion = ({
  id,
  title,
  children,
  open,
  disabled,
  onClose,
  onOpen,
}: TextAccordionProps) => {
  const [isOpen, setIsOpen] = useState(open || false)

  const handleToggle = useCallback(() => {
    if (isOpen && onClose) {
      onClose()
    } else if (!isOpen && onOpen) {
      onOpen()
    }
    setIsOpen(!isOpen)
  }, [isOpen, onClose, onOpen])

  useEffect(() => {
    setIsOpen(open || false)
  }, [open])

  return (
    <>
      <Divider>
        <Button
          variant="text"
          endIcon={<FlippingChevron open={isOpen} />}
          onClick={handleToggle}
          id={id}
          aria-expanded={isOpen}
          disabled={disabled}
        >
          {title}
        </Button>
      </Divider>
      <Collapse in={isOpen}>
        <Box sx={{ pt: 1 }}>{children}</Box>
      </Collapse>
    </>
  )
}

export default TextAccordion
