import { Member } from '@counsel-project/counsel-keys-api'
import DeleteIcon from '@mui/icons-material/DeleteRounded'
import MemberIcon from '@mui/icons-material/PersonRounded'
import AdminIcon from '@mui/icons-material/VerifiedUserRounded'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import StyledTableCell from './StyledTableCell'
import StyledTableRow from './StyledTableRow'

type PermissionChipProps = {
  permission: 'admin' | 'member'
}

const PermissionChip = ({ permission }: PermissionChipProps) => {
  return (
    <Chip
      sx={{ py: 2, px: 1 }}
      icon={permission === 'admin' ? <AdminIcon /> : <MemberIcon />}
      label={permission === 'admin' ? 'Admin' : 'Member'}
      color={permission === 'admin' ? 'primary' : 'default'}
      size="small"
    />
  )
}

export type AccessMemberRowProps = {
  isMobile?: boolean
  data: Member
  onClick?: (data: Member) => void
  onClickDelete?: (data: Member) => void
}

const AccessMemberRow = ({ isMobile, data, onClick, onClickDelete }: AccessMemberRowProps) => {
  const { email, accepted, permission } = data

  return (
    <StyledTableRow onClick={() => onClick?.(data)}>
      <StyledTableCell
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 400,
        }}
      >
        <Typography
          variant="subtitle2"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {email}
        </Typography>
      </StyledTableCell>
      <StyledTableCell
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 400,
        }}
      >
        <Typography
          variant="subtitle2"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          <PermissionChip permission={permission} />
        </Typography>
      </StyledTableCell>
      {!isMobile && (
        <StyledTableCell align="right">
          <Typography
            variant="subtitle2"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {accepted ? 'Accepted' : 'Invite Pending'}
          </Typography>
        </StyledTableCell>
      )}
      <StyledTableCell align="right">
        <IconButton onClick={() => onClickDelete?.(data)}>
          <DeleteIcon />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default AccessMemberRow
