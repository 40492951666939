import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useCallback, useEffect } from 'react'

type DeviceSelectorProps = {
  devices: MediaDeviceInfo[]
  value: MediaDeviceInfo | null
  onChange: (device: MediaDeviceInfo) => void
}

const DeviceSelector = ({ devices, value, onChange }: DeviceSelectorProps) => {
  const handleChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      const device = devices.find((device) => device.deviceId === e.target.value)
      if (device) onChange(device)
    },
    [devices, onChange]
  )

  useEffect(() => {
    console.log(devices)
  }, [devices])

  return (
    <Select
      value={value?.deviceId || ''}
      onChange={handleChange}
      fullWidth
      placeholder="Select a device"
      size="small"
      id="device-selector"
      MenuProps={{
        keepMounted: true,
      }}
      sx={{ mt: 0.5 }}
    >
      {devices.map((device) => (
        <MenuItem key={device.deviceId} value={device.deviceId}>
          {device.label}
        </MenuItem>
      ))}
    </Select>
  )
}

export default DeviceSelector
