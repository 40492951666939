import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const DocsPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/docs/welcome')
  }, [navigate])

  return <></>
}

export default DocsPage
