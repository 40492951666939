import { InputField } from '@counsel-project/counsel-external-api/common_external/InputField'
import AddIcon from '@mui/icons-material/AddRounded'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftRounded'
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { SetStateAction, useCallback, useState } from 'react'
import { DEFAULT_TEMPLATE_OPTIONS, TemplateShowOptions } from './_helpers'
import TemplateAddFieldDialog from './TemplateAddFieldDialog'
import TemplateField from './TemplateField'

export type TemplateFieldsProps = {
  properties?: TemplateShowOptions[]
  fields: InputField[]
  setFields: React.Dispatch<SetStateAction<InputField[]>>
  onChangeField?: (field: InputField) => void
  onToggleExamples?: () => void
  examplesShown?: boolean
}

const TemplateFields = ({
  properties = DEFAULT_TEMPLATE_OPTIONS,
  fields,
  setFields,
  onChangeField,
  onToggleExamples,
  examplesShown,
}: TemplateFieldsProps) => {
  const [addFieldOpen, setAddFieldOpen] = useState(false)

  const handleChangeTemplateField = useCallback(
    (value: InputField) => {
      setFields((prev) => prev.map((field) => (field.id === value.id ? value : field)))
      onChangeField?.(value)
    },
    [setFields, onChangeField]
  )

  const handleChangeOrder = useCallback(
    (from: number, to: number) => {
      setFields((prev) => {
        const newFields = [...prev]
        const [removed] = newFields.splice(from, 1)
        newFields.splice(to, 0, removed)
        return newFields
      })
    },
    [setFields]
  )

  const handleAddField = useCallback(
    (field: InputField) => {
      setFields((prev) => [...prev, field])
    },
    [setFields]
  )

  const handleRemoveField = useCallback(
    (field: InputField) => {
      setFields((prev) => prev.filter((f) => f.id !== field.id))
    },
    [setFields]
  )

  return (
    <Grid container spacing={1} alignItems="center" justifyContent="center">
      <Grid item xs>
        <Typography variant="h6" gutterBottom color="text.primary">
          Fields
        </Typography>
      </Grid>
      {properties.includes('add') && (
        <Grid item>
          <Button startIcon={<AddIcon />} onClick={() => setAddFieldOpen(true)}>
            Add Field
          </Button>
        </Grid>
      )}
      {properties.includes('examples') && (
        <Grid item>
          <Button
            endIcon={examplesShown ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            onClick={onToggleExamples}
          >
            {examplesShown ? 'Hide' : 'Show'} Examples
          </Button>
        </Grid>
      )}
      {fields.map((field, index) => (
        <Grid item xs={12} container key={field.id} alignItems="center">
          <TemplateField
            properties={properties}
            allFields={fields}
            index={index}
            total={fields.length}
            field={field}
            onChange={handleChangeTemplateField}
            onChangeOrder={handleChangeOrder}
            onRemoveField={handleRemoveField}
          />
        </Grid>
      ))}
      {properties.includes('add') && (
        <TemplateAddFieldDialog
          fields={fields}
          open={addFieldOpen}
          onClose={() => setAddFieldOpen(false)}
          onAddField={handleAddField}
        />
      )}
    </Grid>
  )
}

export default TemplateFields
