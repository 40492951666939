import { RAccess } from '@counsel-project/counsel-keys-api'
import OpenInNewIcon from '@mui/icons-material/OpenInNewRounded'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Breadcrumbs from '../../components/Breadcrumbs'
import PageContainer from '../../components/layout/PageContainer'
import { keysRequest } from '../../util/api/keys-api'
import getToken from '../../util/auth/getToken'
import handleError from '../../util/handleError'
import AccessInvitesDialog from './AccessInvitesDialog'
import NavCard from './NavCard'

const PlatformPage = () => {
  const [loading, setLoading] = useState(false)
  const [accesses, setAccesses] = useState<RAccess[]>([])

  const navigate = useNavigate()

  const handlePopulateAccesses = useCallback(async () => {
    try {
      setLoading(true)

      const token = await getToken()
      if (!token) return

      const { results } = await keysRequest.user.access.list({
        token,
      })

      setAccesses(results)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(handlePopulateAccesses, 10)
    return () => clearTimeout(timeout)
  }, [handlePopulateAccesses])

  const handleNav = (accessId: string) => {
    navigate(`/platform/${accessId}`)
  }

  return (
    <PageContainer>
      <Breadcrumbs paths={[]} />
      <Typography variant="h4" sx={{ mb: 1 }} textAlign="center">
        Clinical Notes API Platform
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }} textAlign="center">
        Choose your organization below to configure your API keys, organization settings, and view
        usage data.
      </Typography>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        {accesses.map((access) => (
          <Grid item xs={12} sm={6} key={access._id}>
            <NavCard title={access.organization} path={`/platform/${access._id}`} />
          </Grid>
        ))}
      </Grid>
      <AccessInvitesDialog onAddAccess={(access) => setAccesses([...accesses, access])} />
    </PageContainer>
  )
}

export default PlatformPage
