import { request } from '@counsel-project/utils/api'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useSearchParams } from 'react-router-dom'
import { url } from '../../util/api/url'
import handleError from '../../util/handleError'
import ClosableDialog from '../ClosableDialog'

type LoginBrowserErrorDialogProps = {
  open: boolean
  onClose: () => void
}

const LoginBrowserErrorDialog = ({ open, onClose }: LoginBrowserErrorDialogProps) => {
  const [email, setEmail] = useState('')
  const [emailsSent, setEmailsSent] = useState(0)
  const [loading, setLoading] = useState(false)

  const location = useLocation()
  const [searchParams] = useSearchParams()

  const handleSendEmail = useCallback(async () => {
    try {
      const license = searchParams.get('type') || 'Free'
      const path = location.pathname + location.search
      if (!license) {
        toast.error('License type not found')
        return
      }

      if (!email) {
        toast.error('Email is required')
        return
      }

      setLoading(true)

      await request({
        method: 'POST',
        url: url + '/mailing/register',
        body: {
          email,
          path,
          license,
        },
      })

      setEmailsSent(emailsSent + 1)
    } catch (err) {
      if (emailsSent > 0) {
        toast.success('Email has been sent')
        return
      }
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [email, emailsSent, location, searchParams])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Please Verify Your Email">
      <DialogContent>
        <Typography>
          Enter your email below to receive a link to verify your email address.
        </Typography>
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
        />
        {emailsSent > 0 && (
          <Typography fontStyle="italic" color="text.secondary">
            An email was sent. Please check your inbox.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSendEmail} disabled={loading || !email || emailsSent > 1}>
          {loading ? 'Sending...' : emailsSent > 0 ? 'Retry Email' : 'Send Email'}
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default LoginBrowserErrorDialog
