import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { TemplateStyles } from '../../_helpers'

type SelectSectionProps = {
  sectionId: keyof TemplateStyles
  label: string
  value: string | number
  onChange: (sectionId: keyof TemplateStyles, value: string | number) => void
  options: (string | number)[]
}

const SelectSection = ({ sectionId, label, value, onChange, options }: SelectSectionProps) => {
  const [stagedValue, setStagedValue] = useState(value)

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(sectionId, stagedValue)
    }, 500)
    return () => clearTimeout(timeout)
  }, [stagedValue, sectionId, onChange])

  useEffect(() => {
    setStagedValue((prev) => (prev !== value ? value : prev))
  }, [value])

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Typography variant="body1">{label}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Select
          value={stagedValue}
          onChange={(e) => {
            setStagedValue(e.target.value)
          }}
          fullWidth
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  )
}

export default SelectSection
