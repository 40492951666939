import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

const LeftPanel = styled(Box)(({ theme }) => ({
  backgroundColor: '#1f2025',
  borderRight: `1px solid ${theme.palette.divider}`,
  height: '100%',
  overflowY: 'auto',
  position: 'fixed',
  top: 0,
  left: 0,
  width: 240,
}))

export default LeftPanel
