import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { TEMPLATE_STYLES_LABELS, TemplateStyles } from '../../_helpers'
import ColorSection from './ColorSection'
import SelectSection from './SelectSection'
import { useCallback } from 'react'

type CBFunction = (prev: TemplateStyles) => TemplateStyles

type StylesSectionProps = {
  presets: {
    label: string
    styles: TemplateStyles
  }[]
  value: TemplateStyles
  onChange: (func: CBFunction) => void
}

const StylesSection = ({ presets, value, onChange }: StylesSectionProps) => {
  const getStyle = (sectionId: keyof TemplateStyles) => value[sectionId]

  const handleChangeStyle = useCallback(
    (sectionId: keyof TemplateStyles, newValue: string | number) => {
      onChange((prev) => ({
        ...prev,
        [sectionId]: newValue,
      }))
    },
    [onChange]
  )

  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Typography variant="h6">Style</Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
        Control the appearance of the template editor and recorder components.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Presets
      </Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {presets.map((preset, index) => (
          <Grid item key={preset.label}>
            <Button
              onClick={() => {
                onChange(() => preset.styles)
              }}
            >
              {preset.label}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2}>
        {TEMPLATE_STYLES_LABELS.map((section) =>
          section.options.length !== 0 ? (
            <Grid item key={section.id} xs={12} sm={6} lg={4}>
              <SelectSection
                sectionId={section.id}
                label={section.label}
                options={section.options}
                value={getStyle(section.id)}
                onChange={handleChangeStyle}
              />
            </Grid>
          ) : (
            <Grid item key={section.id} xs={12} sm={6} lg={4}>
              <ColorSection
                sectionId={section.id}
                label={section.label}
                value={getStyle(section.id) as string}
                onChange={handleChangeStyle}
              />
            </Grid>
          )
        )}
      </Grid>
    </Paper>
  )
}

export default StylesSection
