import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import createAuthSlice, { AuthStore } from './slices/auth'
import cookieStorage from './cookieStorage'

const useStore = create<AuthStore>()(
  persist(
    (...a) => ({
      ...createAuthSlice(...a),
    }),
    {
      name: 'app-storage',
      storage: createJSONStorage(() => cookieStorage),
      partialize: (state: AuthStore) => ({
        user: state.user,
      }),
    }
  )
)

export const getStore = () => useStore.getState()

export const setStore = (state: Partial<AuthStore>) => useStore.setState(state)

export default useStore
