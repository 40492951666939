import ClosableDialog from '../../components/ClosableDialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useState, useCallback, useEffect } from 'react'
import { keysRequest } from '../../util/api/keys-api'
import getToken from '../../util/auth/getToken'
import { RAccess, RateLimit, RateLimitType } from '@counsel-project/counsel-keys-api'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { options } from './AddRateLimitDialog'

type EditRateLimitDialogProps = {
  accessId: string
  limits: RateLimit[]
  limit: RateLimit
  open: boolean
  onClose: () => void
  onUpdateAccess: (access: RAccess) => void
}

const EditRateLimitDialog = ({
  accessId,
  limits,
  limit,
  open,
  onClose,
  onUpdateAccess,
}: EditRateLimitDialogProps) => {
  const [limitValue, setLimitValue] = useState(limit.limit)
  const [intervalValue, setIntervalValue] = useState(limit.interval)
  const [typeValue, setTypeValue] = useState<RateLimitType>(limit.type)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLimitValue(limit.limit)
    setIntervalValue(limit.interval)
    setTypeValue(limit.type)
  }, [limit])

  const handleUpdateRateLimit = useCallback(async () => {
    try {
      setLoading(true)

      const token = await getToken()
      if (!token) return

      const { result } = await keysRequest.admin.access.update({
        token,
        accessId,
        limits: limits.map((l) => {
          if (l === limit) {
            return {
              ...l,
              limit: limitValue,
              interval: intervalValue,
              type: typeValue,
            }
          }
          return l
        }),
      })

      onUpdateAccess(result)
      onClose()
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [accessId, limit, limits, limitValue, intervalValue, onUpdateAccess, onClose, typeValue])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Edit Rate Limit">
      <DialogContent>
        <Typography variant="body1" gutterBottom fontWeight={500}>
          Type
        </Typography>
        <Select
          value={typeValue}
          onChange={(e) => setTypeValue(e.target.value as RateLimitType)}
          fullWidth
        >
          <MenuItem value="field_generation">Field Generation</MenuItem>
          <MenuItem value="transcription_minutes">Transcription Minutes</MenuItem>
        </Select>
        <Typography variant="body1" gutterBottom fontWeight={500} sx={{ mt: 2 }}>
          Limit
        </Typography>
        <TextField
          placeholder="Limit"
          value={limitValue}
          onChange={(e) => setLimitValue(Number(e.target.value))}
          fullWidth
        />
        <Typography variant="body1" gutterBottom fontWeight={500} sx={{ mt: 2 }}>
          Interval
        </Typography>
        <Select
          value={intervalValue}
          onChange={(e) => setIntervalValue(e.target.value as number)}
          fullWidth
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleUpdateRateLimit} disabled={loading}>
          Update
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default EditRateLimitDialog
