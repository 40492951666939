import { RUser } from '@counsel-project/counsel-auth-api'
import { StateCreator } from 'zustand'

export type AuthStoreValues = {
  user: RUser | null
}

export type AuthStore = AuthStoreValues & {
  getIsLoggedIn: () => boolean
  setUser: (user: RUser | null) => void
}

const createAuthSlice: StateCreator<AuthStore> = (set, get) => ({
  user: null,
  getIsLoggedIn: () => {
    const { user } = get()
    if (!user) return false
    return true
  },
  setUser: (user: RUser | null) => set({ user }),
})

export default createAuthSlice
