import { RKey } from '@counsel-project/counsel-keys-api'
import CopyIcon from '@mui/icons-material/FileCopyRounded'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import ClosableDialog from '../../components/ClosableDialog'
import { keysRequest } from '../../util/api/keys-api'
import getToken from '../../util/auth/getToken'
import toast from 'react-hot-toast'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'

const CopyableTextField = ({ value }: { value: string }) => {
  const handleCopy = useCallback(() => {
    toast.success('Copied to clipboard')
    navigator.clipboard.writeText(value)
  }, [value])

  return (
    <TextField
      value={value}
      fullWidth
      disabled
      InputProps={{
        endAdornment: (
          <IconButton onClick={handleCopy}>
            <CopyIcon />
          </IconButton>
        ),
      }}
    />
  )
}

type AddKeyDialogProps = {
  accessId: string
  open: boolean
  onClose: () => void
  onAddKey: (key: RKey) => void
}

const AddKeyDialog = ({ accessId, open, onClose, onAddKey }: AddKeyDialogProps) => {
  const [name, setName] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [expiresAt, setExpiresAt] = useState<Date | null>(null)
  const [key, setKey] = useState<RKey | null>(null)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setName('')
    setAccessToken('')
    setKey(null)
  }, [open])

  const handleAddKey = useCallback(async () => {
    try {
      if (!name) return

      setLoading(true)

      const token = await getToken()
      if (!token) return

      const { result, accessToken } = await keysRequest.user.access.keys.add({
        token,
        accessId,
        name,
        expiresAt: expiresAt?.toISOString(),
      })

      // Add member
      onAddKey(result)
      setKey(result)
      setAccessToken(accessToken)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [accessId, name, onAddKey, expiresAt])

  if (accessToken && key) {
    return (
      <ClosableDialog open={open} onClose={onClose} titleText="Access Token">
        <DialogContent>
          <Typography variant="body1" gutterBottom fontWeight={500}>
            Keep this token safe. You will not be able to see it again. Never expose this token in
            your client side code.
          </Typography>
          <CopyableTextField value={accessToken} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </ClosableDialog>
    )
  }

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Add API Key">
      <DialogContent>
        <Typography variant="body1" gutterBottom fontWeight={500}>
          Name
        </Typography>
        <TextField
          placeholder="Name of the API Key..."
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={!!expiresAt}
              onChange={(e) => setExpiresAt(e.target.checked ? new Date() : null)}
            />
          }
          label="Expires"
        />
        {expiresAt && (
          <Box>
            <Typography variant="body1" gutterBottom fontWeight={500}>
              Expires At
            </Typography>
            <TextField
              type="datetime-local"
              value={expiresAt?.toISOString().slice(0, 16)}
              onChange={(e) => setExpiresAt(new Date(e.target.value))}
              fullWidth
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleAddKey}>
          Create
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default AddKeyDialog
