import { RTemplate } from '@counsel-project/counsel-external-api'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { alpha, createTheme, ThemeProvider } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import Breadcrumbs, { BreadcrumbPath } from '../../../../components/Breadcrumbs'
import PageContainer from '../../../../components/layout/PageContainer'
import SelectableTab from '../../../../components/SelectableTab'
import {
  DEFAULT_DARK_TEMPLATE_STYLES,
  DEFAULT_TEMPLATE_OPTIONS,
  DEFAULT_TEMPLATE_STYLES,
  TEMPLATE_OPTIONS_LABELS,
  TemplateStyles,
} from '../../_helpers'
import RecorderOverlay from '../../RecorderOverlay'
import { DEFAULT_RECORDER_OPTIONS, RECORDER_OPTIONS_LABELS } from '../../RecorderOverlay/_helpers'
import TemplateEditor from '../../TemplateEditor'
import StylesSection from './StylesSection'
import { keysRequest } from '../../../../util/api/keys-api'
import getToken from '../../../../util/auth/getToken'
import Button from '@mui/material/Button'
import toast from 'react-hot-toast'

export const createStyledTheme = (styles: TemplateStyles) => {
  const {
    backgroundColor,
    paperBackgroundColor,
    primaryColor,
    primaryTextColor,
    buttonTextColor,
    secondaryColor,
    secondaryTextColor,
    borderRadius,
    buttonStyle,
    borderColor,
    fontFamily,
  } = styles

  const newTheme = createTheme({
    palette: {
      background: {
        default: backgroundColor,
        paper: paperBackgroundColor,
      },
      primary: {
        main: primaryColor,
        contrastText: buttonTextColor,
      },
      secondary: {
        main: secondaryColor,
      },
      info: {
        main: secondaryColor,
      },
      text: {
        primary: primaryTextColor,
        secondary: secondaryTextColor,
        disabled: alpha(secondaryTextColor, 0.5),
      },
      action: {
        active: alpha(secondaryColor, 0.6),
        selected: alpha(secondaryColor, 0.2),
      },
      divider: borderColor,
    },
    typography: {
      h1: {
        fontFamily,
      },
      h2: {
        fontFamily,
      },
      h3: {
        fontFamily,
      },
      h4: {
        fontFamily,
      },
      h5: {
        fontFamily,
      },
      h6: {
        fontFamily,
      },
      fontFamily,
      button: {
        textTransform: 'none',
        fontWeight: 500,
        fontSize: '1em',
        lineHeight: 1.75,
      },
    },
    shape: {
      borderRadius,
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& fieldset': {
              borderColor,
            },
            '&:hover fieldset': {
              borderColor,
            },
            '&.Mui-focused fieldset': {
              borderColor,
            },
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        defaultProps: {
          variant: buttonStyle === 'contained' ? 'contained' : 'outlined',
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            backgroundColor: buttonStyle === 'ghost' ? `${alpha(primaryColor, 0.1)}` : undefined,
            border: buttonStyle === 'ghost' ? '1px solid transparent' : undefined,
          },
        },
      },
    },
  })

  return newTheme
}

const PREVIEW_TEMPLATE: RTemplate = {
  _id: '123',
  accessId: '123',
  identifier: '123',
  examples: [],
  metadata: {},
  customerIdentifier: '123',
  state: 'completed',
  error: null,
  updatedAt: new Date().toISOString(),
  createdAt: new Date().toISOString(),
  name: 'Preview Template',
  instructions: 'This is a preview template',
  type: 'Progress Note',
  fields: [
    {
      name: 'Subjective',
      id: 'subjective',
      temperature: 0.1,
      type: 'string',
      instructions: 'Example instructions...',
    },
    {
      name: 'Objective',
      id: 'objective',
      temperature: 0.4,
      type: 'string',
      instructions: '',
    },
  ],
}

const attemptParse = (str: string) => {
  try {
    return JSON.parse(str)
  } catch (err) {
    return null
  }
}

const ConfigurePage = () => {
  useTitle('API Dashboard')

  const { id } = useParams()

  const [templateOptions, setTemplateOptions] = useState(DEFAULT_TEMPLATE_OPTIONS)
  const [templateStyles, setTemplateStyles] = useState(DEFAULT_TEMPLATE_STYLES)
  const [template] = useState<RTemplate>(PREVIEW_TEMPLATE)
  const [recorderOptions, setRecorderOptions] = useState(DEFAULT_RECORDER_OPTIONS)
  const [recorderStyles, setRecorderStyles] = useState(DEFAULT_TEMPLATE_STYLES)

  const [previewShown, setPreviewShown] = useState<'template' | 'recorder'>('template')

  const [loading, setLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(true)

  const stagedTemplateTheme = useMemo(() => {
    return createStyledTheme(templateStyles)
  }, [templateStyles])

  const stagedRecorderTheme = useMemo(() => {
    return createStyledTheme(recorderStyles)
  }, [recorderStyles])

  const handlePopulateConfig = useCallback(async () => {
    try {
      if (!id) return

      setInitialLoading(true)

      const { metadata } = await keysRequest.access.metadata.get({
        accessId: id,
      })

      if (!metadata) return

      if (metadata.templateOptions && typeof metadata.templateOptions === 'string') {
        const parsed = attemptParse(metadata.templateOptions)
        if (parsed) setTemplateOptions(parsed)
      }

      if (metadata.templateStyles && typeof metadata.templateStyles === 'string') {
        const parsed = attemptParse(metadata.templateStyles)
        if (parsed) setTemplateStyles(parsed)
      }

      if (metadata.recorderOptions && typeof metadata.recorderOptions === 'string') {
        const parsed = attemptParse(metadata.recorderOptions)
        if (parsed) setRecorderOptions(parsed)
      }

      if (metadata.recorderStyles && typeof metadata.recorderStyles === 'string') {
        const parsed = attemptParse(metadata.recorderStyles)
        if (parsed) setRecorderStyles(parsed)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setInitialLoading(false)
    }
  }, [id])

  useEffect(() => {
    const timeout = setTimeout(handlePopulateConfig, 10)
    return () => clearTimeout(timeout)
  }, [handlePopulateConfig])

  const handleSave = useCallback(async () => {
    try {
      if (!id) return

      setLoading(true)

      const token = await getToken()
      if (!token) return

      await keysRequest.user.access.update({
        accessId: id,
        token,
        metadata: {
          templateOptions: JSON.stringify(templateOptions),
          templateStyles: JSON.stringify(templateStyles),
          recorderOptions: JSON.stringify(recorderOptions),
          recorderStyles: JSON.stringify(recorderStyles),
        },
      })

      toast.success('Configuration saved!')
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [id, templateOptions, templateStyles, recorderOptions, recorderStyles])

  if (!id) return null

  const paths: BreadcrumbPath[] = [
    {
      name: 'API',
      path: '/platform',
    },
    {
      name: 'Console',
      path: `/platform/${id}`,
    },
    {
      name: 'Configure Components',
    },
  ]

  if (initialLoading) {
    return (
      <PageContainer>
        <Breadcrumbs paths={paths} />
        <Typography>Loading...</Typography>
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <Breadcrumbs paths={paths} />
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant="h4">Configure Components</Typography>
        </Grid>
        <Grid item>
          <Button color="primary" onClick={handleSave} disabled={loading}>
            Save
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Adjust the embed-able template & recorder component styles & available customer-facing
            fields.
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <SelectableTab
            active={previewShown === 'template'}
            onClick={() => setPreviewShown('template')}
            small
          >
            Template Editor
          </SelectableTab>
        </Grid>
        <Grid item xs={6}>
          <SelectableTab
            active={previewShown === 'recorder'}
            onClick={() => setPreviewShown('recorder')}
            small
          >
            Recorder Widget
          </SelectableTab>
        </Grid>
        <Grid item xs={12}>
          {previewShown === 'recorder' ? (
            <StylesSection
              presets={[
                {
                  label: 'Default',
                  styles: DEFAULT_TEMPLATE_STYLES,
                },
                {
                  label: 'Dark & Blue',
                  styles: DEFAULT_DARK_TEMPLATE_STYLES,
                },
              ]}
              onChange={setRecorderStyles}
              value={recorderStyles}
            />
          ) : (
            <StylesSection
              presets={[
                {
                  label: 'Default',
                  styles: DEFAULT_TEMPLATE_STYLES,
                },
                {
                  label: 'Dark & Blue',
                  styles: DEFAULT_DARK_TEMPLATE_STYLES,
                },
              ]}
              onChange={setTemplateStyles}
              value={templateStyles}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ p: 2 }}>
            <Typography variant="h6">Enabled Features</Typography>
            <Typography variant="body1" color="text.secondary">
              These options control what features are available to the customer-facing UI.
            </Typography>
            {previewShown === 'recorder' ? (
              <Grid container spacing={2}>
                {RECORDER_OPTIONS_LABELS.map((option) => (
                  <Grid item key={option.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={recorderOptions.includes(option.id)}
                          onChange={() => {
                            if (recorderOptions.includes(option.id)) {
                              setRecorderOptions(recorderOptions.filter((p) => p !== option.id))
                            } else {
                              setRecorderOptions([...recorderOptions, option.id])
                            }
                          }}
                        />
                      }
                      label={option.label}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid container spacing={2}>
                {TEMPLATE_OPTIONS_LABELS.map((option) => (
                  <Grid item key={option.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={templateOptions.includes(option.id)}
                          onChange={() => {
                            if (templateOptions.includes(option.id)) {
                              setTemplateOptions(templateOptions.filter((p) => p !== option.id))
                            } else {
                              setTemplateOptions([...templateOptions, option.id])
                            }
                          }}
                        />
                      }
                      label={option.label}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Paper>
        </Grid>
        <Grid item>
          <Button color="primary" onClick={handleSave} disabled={loading}>
            Save
          </Button>
        </Grid>
        {previewShown === 'template' ? (
          <Grid item xs={12}>
            <ThemeProvider theme={stagedTemplateTheme}>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.default,
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: 1,
                  p: 2,
                })}
              >
                <TemplateEditor
                  properties={templateOptions}
                  template={template}
                  onSave={() => {}}
                  onExamplesUpdated={() => {}}
                  disableExamples
                />
              </Box>
            </ThemeProvider>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <ThemeProvider theme={stagedRecorderTheme}>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.default,
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: 1,
                  p: 2,
                  position: 'relative',
                  minHeight: 600,
                  overflow: 'hidden',
                })}
              >
                <RecorderOverlay absolute properties={recorderOptions} />
              </Box>
            </ThemeProvider>
          </Grid>
        )}
      </Grid>
    </PageContainer>
  )
}

export default ConfigurePage
