import { ContextOptions } from '@counsel-project/counsel-external-api'

export type ExampleSession = {
  name: string
  context: ContextOptions
  transcript: string
}

export const hannahTranscript = `
Speaker 0: Hi, Hannah. Nice to meet you. Hi. So I understand that you were referred to me by your GP because you've been experiencing some anxiety difficulties.
Speaker 1: Yeah. Yeah. I guess it can it kind of started, like, 5, 6 years ago.
Speaker 0: Okay.
Speaker 1: It kind of escalated a bit. I've just recently moved out, from living with my parents. So it's made it a bit worse, I think.
Speaker 0: Okay. Is this the first time that you've left home?
Speaker 1: Yeah. I, Yeah. It's the first time, actually. Yeah. I was going to a few years ago.
I just thought it would be easier to stay at home. So
Speaker 0: Okay. So it's been quite a big step and a big change. And and it sounds like that that was what led you to go to your GP. Yeah. So how has that, changed things for you?
Do
Speaker 1: you know it's kind of I haven't really told anyone. So, I mean, it's made it, like, better in the sense that I feel, hopefully, this is, you know, this is it. Like, it'll it'll help. But I don't know. I don't like thinking that I've, like, had to do this.
I never thought I'd, like, be the kind of person who'd have to actually go and, like, get help, if that makes sense.
Speaker 0: Okay. So how do you feel about being here today?
Speaker 1: A bit, like, scared. I don't know. Hopefully, it will help.
Speaker 0: Okay. So you'll be feeling a little bit anxious about today? Yeah. Okay. So I guess just going back to, why you went to the GP and and how you've ended up kind of coming here today.
So it sounds like you've been experiencing anxiety for quite a few years. Yeah. But just recently, you moved out of home for the first time, and, and that was what kind of led you to go to the GP. So how did that change things? How were things after you moved out?
I thought that they'd be better, but because
Speaker 1: I thought I'd have to kind of be independent and but it's actually a lot worse. I'm I'm just kind of staying in the house a lot. I don't really have any friends or anything. I mean, my family come and visit a bit, but it's not I don't know. It's kind of scary because I've just been kind of literally just in the flat by myself, which I always thought I'd like, but I don't actually like it.
Speaker 0: Okay. So what are the kind of difficulties that you have been experiencing?
Speaker 1: I mean, I'm I'm a photographer. So I kind of it's it's been stopping me a bit, my anxiety, from being able to get work and because every time, you know, there's an opportunity that is kind of involving me having to interact with people, I just tend to just turn it down. So I was kind of making things hard in that respect. And, just kind of friends wise and everything, I just feel like I'm just kind of increasingly more alone.
Speaker 0: So it's
Speaker 1: kind of stopping me from making friends and, you know, like, relationships and stuff.
Speaker 0: Okay. So you said that, your difficulties are getting in the way of you doing certain things, so they're they're stopping you from taking up kind of certain types of work. And as a freelance photographer, I guess that that can be kind of a problem. Yeah. And they're also stopping you or you feel like they're stunting you, socially.
You're not kind of able to make new friends and get to meet new people.
Speaker 1: Yeah. Yeah. It was kind of easier when I was at home because I was obviously, I mean, my parents and so that's fine. But, you know, they would I'd go to places with them or I'd kind of have people there, but more and more I'm just finding myself just staying in a lot. I mean, I went, like, 2 weeks to go for coffee with my friends, and the whole time I was just kind of wanting to go home again.
So I don't I just don't wanna I don't want that to happen. I don't wanna lose everyone. Mhmm.
Speaker 0: So So you're kind of worried about how where this is
Speaker 1: gonna lead to? Yeah. Yeah. Okay. So
Speaker 0: you said that you first noticed the anxiety kicking in when about 5, 6 years ago? So you would have been about 19? Yeah. And what was happening around that time?
Speaker 1: I guess I'd finished school about a year before that. And, I mean, I've always been a bit shy, at school, but it was never ever, like, it didn't really affect things as much as not at all as much as it has been doing the last few years, I guess. It just started when, like, my friends went to university, and I don't know, I felt like I was expected to kind of move out or just do something, and then it just kind of escalated from there. I'd I'd just stop going to parties or I'd just say no to invitations or I don't know. I thought my job would help because, I mean, I love it.
I I really like doing photography, but I'm just kind of getting less and less work as it goes on. So which is my fault. I keep saying no. But What kind of work is
Speaker 0: the most difficult for you
Speaker 1: in your job? I guess when it I have to kind of be working with other people. I don't like that. It's like when I'm trying to kind of do an event or something where there's a lot of people there, not just photographers but actual you know, if I'm, like, taking photos of people Mhmm. That I don't know.
I just kind of find myself saying no. I mean, I want to do them, but just it's scary. So I just don't do it.
Speaker 0: So I guess tell me a bit more about what happens then. So let's focus on maybe an invitation to do a job with, you know, it's gonna involve photography with with a group of people and you're gonna have to have social interaction. What kind of thoughts go through your head?
Speaker 1: Just I mean, I get really hot and sweaty, and I don't I feel like everyone's kind of looking at me and thinking that I'm just, like, really stupid. And I feel like they're all gonna be staring and thinking I just can't do my job and I'm just an idiot. And, I mean, they'll think that I just look like a weirdo, and I just try and I mean, I just kind of just try and get out of the situation or just focus on something else. So that's why I like doing shots where it's just me and the camera, because I then I can just put all my attention on that instead of having to, you know, be with other people.
Speaker 0: Okay. So it sounds like you have quite a lot of thoughts rushing through your head then at the point where you get that invitation. You think people will look, people will stare, people will think I'm a weirdo. There's quite a lot about what you think other people will think. And how does that make you feel?
Speaker 1: Just makes me feel like an idiot. It makes me feel like I just shouldn't even bother trying to do it anymore. I should just go home, and I just I think I just don't wanna be in that situation. I just feel like everyone else is fine, and they're normal, and I'm just the freak who can't, like, do normal things. And
Speaker 0: Okay. Can you give me an example of a a recent situation? Maybe we can focus on that.
Speaker 1: Like a work situation or just Yeah.
Speaker 0: A time when you were maybe doing a job with some people and you felt the social anxiety, you know, that you're describing. You felt that kick in.
Speaker 1: Yeah. I was doing a job where it was kind of like a a nature shoot, and I was having to work with a lot of different people. And and when they told me about the job, I thought it would be fine. I thought it was just gonna be a few other photographers, but there were actually people in you know, I was taking photos of people kind of in the the trees and stuff like that, and I get they asked me to,
Speaker 0: That sounds quite interesting. Was was that a fashion shoot or something?
Speaker 1: Yeah. Yeah. It was a fashion thing. I think it was for some kind of I'm not sure if it was a campaign or something but Okay. Yeah.
It was really cool but I find it found it really hard to try and focus on that because I was so I was kind of feeling really anxious and So
Speaker 0: it wasn't what you expected?
Speaker 1: No. It
Speaker 0: was No. So how long ago was that? That was 2 weeks ago. Okay. So it was quite recent.
Yeah. So on that particular day, what were the thoughts that you had in your head when you were in that situation?
Speaker 1: Just felt really just really kind of hot. I couldn't really breathe and just felt like I wanted to just get out, and I was kind of trying really, really hard to just focus and just, you know, like, kind of hold the camera and just not think about anything. But I just felt like everyone was looking at me, and, like when they'd say action and they'd try and get me to start and I was just felt like everyone was just thinking I was really stupid and I shouldn't have been there and I
Speaker 0: can tell you're getting kind of anxious even just thinking about that day. Yeah. Okay. So you're describing quite a lot there to me. So you're saying that you you kind of got hot and sweaty.
You said that you you held the camera. Yeah. What was do you mean you're kind of trying to hide behind it
Speaker 1: or I was just trying to focus on it. I was just thinking if I could just hold it really tightly, it would just I don't know. I'd kind of just be able to just get through it and just focus.
Speaker 0: So you're holding the camera very tightly? Yeah. And why was that why was that helpful?
Speaker 1: Because I felt like I was getting really, like, hot and flushed, and I just thought at least if I can just I don't know. It sounds weird, but I was just trying to keep my hands still on it because my hands were kind of sweaty and, like, shaking, like, loads. Like, and I could just tell everyone was looking and they could see, and I just thought if I just managed to hold on to it, it wouldn't be as noticeable. But
Speaker 0: Okay. So okay. So it sounds like you had a lot of thoughts going through your head. You said that you're worried that everyone's looking at you. What other thoughts were there that were in your mind?
Just I was just
Speaker 1: just stupid, and it was just really just felt really like, kind of, like everyone was just gonna be staring and just thinking that I just felt like everyone was looking at me, and just they could tell and just thinking, oh, what what's she doing? And They could tell what? How she like, I was just really, like, sweaty and just Oh,
Speaker 0: so oh, like that they could see that that was okay.
Speaker 1: Yeah. So I was trying to wear black, so it kind of tries to cover it up and just, like, a lot of layers so they can't tell. But, I mean, my hands, like, they were so shaken. I was really red and just just just wishing that I just wasn't there.
Speaker 0: So, I mean, you've already described it to me
Speaker 1: a little bit, but if
Speaker 0: you think about how you looked that day, how how do you say how do you think you looked?
Speaker 1: I think just really stupid and, like, I think my face was really red. I could feel it flushing, and I knew that I was probably sweating and shaking. Like, my hands were definitely really, really shaking. And every it was, like, definitely really noticeable. Everyone could definitely see.
Speaker 0: Okay. And what what did you do about how did you try and conceal that or hide that?
Speaker 1: Well, again, I was trying to grip the camera, just look down, just trying not to do anything weird or because, I mean, they could probably tell I was being weird anyway, but hopefully, if I I just tried really hard to just focus and look down, not try like, not look at anyone else and just, yeah, just kind of try and get on with it as best as I could. But
Speaker 0: So you avoided looking at people. It sounds like you wore black as well in in kind of preparation anyway. Yeah. No.
Speaker 1: I I always do that even if it's really hot or I wear just loads of layers and just because otherwise, like, I'll just be really sweaty and, like, people would definitely be able to see. So
Speaker 0: Okay. And how did it end?
Speaker 1: Well, it kind of went on for, like, an hour or so, and then, people were going. Just after every shoot, people go out for drinks after just to kind of celebrate, like, that we've done it. But I just I went home straight away. Just got in my car and went home. I just didn't wanna be around them, especially after they would have seen me being weird, and I just wanted to get get home straight away.
Okay.
`

export const hannahExample: ExampleSession = {
  name: 'Hannah (CBT)',
  context: {
    patient: {
      name: 'Hannah',
      age: 25,
      gender: 'Female',
      pronouns: 'She/Her',
    },
    provider: {
      name: 'Dr. Smith',
      specialty: 'Therapist',
    },
    current_visit: {
      date: '2022-01-01',
    },
  },
  transcript: hannahTranscript,
}

export const tomTranscript = `
Speaker 0: Nice to see you again, Tom. How are you today?
Speaker 1: I'm okay.
Speaker 0: So I understand that, since I was seeing you, that while ago, that you're in hospital again. Yeah. So can you tell me a bit more about what happened before that?
Speaker 1: Well, it was a few months ago now. Yeah. I've done quite well for a number of years. So I kept it under control really. But,
Speaker 0: Yeah. I mean, I've not I've not seen you since your gap year, wasn't it?
Speaker 1: Yeah. So that was 4 years ago. And so yeah it was quite disappointing to to go through it all again. But it sort of started at the towards the end of university. So I was doing law.
And well I was on course, for a first and I was just coming up to my, my final exams. Mhmm. And I got a few essays and a few exams and so I just I started working really hard. I felt like, right now is the real time to put the effort in and to to put the work in. So I started to step a bit later, take on a bit more work.
So my stress levels sort of start to increase over a while. I was drinking a lot more coffee, Took a few, caffeine tablets and energy drinks. A few people were because of the environment at the time was a lot of people were pushing themselves that they wouldn't normally do and some people were taking Ritalin. Oh, wow. Okay.
Speaker 0: Yeah. Which and Did you take any Ritalin?
Speaker 1: Yeah. Because it was just like it was that was necessary at the time.
Speaker 0: How did that affect you?
Speaker 1: Well, I it sort of increased my confidence at the time. All everything that was going on, I was becoming a lot more productive. Yeah, a lot more confident, a lot. I was working harder, my brain was working faster and so I Yeah. My my momentum was building up and up throughout the period.
Yeah. And so I was I was relishing it and I was, yeah, doing a lot of work and,
Speaker 0: So what did that mean to you then to be at at that time? Because it sounds like you put quite a lot of pressure on yourself. You've been doing really well at uni and and, you know, this was it. This was the time. What did it mean to you to be feeling like that kind of more awake and, more energized maybe?
Speaker 1: Yeah. Well, for me, it was it was my sort of time. So I'd spent I'd spent 3 years. I'd I was in control. I was doing well.
I didn't it wasn't really part of my life at the time. I didn't really talk to any of my friends about it. But then I, yeah, I started to just sort of grow into it. And I took the opportunity to, enjoy this time. And it didn't really reach its peak until after my exams.
I was still functioning throughout my exams. I have since found out that I didn't get the results that I wanted to.
Speaker 0: Why do you think that was?
Speaker 1: Well, I thought that I was working harder than ever, but it was misjudged, I think. And so I, yeah, I didn't perform to the best of my ability, in like, when it came to the to the exams. And so I didn't achieve I didn't achieve a first time, and only got 21.
Speaker 0: What else was happening for you around that time? I mean, you talked about the pressure of your exams and staying up for them. Was anything else going on for you around the same time?
Speaker 1: Well, so it's the end of the end of university, and that was the time where well, as soon as that period stopped of work, then I was sort of let off the leash, a bit and yeah, I just thought to to grow, even further. So I I mentioned those, I was taking, Ritalin, drinking a lot of coffee and as as soon as my exams had stopped, I just substance abuse sort of took a bit, more of a hold. And yeah, I just sort of grew into this person who I thought was him, was the guy that everyone wanted to be around and to see. And so I made sure that I was just everywhere that to be seen. I was
Speaker 0: So this is like the gap after you finish the exams but before the results are out.
Speaker 1: Yeah. Yeah. Yeah. So we were just going out a lot, but if not every night. You said
Speaker 0: that you were taking Ritalin and drinking a lot of coffee.
Speaker 1: Yeah.
Speaker 0: Using other drugs as well.
Speaker 1: Yeah. It sort of developed into, cocaine. A lot of cocaine. MDMA, MCAT.
Speaker 0: And how did they affect you?
Speaker 1: I honestly was on cloud 9 at the time. Yeah. I was just I was the person everyone everyone wanted to know in my head. I was I put myself at the center of the universe and, I even I convinced a friend of mine who develops apps, that we should, as if I had an idea to develop an app about when you go into a alcohol shop that, it all the way up, put like what's the most cost effective way to get wasted. Right.
So, yeah. I mean, I at the time I was spending a lot of money, a lot of my parents savings. I got a credit card, which I didn't tell them about. And so in this sort of role as the man, as the man about town, I was spent a lot on, yeah, alcohol, drugs, clothes. I invested in this technology.
And, yeah, I was
Speaker 0: So it sounds like it began with kind of the exam pressure and putting more pressure on yourself to stay up using kind of stimulants to keep yourself awake. And then you started to feel like you were more and more the man you were. You were kind of popular. You were successful. How did you respond and cope with those symptoms?
What did you do once those are kind of begun?
Speaker 1: Well, having not had to deal with my illness throughout university and for not being a part of that world, I I didn't confront those symptoms in a in a way that's gonna have long term stability. It's like, well, it began with doing the work that I needed to do. That's just, that's just what I needed to do in order to achieve my goals and to achieve what I want to and then it just, I just let it take hold and I it was all part of it wasn't, it wasn't, yeah, it wasn't the illness, it was me. It was just, I was in I was the master of this whole situation and I was everything that was happening was down to my own doing.
Speaker 0: So it sounds you said on the one hand, you didn't confront it. You kind of just let it roll. And on the other hand, you said that you you kind of took it as evidence that you were very energetic. You were powerful. This was all kind of explained about who you were in it.
I guess, how did that affect you looking at things in that way?
Speaker 1: It just it was my time. It had all been leading to like this was the moment where I would sort of like become the person that I wanted to be. All the hard work, all everything, like all the work that my parents had done for me, all the work that I've been doing, it was now crystallizing. Mhmm.
Speaker 0: It's felt like you're on the brink of something enormous from the sounds of it. Again, going back to that kind of beginning stage where you started to stay up late and use stimulants. I mean, how much sleep were you getting at at the beginning?
Speaker 1: It's sort it gradually diminished, I think. It began at 6, 5, and then was ended up about 3 and 2 hours.
Speaker 0: What kind of things did you notice? Did you notice any changes from having less and less sleep? In your body maybe, or the way you were thinking.
Speaker 1: I think the harder I was working, the faster my brain then would work and how much Yeah, the harder I worked, the more I would do. So it was it wasn't It was just a Yeah. The momentum was building more than anything. My thoughts were firing and my goals were stronger and my, yeah, my determination was
Speaker 0: bigger. And how did you feel in your body? Did you notice any changes physically?
Speaker 1: Not that I can recall. At the time, maybe I was trying to Maybe I was avoiding, avoiding, confronting, any negative effects, but nothing powerful comes to mind.
Speaker 0: Okay. And once you had kind of things were a bit further along after your exams, you said you were going out a lot, you convinced your friend to do this app. Was there anything else that you did that maybe you wouldn't normally do that you think might have been assigned to you that your mood was much higher than you'd like it to be?
Speaker 1: There was I had a lot of savings that my, that my parents had built up for me, which don't really exist anymore either through. Yeah, well the money I invested, and through drugs, clothes. I I had a girlfriend at the time. But because it was Because I was the main character in a sense that I was only the only person that mattered. And so, sort of those around me, especially at her, it just didn't really have as much importance.
So on nights out, I I wouldn't she just wouldn't be in my mind, and so there would be other women. Okay. So And
Speaker 0: how do you feel about that now, looking back on it?
Speaker 1: I think it's it's those moments where it's not just me, it's those those closest to you and those around you that have to suffer from more than anyone else. That brings a lot of the, shame. Yeah.
`

export const tomExample: ExampleSession = {
  name: 'Tom (Substance Abuse, Bipolar)',
  context: {
    patient: {
      name: 'Tom',
      age: 22,
      gender: 'Male',
      pronouns: 'He/Him',
    },
    provider: {
      name: 'Dr. Smith',
      specialty: 'Therapist',
    },
    current_visit: {
      date: '2022-01-01',
    },
  },
  transcript: tomTranscript,
}

export const jeffTranscript = `
Speaker 0: Hi, Jeff. How are you doing today?
Speaker 1: I'm I'm alright. I'm feeling a little weird lately, and I I can't explain it. I'm just I feel off.
Speaker 0: You feel off? I feel
Speaker 1: I just feel really off and not like myself and just not like a normal like, I think a normal person should be feeling right now.
Speaker 0: So you're not feeling like a normal person should be feeling?
Speaker 1: I'm feeling a little anxious, a little jittery, a little I I don't know. I just I just feel weird.
Speaker 0: Alright. So you have some anxiety, but you're not really certain of the feeling. Yes. I yes. Okay.
I wanna ask you a bit of an unusual question that is gonna take some imagination to answer. Okay. I want you to bear with me if you can. Okay. Alright.
So let's say that, we we know you have this problem that you're describing. It's partially anxiety based at least. Say that you were to go home tonight Mhmm. And attend to your usual chores, and you're to go to sleep. Okay.
And attend to your usual chores, and you're to go to sleep. Okay. Alright. So you're asleep, and sometime during the night, a miracle occurs. And the problem you're having right now Mhmm.
In its entirety is gone. So the next morning, you wake up, but you don't know the miracle has occurred. Okay. Does that make sense? Makes sense.
So at this point, as you wake up, you don't know the miracle has occurred. Mhmm. How would you recognize the problem is going? I I
Speaker 1: think I would just feel better, like, I don't know. Maybe not be jittery. I don't
Speaker 0: I I guess. Not be jittery?
Speaker 1: Yeah. Maybe I I don't know. Maybe I would just I would just feel better on the inside.
Speaker 0: Alright. Try to try to think that's good. Try to think specifically, like, you first wake up. Okay. Let's go through let's go kinda through your day.
So you first wake up, this miracle has occurred, but you don't know it's occurred. Okay. So you don't know about the miracle. Mhmm. You wake up believing that you would still have the problem.
Okay. You know the problem is not there. Okay. So let's move through let's move through the day and see when you would be able to recognize or what you would recognize. So it's different.
Speaker 1: I think during the first half of my day, I would just it like, when I wake up, I do all the normal things. I make my breakfast, shower, shave, all of that stuff. And I generally don't feel jittery then. It's just when I start to actually go out of the house and just thinking about getting to work and stuff, it starts to just get a little jittery on the inside.
Speaker 0: So you may not notice the problems go on when you're still at home.
Speaker 1: Correct. But I think that once I actually got, like, to my office space, I think that that's when I'll start to say, hey. You're you're you're not feeling all jittery. Your palms are not sweating. You're not being you're not really anxious about going into work today.
And something just starts to click and it's like, hey. This is just feels right. Instead of this feels wrong, and I shouldn't be here, or I shouldn't want to feel like this.
Speaker 0: Alright. So the moment you get to work is when you think you'd really recognize that something about the problem has changed, that that it might be gone.
Speaker 1: I think so. And I think more I would actually understand or see the miracle had occurred is actually when I started to interact with my coworkers. Because I think that they're that's where a lot of the jitteriness occurs or at least that's what I've been noticing that I'm seeing that anytime I'm really starting to interact with my coworkers that I I my heart starts to raise, my hand starts to get sweaty. I I don't know what to say. I don't know what to do, and I think that if all of that started to disappear, then I think that I would have noticed that.
So that moment when you start to
Speaker 0: interact with your coworkers, you would you would notice that you're not reacting in the way that you used to react. Correct. Which sounds almost like almost like intense anxiety or panic Uh-huh. The way you describe
Speaker 1: it. I I never really thought about it like that because I think that that's something that this naturally always been happening, and it really never occurred to me to look at my work situation to see if I eliminated that or those people that all of the anxiety would go away. Because I think that even though when I come home, I'm still thinking about work and stuff is still happening, and I'm still worried about how I'm going to work with these people that I still am a little jittery, but I'm not as jittery or sweaty or whatever, especially when I'm at the office.
Speaker 0: So the so the largest chance you're gonna recognize the problem is gone is during that time in the office. Correct. That's really so there might be an after effect that you would that's that'll be absent on your way home. Correct. But you feel like the the symptoms, the problem are really strongest at the office.
Correct. Yes. Right.
Speaker 1: Yes. I would say 100%. Yes. When I'm especially when I'm interacting with just people, especially large groups of people. I think that that's when it really pops in, and it just I just don't know know what to do with myself.
And I think I could see that if I we had a, say,
Speaker 0: a a a
Speaker 1: group meeting
Speaker 0: Mhmm.
Speaker 1: I think I would really notice it then because I wouldn't be worried about what I'm saying, or how other people are viewing me, or if people think I'm dumb, or anything like that. I think that it I would just be able to say what I needed to say.
Speaker 0: So if the problem wasn't there, you'd be relaxed and confident during these meetings. Yeah. I think I would be.
Speaker 1: I think be really confident, and, also, I would be very sure of myself because I would know that this is what I'm saying is right, and what I'm doing is right. And I wouldn't have to worry about if my coworker's talking behind my back or if my coworker's laughing. Like, I wouldn't even care if if they're on their phones because I know that what I'm presenting is going to be right. Right.
Speaker 0: So it it it seems like I've used this question, which we call the miracle question. Okay. Right. It seems like it's helped you to identify what's going on a little bit. It sounds like a social anxiety type symptoms that you have going on.
Okay. Alright. And that's something we can certainly work on.
Speaker 1: Perfect. Alright. Perfect.
Speaker 0: And maybe it's given you some insight into how that social anxiety seems to function throughout the day. Yep. When it's more intense at work, when it's less intense as you come home. Mhmm. And in the morning, when you wake up, really, it doesn't seem like it's too bad at all.
Yeah. So it's giving you an idea how those symptoms ebb and flow through the day and what they might mean. What might be causing them. Mhmm. Does that make sense?
That makes complete sense, I I think. We'll we'll work on it. Okay. Work on it to put some more framework around it. But give that some thought, what's going on the problem in terms of, when it occurs.
You kinda like you're recognizing happy as work. Mhmm. And reflect on that a bit, and then I'll see you again in about a week. And we'll start moving through and see what we can do about the social anxiety.
Speaker 1: Okay. Perfect.
Speaker 0: That sound good, Jeff.
Speaker 1: That sounds wonderful.
Speaker 0: I'll see you then. Thanks a lot.
Speaker 1: Thank you.
`

export const jeffExample: ExampleSession = {
  name: 'Jeff (Social Anxiety, Solution Focused)',
  context: {
    patient: {
      name: 'Jeff',
      age: 30,
      gender: 'Male',
      pronouns: 'He/Him',
    },
    provider: {
      name: 'Dr. Smith',
      specialty: 'Therapist',
    },
    current_visit: {
      date: '2022-01-01',
    },
  },
  transcript: jeffTranscript,
}

export const examples: ExampleSession[] = [hannahExample, tomExample, jeffExample]
