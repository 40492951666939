export type RecorderShowOptions =
  | 'upload-audio'
  | 'live-session'
  | 'dictation'
  | 'top'
  | 'left'
  | 'panel'

export const ALL_RECORDER_OPTIONS: RecorderShowOptions[] = [
  'upload-audio',
  'live-session',
  'dictation',
  'top',
  'left',
  'panel',
]

export const DEFAULT_RECORDER_OPTIONS: RecorderShowOptions[] = [
  'upload-audio',
  'live-session',
  'dictation',
]

export const RECORDER_OPTIONS_LABELS: { id: RecorderShowOptions; label: string }[] = [
  {
    id: 'upload-audio',
    label: 'Upload Audio',
  },
  {
    id: 'live-session',
    label: 'Live Session',
  },
  {
    id: 'dictation',
    label: 'Dictation',
  },
  {
    id: 'top',
    label: 'Top Position',
  },
  {
    id: 'left',
    label: 'Left Position',
  },
  {
    id: 'panel',
    label: 'Display in Panel',
  },
]
