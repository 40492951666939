import { isApiError, request } from '@counsel-project/utils/api'
import Cookies from 'js-cookie'

const url = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/api' : '/api'

export const getStringified = (err: unknown) => {
  if (isApiError(err)) {
    return `${err.status} ${err.msg}\n${err.stack}`
  }
  if (err instanceof Error) {
    return `${err.message}\n${err.stack}`
  }
  if (typeof err === 'string') {
    return err
  }
  if (typeof err === 'object' && !!err) {
    return err.toString()
  }
  return JSON.stringify(err)
}

export const info = (message: unknown, ...args: unknown[]) => {
  const fullMessage = `${getStringified(message)} ${args
    .map((arg) => getStringified(arg))
    .join(' ')}`

  // Send info to backend TODO
  request({
    method: 'post',
    url: url + '/log',
    body: {
      msg: fullMessage,
      token: Cookies.get('token'),
    },
    headers: {
      'Content-Type': 'application/json',
    },
  }).catch((err) => {
    console.error(err)
  })

  console.log(fullMessage)
}

export const error = (message: unknown, ...args: unknown[]) => {
  const fullMessage = `${getStringified(message)} ${args
    .map((arg) => getStringified(arg))
    .join(' ')}`

  request({
    method: 'post',
    url: url + '/error',
    body: {
      msg: fullMessage,
      token: Cookies.get('token'),
    },
    headers: {
      'Content-Type': 'application/json',
    },
  }).catch((err) => {
    console.error(err)
  })

  console.error(fullMessage)
}

export const warn = (message: unknown, ...args: unknown[]) => {
  const fullMessage = `${getStringified(message)} ${args
    .map((arg) => getStringified(arg))
    .join(' ')}`

  request({
    method: 'post',
    url: url + '/warn',
    body: {
      msg: fullMessage,
      token: Cookies.get('token'),
    },
    headers: {
      'Content-Type': 'application/json',
    },
  }).catch((err) => {
    console.error(err)
  })

  console.warn(fullMessage)
}

const log = {
  error,
  info,
  warn,
}

export default log
