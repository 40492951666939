import { OutputField, RTemplate } from '@counsel-project/counsel-external-api'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import Breadcrumbs, { BreadcrumbPath } from '../../../../../components/Breadcrumbs'
import PageContainer from '../../../../../components/layout/PageContainer'
import { externalRequest } from '../../../../../util/api/external-api'
import getToken from '../../../../../util/auth/getToken'
import handleError from '../../../../../util/handleError'
import TemplateEditor, { SavedOptions } from '../../../TemplateEditor'

const PlatformTemplatePage = () => {
  useTitle('Customer Templates')

  const { id, templateIdentifier } = useParams()

  const [template, setTemplate] = useState<RTemplate | null>(null)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)

  const handleSave = useCallback(
    async ({ instructions, fields, examples }: SavedOptions) => {
      if (!id) return
      if (!templateIdentifier) return

      try {
        setSaving(true)

        const token = await getToken()
        if (!token) return

        const { result } = await externalRequest.user.templates.update({
          token,
          accessId: id,
          identifier: templateIdentifier,
          instructions,
          fields,
          examples,
        })

        toast.success('Template saved', { id: 'template-saved' })
      } catch (err) {
        handleError(err)
      } finally {
        setSaving(false)
      }
    },
    [id, templateIdentifier]
  )

  const populateTemplate = useCallback(async () => {
    try {
      if (!id) return
      if (!templateIdentifier) return

      setLoading(true)

      const token = await getToken()
      if (!token) return

      const { result } = await externalRequest.user.templates.get({
        token,
        accessId: id,
        identifier: templateIdentifier,
      })

      setTemplate(result)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [id, templateIdentifier])

  useEffect(() => {
    const timeout = setTimeout(populateTemplate, 10)
    return () => clearTimeout(timeout)
  }, [populateTemplate])

  const paths: BreadcrumbPath[] = [
    {
      name: 'API',
      path: '/platform',
    },
    {
      name: 'Console',
      path: `/platform/${id}`,
    },
    {
      name: 'Dashboard',
      path: `/platform/${id}/dashboard`,
    },
    {
      name: 'Templates',
      path: `/platform/${id}/templates/${template?.customerIdentifier}`,
    },
    {
      name: 'Template',
    },
  ]

  const handleUpdateExamples = useCallback(
    async (outputs: OutputField[]) => {
      try {
        if (outputs.length === 0) return

        if (!id) return
        if (!templateIdentifier) return

        const token = await getToken()
        if (!token) return

        const { result } = await externalRequest.user.templates.update({
          token,
          accessId: id,
          identifier: templateIdentifier,
          examples: outputs,
        })

        setTemplate(result)
      } catch (err) {
        handleError(err)
      }
    },
    [id, templateIdentifier]
  )

  if (loading || !template) {
    return (
      <Box>
        <PageContainer>
          <Breadcrumbs paths={paths} />
        </PageContainer>
        <Container sx={{ p: { xs: 1, sm: 2 } }}>
          <Grid container spacing={1} alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={8}>
              <Grid container spacing={1} alignItems="start">
                <Grid item xs>
                  <Skeleton
                    variant="text"
                    height={80}
                    sx={{ backgroundColor: 'background.paper' }}
                  />
                  <Skeleton
                    variant="text"
                    height={30}
                    sx={{ backgroundColor: 'background.paper' }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Skeleton variant="text" height={240} sx={{ backgroundColor: 'background.paper' }} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Skeleton variant="text" height={44} sx={{ backgroundColor: 'background.paper' }} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Skeleton variant="text" height={44} sx={{ backgroundColor: 'background.paper' }} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    )
  }

  return (
    <Box>
      <PageContainer>
        <Breadcrumbs paths={paths} />
      </PageContainer>
      <TemplateEditor
        template={template}
        onSave={handleSave}
        disabled={saving}
        loading={saving}
        onExamplesUpdated={handleUpdateExamples}
      />
    </Box>
  )
}

export default PlatformTemplatePage
