import makeCNAIApi from '@counsel-project/clinical-notes-api'
import BackIcon from '@mui/icons-material/ArrowBack'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Cookies from 'js-cookie'
import { useCallback, useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { Outlet, useNavigate } from 'react-router-dom'
import getEnvironment from '../../util/api/get-environment'
import { keysRequest } from '../../util/api/keys-api'
import getToken from '../../util/auth/getToken'
import useUser from '../../util/auth/useUser'
import handleError from '../../util/handleError'
import log from '../../util/logging'
import InitializeDemoDialog from './InitializeDemoDialog'

const DemoNavLayout = () => {
  const navigate = useNavigate()

  const [user] = useUser()
  const [initializeOpen, setInitializeOpen] = useState(true)
  const [env, setEnv] = useState('')

  const populateEnv = useCallback(async () => {
    try {
      const env = await getEnvironment()
      setEnv(env)
    } catch (err) {
      handleError(err)
    }
  }, [])

  useEffect(() => {
    populateEnv()
  }, [populateEnv])

  const authenticateCustomer = useCallback(async () => {
    try {
      if (!env) return false
      if (!user) return false

      const token = await getToken(true)
      if (!token) {
        toast.error('Please log in to continue')
        navigate('/login')
        return false
      }

      const { results: accesses } = await keysRequest.user.access.list({ token })

      if (!accesses.length) {
        setInitializeOpen(true)
        toast.error(
          'No instances have been set up yet. Please contact our support regarding getting your set up with an instance.',
          { duration: 9000 }
        )
        return false
      }

      let accessToken = Cookies.get('_demo_access_token')
      if (!accessToken) {
        const expiresAt = new Date(Date.now() + 1000 * 60 * 60 * 24)

        const addResponse = await keysRequest.user.access.keys.add({
          token,
          name: `Demo Key - ${new Date().toISOString()}`,
          accessId: accesses[0]._id,
          // Key will expire in 24 hours
          expiresAt: expiresAt.toISOString(),
        })

        Cookies.set('_demo_access_token', addResponse.accessToken, { expires: expiresAt })

        accessToken = addResponse.accessToken
      }

      const cnaiRequest = makeCNAIApi({
        url:
          env === 'production'
            ? 'https://api.clinicalnotesai.com'
            : 'https://beta.api.clinicalnotesai.com',
        access_token: accessToken,
      })

      try {
        const { customer_token } = await cnaiRequest.customers.authenticate({
          customer_id: `_internal_${user.email}`,
        })

        // @ts-expect-error No types for the widget have been defined
        window.CNAI.addEventListener('authenticated', () => {
          console.log('CNAI - Authenticated')
          setTimeout(() => {
            // @ts-expect-error No types for the widget have been defined
            window.CNAI.show()
          }, 300)
        })

        // @ts-expect-error No types for the widget have been defined
        window.CNAI.addEventListener('error', (err) => {
          if (err.type === 'Error Authenticating') {
            Cookies.remove('_demo_access_token')
            toast.error('Error authenticating. Please try again.')
            // Reload the page to re-authenticate
            // window.location.reload()
          }
          log.error(err)
        })

        // @ts-expect-error No types for the widget have been defined
        window.CNAI.addEventListener('transcription', (transcript) => {
          console.log(`Transcript created: ${transcript}`)
        })

        // @ts-expect-error No types for the widget have been defined
        window.CNAI.authenticate(customer_token)
      } catch (err) {
        Cookies.remove('_demo_access_token')
        log.error(err)
        // Reload the page to re-authenticate
        // window.location.reload()
      }
    } catch (err) {
      Cookies.remove('_demo_access_token')
      handleError(err)
    }
  }, [user, navigate, env])

  const injectCDN = useCallback(() => {
    if (!env) return
    console.log('CNAI - Injecting CDN')
    const script = document.createElement('script')
    script.id = 'clinicalnotes-widget'
    script.src =
      env === 'development'
        ? 'http://localhost:3001/cdn/index.js'
        : env === 'production'
          ? 'https://platform.clinicalnotes.ai/cdn/index.js'
          : 'https://beta.platform.clinicalnotesai.com/cdn/index.js'
    script.async = true
    document.body.appendChild(script)
  }, [env])

  const deleteCDN = useCallback(() => {
    console.log('CNAI - Deleting CDN')
    const script = document.getElementById('clinicalnotes-widget')
    if (script) {
      try {
        // @ts-expect-error No types for the widget have been defined
        window.CNAI.hide()
      } catch (err) {
        log.error(err)
      }
      script.remove()
    }
  }, [])

  const isInjected = useCallback(() => {
    return !!document.getElementById('clinicalnotes-widget')
  }, [])

  const handleInject = useCallback(() => {
    if (!isInjected()) {
      injectCDN()
    }
  }, [injectCDN, isInjected])

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleInject()
    }, 10)
    return () => clearTimeout(timeout)
  }, [handleInject])

  const handleCancel = useCallback(() => {
    navigate('/platform')
    toast.error('Demo canceled')
  }, [navigate])

  const handleInitialize = useCallback(() => {
    setInitializeOpen(false)
    setTimeout(() => {
      authenticateCustomer()
    }, 2000)
  }, [authenticateCustomer])

  // Cleanup the window.CNAI instance
  useEffect(() => {
    return () => {
      deleteCDN()
    }
  }, [deleteCDN])

  return (
    <>
      <InitializeDemoDialog
        onAgree={handleInitialize}
        onCancel={handleCancel}
        open={initializeOpen}
      />
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Widget Demo
          </Typography>
          <Button
            color="inherit"
            onClick={() => navigate('/platform')}
            startIcon={<BackIcon />}
            sx={{ color: 'white' }}
          >
            Back
          </Button>
          <Button color="inherit" onClick={() => navigate('/demo')} sx={{ color: 'white' }}>
            Home
          </Button>
          <Button color="inherit" onClick={() => navigate('/demo/soap')} sx={{ color: 'white' }}>
            SOAP
          </Button>
          <Button color="inherit" onClick={() => navigate('/demo/dap')} sx={{ color: 'white' }}>
            DAP
          </Button>
          <Button
            color="inherit"
            onClick={() => navigate('/demo/treatment-plan')}
            sx={{ color: 'white' }}
          >
            Treatment Plan
          </Button>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 4 }}>
        <Outlet />
      </Container>
      <Toaster />
    </>
  )
}

export default DemoNavLayout
