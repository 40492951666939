import ClosableDialog from '../../components/ClosableDialog'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { InputField } from '@counsel-project/counsel-external-api'
import Typography from '@mui/material/Typography'
import { useCallback, useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import AddIcon from '@mui/icons-material/AddRounded'
import IconButton from '@mui/material/IconButton'
import TextAccordion from '../../components/TextAccordion'
import Box from '@mui/material/Box'
import toast from 'react-hot-toast'
import LabeledTextField from '../../components/layout/LabeledTextField'
import { ALL_TEMPLATE_OPTIONS, TemplateShowOptions } from './_helpers'

type OptionsSelectorProps = {
  value: string[]
  onChange: (value: string[]) => void
}

const OptionsSelector = ({ value, onChange }: OptionsSelectorProps) => {
  const [stagedOption, setStagedOption] = useState('')

  const handleAddOption = useCallback(() => {
    if (!stagedOption) return
    onChange([...value, stagedOption])
    setStagedOption('')
  }, [stagedOption, value, onChange])

  const handleRemoveOption = useCallback(
    (option: string) => {
      onChange(value.filter((o) => o !== option))
    },
    [value, onChange]
  )

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item container xs={12} spacing={1}>
        {value.map((option) => (
          <Grid item key={option}>
            <Chip label={option} onDelete={() => handleRemoveOption(option)} variant="outlined" />
          </Grid>
        ))}
      </Grid>
      <Grid item>
        <TextField
          variant="outlined"
          size="small"
          value={stagedOption}
          onChange={(e) => setStagedOption(e.target.value)}
          placeholder='Type an option and press "Add"'
        />
      </Grid>
      <Grid item>
        <IconButton onClick={handleAddOption} color="primary">
          <AddIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

type TemplateFieldSettingsDialogProps = {
  properties?: TemplateShowOptions[]
  allFields: InputField[]
  open: boolean
  onClose: () => void
  field: InputField
  onUpdateField: (field: InputField) => void
  onRemoveField: (field: InputField) => void
}

const TemplateFieldSettingsDialog = ({
  properties = ALL_TEMPLATE_OPTIONS,
  allFields,
  open,
  onClose,
  field,
  onUpdateField,
  onRemoveField,
}: TemplateFieldSettingsDialogProps) => {
  const [stagedName, setStagedName] = useState(field.name)
  const [stagedId, setStagedId] = useState(field.id)
  const [stagedModel, setStagedModel] = useState(field.model)
  const [stagedOptions, setStagedOptions] = useState<string[]>(
    'options' in field ? field.options : []
  )

  useEffect(() => {
    setStagedName(field.name)
    setStagedId(field.id)
    setStagedModel(field.model)
    setStagedOptions('options' in field ? field.options : [])
  }, [field])

  const handleSave = useCallback(() => {
    if (allFields.some((f) => f.id === stagedId && f.id !== field.id)) {
      toast.error('Field ID must be unique')
      return
    }

    let newField: InputField

    if (field.type === 'multiple_choice' || field.type === 'single_choice') {
      newField = {
        ...field,
        name: stagedName,
        id: stagedId,
        model: stagedModel,
        options: stagedOptions,
      }
    } else {
      newField = {
        ...field,
        name: stagedName,
        id: stagedId,
        model: stagedModel,
      }
    }

    onUpdateField(newField)
    onClose()
  }, [field, stagedName, stagedId, stagedOptions, stagedModel, onUpdateField, onClose, allFields])

  const handleRemove = useCallback(() => {
    onRemoveField(field)
    onClose()
  }, [field, onRemoveField, onClose])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Field Settings">
      <DialogContent>
        {properties.includes('id') && (
          <LabeledTextField
            label="Field ID"
            value={stagedId}
            onChange={(e) => setStagedId(e.target.value)}
            sx={{ mb: 2 }}
            placeholder="Enter a unique field ID"
          />
        )}
        {properties.includes('name') && (
          <LabeledTextField
            label="Field Name"
            value={stagedName}
            onChange={(e) => setStagedName(e.target.value)}
            sx={{ mb: 2 }}
            placeholder="Enter a field name"
          />
        )}
        {(field.type === 'multiple_choice' || field.type === 'single_choice') &&
        properties.includes('options') ? (
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {field.type === 'multiple_choice'
                ? 'Multiple Choice Options'
                : 'Single Choice Option'}
            </Typography>
            <OptionsSelector value={stagedOptions} onChange={setStagedOptions} />
          </Box>
        ) : null}
        {properties.includes('models') && (
          <TextAccordion id={`options-accordion-${field.id}`} title="Advanced">
            <Typography variant="body1" gutterBottom>
              Model
            </Typography>
            <Grid container spacing={2}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox checked={stagedModel === 0} onChange={() => setStagedModel(0)} />
                  }
                  label="Model 0 (Default)"
                />
              </Grid>
              {[1, 2, 3, 4, 5, 6, 7, 8].map((model) => (
                <Grid item key={model}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={stagedModel === model}
                        onChange={() => setStagedModel(model)}
                      />
                    }
                    label={`Model ${model}`}
                  />
                </Grid>
              ))}
            </Grid>
          </TextAccordion>
        )}
      </DialogContent>
      <DialogActions>
        {properties.includes('remove') && (
          <Button onClick={handleRemove} color="error">
            Delete
          </Button>
        )}
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={!stagedId || !stagedName}>
          Save
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default TemplateFieldSettingsDialog
