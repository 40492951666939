import { isApiError } from '@counsel-project/utils/api'
import toast from 'react-hot-toast'
import log from './logging'

const handleError = (err: unknown) => {
  if (isApiError(err)) {
    log.warn(err)
    toast.error(err.msg || 'An unexpected error has occurred')
  } else {
    log.error(err)
    toast.error('An unexpected error has occurred')
  }
}

export default handleError
