import { RKey } from '@counsel-project/counsel-keys-api'
import KeyRow from './KeyRow'
import TableBase from './TableBase'
import useMediaQuery from '@mui/material/useMediaQuery'

export type KeyTableProps = {
  searchDisabled?: boolean
  rows: RKey[]
  page: number
  rowsPerPage: number
  rowsPerPageOptions: number[]
  total: number
  loaded: boolean
  onChangePage?: (page: number) => void
  onChangeRowsPerPage?: (rowsPerPage: number) => void
  onClickRow?: (row: RKey) => void
  onSubmitSearch?: (search: string) => void
  onClickDelete?: (row: RKey) => void
}

const KeyTable = ({
  searchDisabled,
  rows,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  total,
  loaded,
  onChangePage,
  onChangeRowsPerPage,
  onClickRow,
  onSubmitSearch,
  onClickDelete,
}: KeyTableProps) => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <TableBase
      searchDisabled={searchDisabled}
      columns={[
        { field: 'name', label: 'Name', sortable: false, align: 'left' },
        { field: 'expiresAt', label: 'Expires', sortable: false, align: 'right' },
        {
          field: 'createdAt',
          label: 'Operations',
          sortable: false,
          align: 'right',
        },
      ]}
      rows={rows}
      renderRow={(row: RKey) => (
        <KeyRow
          key={row._id}
          isMobile={isMobile}
          data={row}
          onClick={onClickRow}
          onClickDelete={onClickDelete}
        />
      )}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      total={total}
      loaded={loaded}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSubmitSearch={onSubmitSearch}
    />
  )
}

export default KeyTable
