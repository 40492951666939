import Cookies from 'js-cookie'
import { setStore } from '../store/auth'

const logout = (storePageState?: boolean) => {
  Cookies.remove('token')
  Cookies.remove('expiresAt')
  Cookies.remove('refreshToken')
  setStore({
    user: null,
  })
  if (window.location.pathname === '/login') return
  if (!storePageState) {
    window.location.href = '/login'
    return
  }
  window.location.href = `/login?state=${encodeURIComponent(
    JSON.stringify({
      page: window.location.pathname + window.location.search,
    })
  )}`
}

export default logout
