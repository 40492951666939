import OpenInNewIcon from '@mui/icons-material/OpenInNewRounded'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

type NavCardProps = {
  title: string
  description?: string
  path: string
}

const NavCard = ({ title, description, path }: NavCardProps) => {
  const navigate = useNavigate()

  const handleNav = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault()
      navigate(path)
    },
    [navigate, path]
  )

  return (
    <Paper
      component="a"
      href={path}
      onClick={handleNav}
      elevation={0}
      sx={(theme) => ({
        p: 2,
        cursor: 'pointer',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        transition: 'box-shadow 0.3s',
        '&:hover': {
          boxShadow: theme.shadows[4],
        },
        display: 'block',
        textDecoration: 'none',
        height: '100%',
      })}
    >
      <Grid container alignItems="stretch">
        <Grid item xs>
          <Typography variant="h6">{title}</Typography>
          {description && (
            <Typography variant="body1" color="text.secondary">
              {description}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <IconButton color="primary">
            <OpenInNewIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default NavCard
