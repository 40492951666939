import { RCustomer } from '@counsel-project/counsel-external-api/common/database/RCustomer'
import DeleteIcon from '@mui/icons-material/DeleteRounded'
import UserIcon from '@mui/icons-material/PersonRounded'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import StyledTableCell from './StyledTableCell'
import StyledTableRow from './StyledTableRow'

type CustomerNameChipProps = {
  identifier: string
}

const CustomerNameChip = ({ identifier }: CustomerNameChipProps) => {
  return (
    <Chip
      icon={<UserIcon />}
      label={identifier}
      color="default"
      size="small"
      sx={{ py: 2, px: 1 }}
    />
  )
}

export type CustomerRowProps = {
  isMobile?: boolean
  data: RCustomer
  onClick?: (data: RCustomer) => void
  onClickDelete?: (data: RCustomer) => void
}

const CustomerRow = ({ data, onClick, onClickDelete }: CustomerRowProps) => {
  const { identifier, createdAt } = data

  return (
    <StyledTableRow onClick={() => onClick?.(data)}>
      <StyledTableCell
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 400,
        }}
      >
        <Typography
          variant="subtitle2"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          <CustomerNameChip identifier={identifier} />
        </Typography>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Typography variant="subtitle2">{new Date(createdAt).toLocaleDateString()}</Typography>
      </StyledTableCell>
      <StyledTableCell align="right">
        <IconButton onClick={() => onClickDelete?.(data)}>
          <DeleteIcon />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default CustomerRow
