import * as React from 'react'
import { styled, alpha } from '@mui/material/styles'
import InputBase, { InputBaseProps } from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import CircularProgress from '@mui/material/CircularProgress'

const Search = styled('form', { shouldForwardProp: (prop) => prop !== 'dark' })(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.action.hover, 0.05),
  transition: theme.transitions.create('background-color', {
    duration: theme.transitions.duration.shorter,
  }),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}))

type SearchIconWrapperProps = {
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

const SearchIconWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<SearchIconWrapperProps>(({ disabled, theme, onClick }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  height: '100%',
  position: 'absolute',
  pointerEvents: onClick && !disabled ? 'all' : 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: onClick && !disabled ? 'pointer' : 'default',
  zIndex: onClick && !disabled ? 1 : 0,
  ':hover': {
    backgroundColor: theme.palette.action.hover,
  },
  borderRadius: theme.shape.borderRadius,
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(6)})`,
    width: '100%',
  },
}))

export interface SearchBarProps extends InputBaseProps {
  loading?: boolean
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  onSubmit?: (e: React.FormEvent<HTMLDivElement | HTMLFormElement>) => void
}

const SearchBar = ({ loading, disabled, onSubmit, onClick, ...props }: SearchBarProps) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(e)
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (onSubmit) {
      e.preventDefault()
      onSubmit(e)
    }
  }

  return (
    <Search onSubmit={handleSubmit}>
      <SearchIconWrapper disabled={disabled} onClick={handleClick}>
        {loading ? (
          <CircularProgress size={20} />
        ) : (
          <SearchIcon
            sx={(theme) => ({
              color: theme.palette.text.secondary,
            })}
          />
        )}
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search…"
        inputProps={{ 'aria-label': 'search' }}
        disabled={disabled}
        {...props}
      />
    </Search>
  )
}

export default SearchBar
