import Tabs from './TabsPopper'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { useState } from 'react'
import Recorder from './Recorder'

const RecorderPopper: React.FC = () => {
  const [activeTab, setActiveTab] = useState('live-session')

  return (
    <Paper
      sx={{
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
        m: 2,
        width: 300,
      }}
    >
      <Tabs
        tabs={[
          {
            id: 'live-session',
            label: 'Live Session',
          },
          {
            id: 'dictation',
            label: 'Dictation',
          },
        ]}
        activeTab={activeTab}
        onClick={setActiveTab}
      />
      <Box sx={{ p: 2 }}>
        <Recorder dictation={activeTab !== 'live-session'} />
      </Box>
    </Paper>
  )
}

export default RecorderPopper
