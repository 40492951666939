import InfoIcon from '@mui/icons-material/InfoRounded'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'

export type TemperatureSliderProps = {
  temperature?: number
  onChange: (value: number) => void
}

const TemperatureSlider = ({ temperature, onChange }: TemperatureSliderProps) => {
  const temperatureVal = useMemo(
    () => (typeof temperature === 'undefined' ? 0.4 : temperature),
    [temperature]
  )

  return (
    <Grid container justifyContent="space-between" spacing={1} alignItems="stretch">
      <Grid item>
        <Typography variant="body1" fontSize={14} sx={{ mt: 0.5 }}>
          Determinate
        </Typography>
      </Grid>
      <Grid item xs>
        <Slider
          value={temperatureVal}
          onChange={(_, value) => {
            onChange(value as number)
          }}
          valueLabelDisplay="auto"
          step={0.1}
          marks
          min={0}
          max={1}
          size="small"
        />
      </Grid>
      <Grid item>
        <Typography variant="body1" color="text.secondary" fontSize={14} sx={{ mt: 0.5 }}>
          Creative
        </Typography>
      </Grid>
      <Grid item>
        <IconButton size="small" sx={{ ml: 1 }}>
          <InfoIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default TemperatureSlider
