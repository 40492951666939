import Button, { ButtonProps } from '@mui/material/Button'
import { alpha, styled, useTheme } from '@mui/material/styles'
import React from 'react'
import Chip from '@mui/material/Chip'

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
  height: 24,
  '& .MuiChip-label': {
    padding: theme.spacing(0.5, 0.75),
    fontSize: '0.75rem',
  },
}))

export type NavListButtonProps = ButtonProps & {
  title: string
  icon: React.ReactNode
  href: string
  active?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  tag?: string
}

const NavListButton = ({
  title,
  icon,
  href,
  active,
  onClick,
  sx,
  color,
  tag,
  ...props
}: NavListButtonProps) => {
  const theme = useTheme()

  const colorToUse = color === 'secondary' ? 'secondary' : 'primary'

  return (
    <Button
      href={href}
      LinkComponent={'a'}
      onClick={(e) => {
        e.preventDefault()
        onClick && onClick(e)
      }}
      startIcon={icon}
      color={color}
      endIcon={tag ? <StyledChip label={tag} /> : undefined}
      sx={{
        backgroundColor: active ? alpha(theme.palette[colorToUse].main, 0.1) : 'transparent',
        ...sx,
      }}
      {...props}
    >
      {title}
    </Button>
  )
}

export default NavListButton
