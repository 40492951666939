import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import Breadcrumbs, { BreadcrumbPath } from '../../../components/Breadcrumbs'
import PageContainer from '../../../components/layout/PageContainer'
import { keysRequest } from '../../../util/api/keys-api'
import getToken from '../../../util/auth/getToken'
import Skeleton from '@mui/material/Skeleton'
import handleError from '../../../util/handleError'

const PlatformBillingPage = () => {
  useTitle('API Platform Billing')

  const { id } = useParams()

  const [dashboardUrl, setDashboardUrl] = useState<string | null>(null)

  const populateDashboardUrl = useCallback(async () => {
    try {
      if (!id) return

      const token = await getToken()
      if (!token) return

      const { url } = await keysRequest.user.access.dashboard.get({
        token,
        accessId: id,
        type: 'invoices',
      })

      setDashboardUrl(url)
    } catch (err) {
      handleError(err)
    }
  }, [id])

  useEffect(() => {
    const timeout = setTimeout(populateDashboardUrl, 10)
    return () => clearTimeout(timeout)
  }, [populateDashboardUrl])

  const paths: BreadcrumbPath[] = [
    {
      name: 'API',
      path: '/platform',
    },
    {
      name: 'Console',
      path: `/platform/${id}`,
    },
    {
      name: 'Billing',
    },
  ]

  return (
    <PageContainer>
      <Breadcrumbs paths={paths} />
      <Typography variant="h4" gutterBottom>
        Billing
      </Typography>
      <Typography variant="body1" gutterBottom>
        {dashboardUrl ? (
          <iframe
            title="dashboard"
            id="dashboard-api"
            src={dashboardUrl}
            width="100%"
            height="800"
            style={{ border: 'none' }}
          />
        ) : (
          <Skeleton variant="rectangular" height={800} />
        )}
      </Typography>
    </PageContainer>
  )
}

export default PlatformBillingPage
