import LoadingButton from '@mui/lab/LoadingButton'
import DialogContent from '@mui/material/DialogContent'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useTitle } from 'react-use'
import ClosableDialog from '../components/ClosableDialog'
import PageContainer from '../components/layout/PageContainer'
import { authRequest } from '../util/api/auth-api'
import handleError from '../util/handleError'

const ResetPassword = () => {
  useTitle('Clinical Notes AI - Reset Password')
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [resetCode, setResetCode] = useState('')
  const [userId, setUserId] = useState('')
  const [password, setPassword] = useState('')

  const handleResetPassword = useCallback(async () => {
    try {
      setLoading(true)

      const data = await authRequest.user.password.resetWithEmail({
        email,
      })

      toast.success(data.msg)

      setUserId(data.userId)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [email])

  const handleConfirmResetPassword = useCallback(async () => {
    try {
      if (!password) {
        toast.error('Please enter a password')
        return
      }

      if (!resetCode) {
        toast.error('Please enter a reset code')
        return
      }

      setLoading(true)

      const data = await authRequest.user.password.finishResetWithEmail({
        userId,
        code: resetCode,
        password,
      })

      toast.success(data.msg)

      navigate('/login')
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [navigate, resetCode, userId, password])

  return (
    <PageContainer>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Typography variant="h4" component="h1" sx={{ mb: 4 }}>
          Reset your password
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          Enter your email address and we will send you a code to reset your password.
        </Typography>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2 }}
        />
        <LoadingButton fullWidth onClick={handleResetPassword} loading={loading} disabled={loading}>
          Send Reset Code
        </LoadingButton>
      </Paper>
      <ClosableDialog
        open={!!userId}
        onClose={() => {
          setUserId('')
        }}
        titleText="Reset Password"
      >
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Enter the code you received in your email and a new password.
          </Typography>
          <TextField
            label="Reset Code"
            variant="outlined"
            fullWidth
            value={resetCode}
            onChange={(e) => setResetCode(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="New Password"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <LoadingButton
            fullWidth
            onClick={handleConfirmResetPassword}
            loading={loading}
            disabled={loading}
          >
            Reset Password
          </LoadingButton>
        </DialogContent>
      </ClosableDialog>
    </PageContainer>
  )
}

export default ResetPassword
