import BackIcon from '@mui/icons-material/ArrowBackRounded'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { alpha } from '@mui/material/styles'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export type BreadcrumbPath = {
  name: string
  path?: string
}

type BreadcrumbsProps = {
  paths: BreadcrumbPath[]
  onClick?: (path: BreadcrumbPath) => void
}

const Breadcrumbs = ({ paths, onClick }: BreadcrumbsProps) => {
  const navigate = useNavigate()

  const createPathClickHandler =
    (path: BreadcrumbPath) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      if (onClick) {
        onClick(path)
      } else if (path.path) {
        navigate(path.path)
      }
    }

  return (
    <Box sx={{ py: 2 }}>
      <Grid container alignItems="center" spacing={2}>
        {paths[paths.length - 2] && (
          <Grid item>
            <Link
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                color: 'text.secondary',
                ':hover': {
                  color: 'primary.main',
                },
              }}
              onClick={createPathClickHandler(paths[paths.length - 2])}
              underline="none"
            >
              <BackIcon sx={{ mr: 1 }} />
              <Typography variant="body2">Back</Typography>
            </Link>
          </Grid>
        )}
        {paths.map((path, i) => (
          <Grid item key={i}>
            <Link
              variant="body2"
              color="textSecondary"
              sx={{
                ...(i === paths.length - 1 && {
                  color: alpha('#000', 0.8),
                }),
              }}
              underline="none"
              onClick={i === paths.length - 1 ? undefined : createPathClickHandler(path)}
              href={i === paths.length - 1 ? undefined : path.path}
            >
              {path.name}
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Breadcrumbs
