import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useNavigate } from 'react-router-dom'

const DemoMainPage = () => {
  const navigate = useNavigate()

  const navHandler = (path: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    navigate(path)
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            CNAI Widget Demo
          </Typography>
          <Typography variant="body1">
            Try out the Clinical Notes AI widget on this live demo
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            href="/demo/soap"
            onClick={navHandler('/demo/soap')}
          >
            SOAP
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            href="/demo/dap"
            onClick={navHandler('/demo/dap')}
          >
            DAP
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            href="/demo/treatment-plan"
            onClick={navHandler('/demo/treatment-plan')}
          >
            Treatment Plan
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DemoMainPage
