import { RAccess } from '@counsel-project/counsel-keys-api'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { keysRequest } from '../../util/api/keys-api'
import getToken from '../../util/auth/getToken'
import handleError from '../../util/handleError'

type AccessInvitesDialogProps = {
  onAddAccess: (access: RAccess) => void
}

const AccessInvitesDialog = ({ onAddAccess }: AccessInvitesDialogProps) => {
  const [loading, setLoading] = useState(false)

  const [accessInvites, setAccessInvites] = useState<RAccess[]>([])

  const handlePopulateAccessInvites = useCallback(async () => {
    try {
      setLoading(true)

      const token = await getToken()
      if (!token) return

      const { results } = await keysRequest.user.access.invites.list({
        token,
      })

      setAccessInvites(results)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(handlePopulateAccessInvites, 10)
    return () => clearTimeout(timeout)
  }, [handlePopulateAccessInvites])

  const handleAcceptInvite = useCallback(
    async (accessId: string) => {
      try {
        setLoading(true)

        const token = await getToken()
        if (!token) return

        const { result } = await keysRequest.user.access.invites.accept({
          token,
          accessId,
        })

        onAddAccess(result)
        setAccessInvites(accessInvites.filter((a) => a._id !== accessId))
      } catch (err) {
        handleError(err)
      } finally {
        setLoading(false)
      }
    },
    [onAddAccess, accessInvites]
  )

  const handleDeclineInvite = useCallback(
    async (accessId: string) => {
      try {
        setLoading(true)

        const token = await getToken()
        if (!token) return

        await keysRequest.user.access.invites.decline({
          token,
          accessId,
        })

        setAccessInvites(accessInvites.filter((a) => a._id !== accessId))
      } catch (err) {
        handleError(err)
      } finally {
        setLoading(false)
      }
    },
    [accessInvites]
  )

  return (
    <Dialog open={accessInvites.length !== 0} onClose={() => {}}>
      <DialogTitle>API Invite</DialogTitle>
      <DialogContent>
        {accessInvites.map((access) => (
          <Paper variant="outlined" key={access._id} sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {access.organization}
                </Typography>
              </Grid>
              <Grid item>
                <Button fullWidth onClick={() => handleAcceptInvite(access._id)} disabled={loading}>
                  Accept
                </Button>
              </Grid>
              <Grid item>
                <Button
                  fullWidth
                  onClick={() => handleDeclineInvite(access._id)}
                  disabled={loading}
                >
                  Decline
                </Button>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </DialogContent>
    </Dialog>
  )
}

export default AccessInvitesDialog
