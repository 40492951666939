import { ButtonProps } from '@mui/material/Button'
import React, { useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import MicrosoftButton from './MicrosoftButton'

type MicrosoftLoginButtonProps = Omit<ButtonProps, 'onError'> & {
  onError?: (err: string) => void
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onBrowserError: () => void
}

const MicrosoftLoginButton = ({
  onError,
  onClick,
  onBrowserError,
  ...props
}: MicrosoftLoginButtonProps) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const autoLogin = searchParams.get('autoLogin') === 'microsoft'

  const populateAutoLogin = useCallback(() => {
    if (autoLogin) {
      navigate(`/mslogin?${searchParams.toString()}`)
    }
  }, [autoLogin, searchParams, navigate])

  useEffectOnce(() => {
    populateAutoLogin()
  })

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(e)
    navigate(`/mslogin?${searchParams.toString()}`)
  }

  return <MicrosoftButton onClick={handleClick} {...props} disabled={autoLogin} />
}

export default MicrosoftLoginButton
