import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { NAV_ITEMS } from './_helpers'
import PageContainer from '../../components/layout/PageContainer'
import MarkdownView from './parser/MarkdownView'
import { RedocStandalone } from 'redoc'
import Box from '@mui/material/Box'
import SwaggerUI from 'swagger-ui-react'
import './swagger.css'

const MDPages = () => {
  const location = useLocation()

  const pathname = location.pathname

  const foundNavItem = NAV_ITEMS.map((item) => {
    const matches = item.href === pathname

    if (matches) {
      return item
    }

    // Check children

    if (item.children) {
      return item.children.find((child) => {
        const childMatches = child.href === pathname

        if (childMatches) {
          return child
        }
      })
    }

    return null
  }).find((item) => item)

  useEffect(() => {
    const interval = setInterval(() => {
      const redocify = document.querySelector('[href="https://redocly.com/redoc/"]')
      if (redocify) {
        redocify.parentElement?.remove()
      }
    }, 100)
    return () => clearInterval(interval)
  }, [])

  if (foundNavItem && foundNavItem.md) {
    return (
      <PageContainer>
        <MarkdownView markdown={foundNavItem.md} />
      </PageContainer>
    )
  }

  return (
    <Box sx={{ backgroundColor: '#292a30' }}>
      {/* <RedocStandalone
        specUrl="/openapi.yaml"
        options={{
          hideDownloadButton: true,
          hideHostname: true,
          hideFab: true,
          hideSingleRequestSampleTab: true,
          theme: {
            colors: {
              gray: {
                [50]: '#fff',
                [100]: '#fff',
              },
              tonalOffset: 0.1,
              primary: {
                main: '#765bfc',
                light: '#765bfc',
                dark: '#35009F',
                contrastText: '#fff',
              },
              text: {
                primary: '#fff',
                secondary: '#cfcfcf',
              },
              border: {
                dark: '#2b2d33',
                light: '#2b2d33',
              },
            },
            sidebar: {
              backgroundColor: '#1f2025',
              width: '0px',
              textColor: '#fff',
              activeTextColor: '#765bfc',
            },
            rightPanel: {
              backgroundColor: '#18191e',
              width: '280px',
              textColor: '#fff',
            },
          },
        }}
      /> */}
      <SwaggerUI url="/openapi.yaml" />
    </Box>
  )
}

export default MDPages
