import { useParams } from 'react-router-dom'
import Breadcrumbs, { BreadcrumbPath } from '../../../components/Breadcrumbs'
import { useTitle } from 'react-use'
import { useCallback, useEffect, useMemo, useState } from 'react'
import handleError from '../../../util/handleError'
import getToken from '../../../util/auth/getToken'
import { keysRequest } from '../../../util/api/keys-api'
import { Member, RAccess, RateLimit, RKey } from '@counsel-project/counsel-keys-api'
import PageContainer from '../../../components/layout/PageContainer'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import KeyTable from '../../../components/tables/KeyTable'
import Button from '@mui/material/Button'
import AccessMemberTable from '../../../components/tables/AccessMemberTable'
import Paper from '@mui/material/Paper'
import AddMemberDialog from '../AddMemberDialog'
import AddKeyDialog from '../AddKeyDialog'
import ConfirmDialog from '../../../components/ConfirmDialog'
import RateLimitCard from '../RateLimitCard'
import useUser from '../../../util/auth/useUser'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import EditRateLimitDialog from '../EditRateLimitDialog'
import AddRateLimitDialog from '../AddRateLimitDialog'
import NavCard from '../NavCard'
import AddCustomerDialog from '../AddCustomerDialog'

const PlatformAccessPage = () => {
  useTitle('API Platform')

  const { id } = useParams()

  const [access, setAccess] = useState<RAccess | null>(null)
  const [loading, setLoading] = useState(false)
  const [keys, setKeys] = useState<RKey[]>([])
  const [keysPage, setKeysPage] = useState(0)
  const [keysLimit, setKeysLimit] = useState(5)
  const shownKeys = useMemo(() => {
    return keys.slice(keysPage * keysLimit, (keysPage + 1) * keysLimit)
  }, [keys, keysPage, keysLimit])

  const [membersPage, setMembersPage] = useState(0)
  const [membersLimit, setMembersLimit] = useState(5)
  const shownMembers = useMemo(() => {
    if (!access) return []
    return access.members.slice(membersPage * membersLimit, (membersPage + 1) * membersLimit)
  }, [access, membersPage, membersLimit])

  const [addMemberOpen, setAddMemberOpen] = useState(false)
  const [addKeyOpen, setAddKeyOpen] = useState(false)

  const [stagedKey, setStagedKey] = useState<RKey | null>(null)
  const [stagedMember, setStagedMember] = useState<Member | null>(null)

  const [user] = useUser()
  const [admin, setAdmin] = useState(false)

  const [addRateLimitOpen, setAddRateLimitOpen] = useState(false)
  const [stagedRateLimit, setStagedRateLimit] = useState<RateLimit | null>(null)
  const [editRateLimitOpen, setEditRateLimitOpen] = useState(false)

  const [addCustomerIdOpen, setAddCustomerIdOpen] = useState(false)

  const handleDeleteStagedKey = useCallback(async () => {
    try {
      if (!stagedKey) return
      if (!id) return

      const token = await getToken()
      if (!token) return

      await keysRequest.user.access.keys.remove({
        token,
        accessId: id,
        keyId: stagedKey._id,
      })

      setKeys((keys) => keys.filter((key) => key._id !== stagedKey._id))
    } catch (err) {
      handleError(err)
    } finally {
      setStagedKey(null)
    }
  }, [id, stagedKey])

  const handleDeleteStagedMember = useCallback(async () => {
    try {
      if (!stagedMember) return
      if (!id) return

      const token = await getToken()
      if (!token) return

      await keysRequest.user.access.members.remove({
        token,
        accessId: id,
        email: stagedMember.email,
      })

      setAccess((access) => {
        if (!access) return access
        return {
          ...access,
          members: access.members.filter((member) => member.email !== stagedMember.email),
        }
      })
    } catch (err) {
      handleError(err)
    } finally {
      setStagedMember(null)
    }
  }, [id, stagedMember])

  const populateAccess = useCallback(async () => {
    try {
      if (!id) return

      setLoading(true)

      const token = await getToken()
      if (!token) return

      const { result } = await keysRequest.user.access.get({
        token,
        accessId: id,
      })

      setAccess(result)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [id])

  useEffect(() => {
    const timeout = setTimeout(populateAccess, 10)
    return () => clearTimeout(timeout)
  }, [populateAccess])

  const populateKeys = useCallback(async () => {
    try {
      if (!id) return

      setLoading(true)

      const token = await getToken()
      if (!token) return

      const { results } = await keysRequest.user.access.keys.list({
        token,
        accessId: id,
      })

      setKeys(results)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [id])

  useEffect(() => {
    const timeout = setTimeout(populateKeys, 10)
    return () => clearTimeout(timeout)
  }, [populateKeys])

  const paths: BreadcrumbPath[] = [
    {
      name: 'API',
      path: '/platform',
    },
    {
      name: 'Console',
      path: `/platform/${id}`,
    },
  ]

  return (
    <PageContainer>
      <Breadcrumbs paths={paths} />
      <Typography variant="h4" sx={{ mb: 2 }}>
        API Console
        <Typography variant="body1" component="span" sx={{ ml: 2 }}>
          {access?.organization}
        </Typography>
      </Typography>
      {user?.admin && (
        <FormControlLabel
          sx={{ mb: 2 }}
          control={<Switch checked={admin} onChange={() => setAdmin(!admin)} />}
          label="Admin View"
        />
      )}
      {admin && access && !access?.metronomeId && (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={6}>
            <Paper sx={{ p: 2 }} elevation={0}>
              <Typography variant="h6">Billing is not enabled on this account</Typography>
              <Button color="primary" onClick={() => setAddCustomerIdOpen(true)}>
                Add Customer ID
              </Button>
            </Paper>
          </Grid>
        </Grid>
      )}
      {admin && access && !!access?.metronomeId && (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={6}>
            <Paper sx={{ p: 2 }} elevation={0}>
              <Typography variant="h6">Billing is enabled</Typography>
              <Button color="primary" onClick={() => setAddCustomerIdOpen(true)}>
                Change Customer ID
              </Button>
            </Paper>
          </Grid>
        </Grid>
      )}
      {access?.metronomeId && (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={6}>
            <NavCard
              title="API Usage"
              description="View API usage statistics"
              path={`/platform/${id}/usage`}
            />
          </Grid>
          <Grid item xs={6}>
            <NavCard
              title="Billing"
              description="View billing information"
              path={`/platform/${id}/billing`}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={6}>
          <NavCard
            title="Dashboard"
            description="View Customer IDs and Templates"
            path={`/platform/${id}/dashboard`}
          />
        </Grid>
        <Grid item xs={6}>
          <NavCard
            title="Configure Components"
            description="Configure the fields & style of your components"
            path={`/platform/${id}/configure`}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} container>
          <Grid item xs={6}>
            <Typography variant="h5">Platform Members</Typography>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <Button color="primary" onClick={() => setAddMemberOpen(true)}>
              Add Member
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }} elevation={0}>
            <AccessMemberTable
              rows={shownMembers}
              page={membersPage}
              rowsPerPage={membersLimit}
              rowsPerPageOptions={[5, 10, 25, 50]}
              total={access?.members.length || 0}
              loaded={!loading}
              onChangePage={setMembersPage}
              onChangeRowsPerPage={setMembersLimit}
              onClickDelete={(row) => setStagedMember(row)}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} container>
          <Grid item xs={6}>
            <Typography variant="h5">API Keys</Typography>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <Button color="primary" onClick={() => setAddKeyOpen(true)}>
              Create API Key
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }} elevation={0}>
            <KeyTable
              rows={shownKeys}
              page={keysPage}
              rowsPerPage={keysLimit}
              rowsPerPageOptions={[5, 10, 25, 50]}
              total={keys.length}
              loaded={!loading}
              onChangePage={setKeysPage}
              onChangeRowsPerPage={setKeysLimit}
              onClickDelete={(row) => setStagedKey(row)}
            />
          </Paper>
        </Grid>
      </Grid>
      {id && access && (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                <Typography variant="h5">Rate Limits</Typography>
              </Grid>
              {admin && (
                <Grid item xs={6} container justifyContent="flex-end">
                  <Button color="primary" onClick={() => setAddRateLimitOpen(true)}>
                    Add Rate Limit
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          {access.limits.map((limit, index) => (
            <Grid item xs={12} key={index}>
              <RateLimitCard
                admin={admin}
                accessId={id}
                limits={access.limits}
                limit={limit}
                onUpdateAccess={setAccess}
                onClickEdit={() => {
                  setStagedRateLimit(limit)
                  setEditRateLimitOpen(true)
                }}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {id && (
        <AddMemberDialog
          open={addMemberOpen}
          onClose={() => setAddMemberOpen(false)}
          accessId={id}
          onUpdateAccess={populateAccess}
        />
      )}
      {id && (
        <AddKeyDialog
          open={addKeyOpen}
          onClose={() => setAddKeyOpen(false)}
          accessId={id}
          onAddKey={populateKeys}
        />
      )}
      <ConfirmDialog
        titleText="Delete Key"
        text="Are you sure you want to delete this key?"
        open={!!stagedKey}
        onClose={() => setStagedKey(null)}
        onConfirm={handleDeleteStagedKey}
      />
      <ConfirmDialog
        titleText="Delete Member"
        text="Are you sure you want to delete this member?"
        open={!!stagedMember}
        onClose={() => setStagedMember(null)}
        onConfirm={handleDeleteStagedMember}
      />
      {id && stagedRateLimit && (
        <EditRateLimitDialog
          accessId={id}
          limits={access?.limits || []}
          limit={stagedRateLimit}
          open={editRateLimitOpen}
          onClose={() => setEditRateLimitOpen(false)}
          onUpdateAccess={setAccess}
        />
      )}
      {id && (
        <AddRateLimitDialog
          accessId={id}
          limits={access?.limits || []}
          open={addRateLimitOpen}
          onClose={() => setAddRateLimitOpen(false)}
          onUpdateAccess={setAccess}
        />
      )}
      {id && (
        <AddCustomerDialog
          accessId={id}
          open={addCustomerIdOpen}
          onClose={() => setAddCustomerIdOpen(false)}
          onUpdateAccess={setAccess}
        />
      )}
    </PageContainer>
  )
}

export default PlatformAccessPage
