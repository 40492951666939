import { alpha, styled } from '@mui/material/styles'
import React from 'react'
import Button from '@mui/material/Button'

export type SelectableTabProps = {
  children: React.ReactNode
  onClick: () => void
  active: boolean
  color?: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success'
  small?: boolean
  disabled?: boolean
}

const SelectableTab = styled(Button, {
  shouldForwardProp: (prop: string) => !['active', 'color', 'small', 'disabled'].includes(prop),
})<SelectableTabProps>(({ theme, active, color = 'primary', small = false, disabled = false }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: small ? theme.spacing(0.5, 2) : theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  backgroundColor: active ? theme.palette[color].main : alpha(theme.palette[color].main, 0.15),
  color: active ? theme.palette[color].contrastText : theme.palette[color].main,
  fontSize: small ? 17 : 20,
  textAlign: 'center',
  height: '100%',
  width: '100%',
  fontWeight: 500,
  userSelect: 'none',
  transition: 'background-color 0.1s ease-in-out',
  '&:hover': {
    backgroundColor: active ? theme.palette[color].main : alpha(theme.palette[color].main, 0.25),
  },
  ...(disabled && {
    cursor: 'none',
    pointerEvents: 'none',
    backgroundColor: alpha(theme.palette.action.disabledBackground, 0.05),
    color: theme.palette.action.disabled,
    '&:hover': {
      backgroundColor: alpha(theme.palette.action.disabled, 0.15),
    },
  }),
}))

export default SelectableTab
