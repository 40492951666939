import ChangeIcon from '@mui/icons-material/ChangeCircleRounded'
import PlayIcon from '@mui/icons-material/PlayArrowRounded'
import StopIcon from '@mui/icons-material/StopRounded'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useMemo, useState } from 'react'
import AudioVisualizer from './AudioVisualizer'
import DeviceSelector from './DeviceSelector'
import { getIsRecording, startRecording, stopRecording } from './record-stream'

type RecorderProps = {
  dictation?: boolean
  onDataAvailable?: (buffer: ArrayBuffer) => void
  onStartRecording?: () => void
  onStopRecording?: () => void
  onAudioDetected?: () => void
}

const Recorder = ({
  dictation,
  onDataAvailable,
  onStartRecording,
  onStopRecording,
  onAudioDetected,
}: RecorderProps) => {
  const [recording, setRecording] = useState(false)
  const [analyser, setAnalyser] = useState<AnalyserNode | null>(null)
  const [loading, setLoading] = useState(false)
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
  const [activeDevice, setActiveDevice] = useState<MediaDeviceInfo | null>(null)
  const [changeDeviceDialogOpen, setChangeDeviceDialogOpen] = useState(false)
  const [useDesktopAudio, setUseDesktopAudio] = useState(false)

  const handleInit = useCallback(async () => {
    try {
      setLoading(true)

      console.log('record')
      const res = await startRecording({
        onDataAvailable,
        onAudioDetected,
        browserAudio: useDesktopAudio,
      })

      setActiveDevice(res.device)
      setDevices(res.devices)
      setAnalyser(res.analyser)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [onDataAvailable, onAudioDetected, useDesktopAudio])

  useEffect(() => {
    setLoading(true)
    const timeout = setTimeout(() => {
      if (getIsRecording()) return
      handleInit()
    }, 200)

    return () => {
      clearTimeout(timeout)
      stopRecording()
    }
  }, [handleInit, useDesktopAudio])

  const handleStopRecording = useCallback(async () => {
    try {
      setLoading(true)

      await stopRecording()

      onStopRecording?.()
      setRecording(false)
      setAnalyser(null)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [onStopRecording])

  const activeLabel = useMemo(() => {
    if (!activeDevice) return 'No device selected'
    const shortenedLabel = activeDevice.label.slice(0, 20)
    return shortenedLabel.length === 20 ? shortenedLabel + '...' : shortenedLabel
  }, [activeDevice])

  return (
    <Box>
      <Box sx={{ pb: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <AudioVisualizer analyser={analyser} width={200} height={40} />
      </Box>
      <Box>
        <Typography variant="body1" align="center">
          Audio input
        </Typography>
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item>
            <Box sx={{ maxWidth: 200, overflow: 'hidden' }}>
              <Typography variant="h6" align="center" textOverflow="ellipsis" noWrap>
                {activeLabel}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <IconButton size="small" onClick={() => setChangeDeviceDialogOpen(true)}>
              <ChangeIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      {!dictation && (
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={useDesktopAudio}
                onChange={() => setUseDesktopAudio((prev) => !prev)}
              />
            }
            label="Use Desktop Audio"
          />
          <Typography variant="body2" color="text.secondary">
            For telehealth sessions, Enable this option to record audio from your desktop
          </Typography>
        </Box>
      )}
      {dictation && (
        <Box>
          <Typography variant="body2" color="text.secondary">
            Speak to your personal scribe and dictate the details needed to generate your
            documentation
          </Typography>
        </Box>
      )}
      <Box>
        <Grid container spacing={1} justifyContent="center" sx={{ mt: 1 }}>
          <Grid item xs={6}>
            <Button disabled={recording || loading} endIcon={<PlayIcon />} fullWidth>
              Start
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={handleStopRecording}
              disabled={!recording || loading}
              endIcon={<StopIcon />}
              fullWidth
            >
              End
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Dialog open={changeDeviceDialogOpen} onClose={() => setChangeDeviceDialogOpen(false)}>
        <DialogTitle>Change audio input</DialogTitle>
        <DialogContent>
          <DeviceSelector
            devices={devices}
            value={activeDevice}
            onChange={(device) => {
              setActiveDevice(device)
              setChangeDeviceDialogOpen(false)
            }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default Recorder
