import Button, { ButtonProps } from '@mui/material/Button'
import { useMemo, forwardRef } from 'react'

type RecorderButtonProps = {
  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
  hidden?: boolean
  spacing?: number
  absolute?: boolean
} & Omit<ButtonProps, 'ref'>

const RecorderButton = forwardRef<HTMLButtonElement, RecorderButtonProps>(
  (
    { position, hidden, spacing = 20, children, absolute, sx, ...props }: RecorderButtonProps,
    ref
  ) => {
    const positionSx = useMemo(() => {
      switch (position) {
        case 'top-left':
          return {
            top: `${spacing}px`,
            left: `${spacing}px`,
          }
        case 'top-right':
          return {
            top: `${spacing}px`,
            right: `${spacing}px`,
          }
        case 'bottom-left':
          return {
            bottom: `${spacing}px`,
            left: `${spacing}px`,
          }
        case 'bottom-right':
          return {
            bottom: `${spacing}px`,
            right: `${spacing}px`,
          }
      }
    }, [position, spacing])

    return (
      <Button
        sx={{
          position: absolute ? 'absolute' : 'fixed',
          borderRadius: '120px',
          p: 1,
          display: hidden ? 'none' : 'flex',
          ...positionSx,
          ...sx,
        }}
        {...props}
        ref={ref}
      >
        {children}
      </Button>
    )
  }
)

export default RecorderButton
