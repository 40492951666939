import CloseIcon from '@mui/icons-material/Close'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography, { TypographyProps } from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

export type ClosableDialogProps = {
  titleText?: string
  titleTextProps?: TypographyProps
  disableClose?: boolean
  fullScreenOnMobile?: boolean
  open: boolean
  onClose: () => void
} & DialogProps

const ClosableDialog = ({
  titleText,
  titleTextProps,
  disableClose,
  fullScreenOnMobile,
  open,
  onClose,
  children,
  ...props
}: ClosableDialogProps) => {
  const matches = useMediaQuery('(max-width:600px)')

  const handleClose = () => {
    if (disableClose) return
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreenOnMobile && matches}
      maxWidth="sm"
      fullWidth
      {...props}
    >
      <DialogTitle sx={{ px: 2 }}>
        <Grid container justifyContent={!titleText ? 'end' : 'space-between'} alignItems="center">
          {!!titleText && (
            <Grid item>
              <Typography variant="h5" {...titleTextProps}>
                {titleText}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <IconButton onClick={handleClose} disabled={disableClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      {children}
    </Dialog>
  )
}

export default ClosableDialog
