import Box from '@mui/material/Box'
import Table, { TableProps } from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import React, { useCallback, useState } from 'react'
import SearchBar from '../SearchBar'
import NoDataDisplay from '../layout/NoDataDisplay'
import RowSkeleton from './RowSkeleton'
import StyledEmptyTableRow from './StyledEmptyTableRow'
import StyledTableCell from './StyledTableCell'
import StyledTableRow from './StyledTableRow'

export type TableColumn<T> = {
  field: T
  label: string
  sortable?: boolean
  align?: 'left' | 'right' | 'center'
  hidden?: boolean
}

export type TableBaseProps<
  T extends Record<string, unknown> = Record<string, unknown>,
  S extends string = string,
> = {
  tableProps?: TableProps
  searchDisabled?: boolean
  columns: TableColumn<keyof T>[]
  rows: T[]
  renderRow: (row: T) => React.ReactNode
  page: number
  rowsPerPage: number
  rowsPerPageOptions: number[]
  total: number
  loaded: boolean
  sort?: S
  direction?: 'asc' | 'desc'
  onChangeSort?: (sort: S, direction: 'asc' | 'desc') => void
  onChangePage?: (page: number) => void
  onChangeRowsPerPage?: (rowsPerPage: number) => void
  onSubmitSearch?: (search: string) => void
  rowHeight?: number
  placeholderRowHeight?: number
  noContentMessage?: string
}

const TableBase = <
  T extends Record<string, unknown> = Record<string, unknown>,
  S extends string = string,
>({
  tableProps,
  searchDisabled,
  columns,
  rows,
  renderRow,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  total,
  loaded,
  sort,
  direction,
  onChangeSort,
  onChangePage,
  onChangeRowsPerPage,
  onSubmitSearch,
  rowHeight = 32,
  placeholderRowHeight = 44,
  noContentMessage = 'Nothing here',
}: TableBaseProps<T, S>) => {
  const colSpan = columns.filter((column) => !column.hidden).length

  const [searchValue, setSearchValue] = useState('')

  const handleSubmitSearch = useCallback(() => {
    onSubmitSearch && onSubmitSearch(searchValue)
  }, [onSubmitSearch, searchValue])

  const handleChangeSearchValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === '') {
        onSubmitSearch && onSubmitSearch('')
      }
      setSearchValue(event.target.value)
    },
    [setSearchValue, onSubmitSearch]
  )

  const handleChangePage = (_e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    onChangePage && onChangePage(page)
  }

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!loaded) return
      onChangeRowsPerPage && onChangeRowsPerPage(parseInt(event.target.value, 10))
    },
    [loaded, onChangeRowsPerPage]
  )

  const createSortHandler = (property: S) => (_e: React.MouseEvent<unknown>) => {
    const isAsc = sort === property && direction === 'asc'
    onChangeSort && onChangeSort(property, isAsc ? 'desc' : 'asc')
  }

  return (
    <>
      {!!onSubmitSearch && (
        <SearchBar
          onChange={handleChangeSearchValue}
          value={searchValue}
          onSubmit={handleSubmitSearch}
          onClick={handleSubmitSearch}
          disabled={searchDisabled}
        />
      )}
      <TableContainer sx={{ mt: 1 }}>
        <Table size="small" {...tableProps}>
          <TableHead>
            <StyledTableRow>
              {columns.map((column) =>
                column.hidden ? (
                  <></>
                ) : (
                  <StyledTableCell key={column.field.toString()} align={column.align ?? 'left'}>
                    {!!onChangeSort ? (
                      <TableSortLabel
                        active={column.sortable ? sort === column.field : undefined}
                        direction={
                          column.sortable ? (sort === column.field ? direction : 'asc') : undefined
                        }
                        onClick={column.sortable ? createSortHandler(column.field as S) : undefined}
                      >
                        {column.label}
                        {sort === column.field ? (
                          <Box component="span" sx={visuallyHidden}>
                            {direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </StyledTableCell>
                )
              )}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {loaded && rows.map((row, i) => renderRow(row))}
            {!loaded &&
              Array.from(Array(rowsPerPage).keys()).map((i) => (
                <RowSkeleton key={i} colSpan={colSpan} height={rowHeight} />
              ))}
            {loaded && rows.length !== rowsPerPage && rows.length !== 0 && (
              <StyledEmptyTableRow
                style={{ height: placeholderRowHeight * (rowsPerPage - rows.length) }}
              >
                <StyledTableCell colSpan={colSpan} />
              </StyledEmptyTableRow>
            )}
            {loaded && rows.length === 0 && (
              <StyledEmptyTableRow
                sx={{ height: placeholderRowHeight * (rowsPerPage - rows.length) }}
              >
                <StyledTableCell colSpan={colSpan}>
                  <NoDataDisplay message={noContentMessage} />
                </StyledTableCell>
              </StyledEmptyTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!!onChangeRowsPerPage && !!onChangePage && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      {!onChangeRowsPerPage && onChangePage && total > rowsPerPage && (
        <TablePagination
          component="div"
          count={total}
          rowsPerPageOptions={[]}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      )}
    </>
  )
}

export default TableBase
