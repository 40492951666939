import BackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import Dialog from '@mui/material/Dialog'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { ApiError, isApiError } from '@counsel-project/utils/api'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import ClinicalNotesButton from '../components/auth/ClinicalNotesButton'
import GoogleButton from '../components/auth/GoogleButton'
import MicrosoftButton from '../components/auth/MicrosoftButton'
import PageContainer from '../components/layout/PageContainer'
import { authRequest } from '../util/api/auth-api'
import login from '../util/auth/login'
import log from '../util/logging'
import { attemptJSONParse } from './login'
import LoginBrowserErrorDialog from '../components/layout/LoginBrowserErrorDialog'
import AppleButton from '../components/auth/AppleButton'

export type VerifyRegisterDialogProps = {
  open: boolean
  onClose: () => void
}

export const VerifyRegisterDialog = ({ open, onClose }: VerifyRegisterDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Grid container padding={3} justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">Verify Your Email</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container padding={3} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">
            We have sent you an email with a link to verify your email address. Please click the
            link in the email to verify your email address.
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  )
}

const Register = () => {
  useTitle('Clinical Notes AI - Register')

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const code = searchParams.get('code')
  const userId = searchParams.get('userId')
  const state = searchParams.get('state')
  const page = attemptJSONParse(decodeURIComponent(state || ''))?.page

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const [verifyOpen, setVerifyOpen] = useState(false)
  const [tos, setTos] = useState(false)
  const [manualLogin, setManualLogin] = useState(false)
  const [browserError, setBrowserError] = useState(false)

  const handleToggleTos = () => {
    setTos(!tos)
  }

  const callbackUrl = `${window.location.protocol}//${window.location.host}/register${
    state ? `?state=${encodeURIComponent(state)}` : ''
  }`

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleChangeConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value)
  }

  const handleNavLogin = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    navigate(state ? `/login?state=${encodeURIComponent(state || '')}` : '/login')
  }

  const handleRegister = async () => {
    try {
      setLoading(true)
      if (!callbackUrl) return

      if (!tos) {
        setError('You must agree to our Terms of Service')
        return
      }

      if (!email) {
        setError('Email is required')
        return
      }

      if (!password) {
        setError('Password is required')
        return
      }

      if (password.length < 6) {
        setError('Password must be at least 6 characters')
        return
      }

      if (password.length > 200) {
        setError('Password must be less than 200 characters')
        return
      }

      if (password !== confirmPassword) {
        setError('Passwords do not match')
        return
      }

      setError('')

      try {
        await authRequest.user.register.init({
          email,
          callbackUrl,
          password,
        })

        setVerifyOpen(true)
      } catch (err) {
        if (!isApiError(err)) {
          throw err
        }

        console.log(err.msg)

        const data = await authRequest.user.login.withEmail({
          email,
          password,
        })

        if ('twoFactorAuth' in data) {
          navigate(`/login/two-factor?email=${email}&id=${data.userId}`)
          return
        }

        login({
          user: data.user,
          token: data.token,
          expiresAt: data.expiresAt,
          refreshToken: data.refreshToken,
        })

        log.info('Logged in')

        if (page) {
          navigate(page)
        } else {
          navigate('/')
        }
      }
    } catch (err) {
      log.error(err)
      if (isApiError(err)) {
        setError(err.msg || err.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleRegister()
  }

  const handleCloseVerify = () => {
    setVerifyOpen(false)
  }

  const verifyUser = useCallback(async () => {
    try {
      if (!code || !userId) return

      const redirectPage = !page || page === '/login' || page === '/register' ? '/' : page

      const data = await authRequest.user.register.verify({
        code,
        userId,
      })

      if (data.token) {
        login({
          token: data.token,
          user: data.user,
          expiresAt: data.expiresAt,
          refreshToken: data.refreshToken,
        })
        navigate(redirectPage)
        return
      }

      toast.success('Successfully signed up!')
      navigate('/login')
    } catch (err) {
      log.error(err)
      if (isApiError(err)) {
        setError(err.msg || err.message)
      }
    }
  }, [code, userId, navigate, page])

  useEffect(() => {
    verifyUser()
  }, [verifyUser])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleClickGoogleRegister = () => {
    searchParams.set('autoLogin', 'google')
    setSearchParams(searchParams)
    navigate(`/login?${searchParams.toString()}`)
  }

  const handleClickMicrosoftRegister = () => {
    searchParams.set('autoLogin', 'microsoft')
    setSearchParams(searchParams)
    navigate(`/login?${searchParams.toString()}`)
  }

  const handleClickAppleRegister = () => {
    searchParams.set('autoLogin', 'apple')
    setSearchParams(searchParams)
    navigate(`/login?${searchParams.toString()}`)
  }

  return (
    <PageContainer>
      <LoginBrowserErrorDialog open={browserError} onClose={() => setBrowserError(false)} />
      <VerifyRegisterDialog open={verifyOpen} onClose={handleCloseVerify} />
      {!manualLogin ? (
        <Paper
          elevation={0}
          sx={{ p: 0, borderRadius: { xs: 0, sm: 1 }, mb: 2, background: 'transparent' }}
        >
          <Grid container justifyContent="center" padding={2}>
            <Grid item>
              <Typography variant="h5">Create Your Account</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GoogleButton onClick={handleClickGoogleRegister} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <MicrosoftButton fullWidth onClick={handleClickMicrosoftRegister} />
            </Grid>
            <Grid item xs={12}>
              <AppleButton fullWidth onClick={handleClickAppleRegister} />
            </Grid>
            <Grid item xs={12}>
              <ClinicalNotesButton fullWidth onClick={() => setManualLogin(true)} />
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Paper elevation={0} sx={{ p: 2, mb: 2 }}>
          <Grid container padding={2} alignItems="center" spacing={2}>
            <Grid item>
              <IconButton onClick={() => setManualLogin(false)}>
                <BackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h5">Create Account</Typography>
            </Grid>
          </Grid>
          <FormControl component={'form'} sx={{ width: '100%' }} onSubmit={handleSubmit}>
            <Grid container justifyContent="center" direction="column" padding={2} spacing={2}>
              <Grid item xs={12}>
                <Collapse in={Boolean(error)}>
                  <Alert severity="error">{error}</Alert>
                </Collapse>
              </Grid>
              <Grid item>
                <TextField
                  placeholder="E-mail"
                  type="email"
                  aria-label="signup-email"
                  autoComplete="off"
                  fullWidth
                  onChange={handleChangeEmail}
                  value={email}
                  disabled={loading || !!code}
                />
              </Grid>
              <Grid item>
                <TextField
                  placeholder="Password"
                  type="password"
                  aria-label="signup-password"
                  autoComplete="off"
                  fullWidth
                  onChange={handleChangePassword}
                  value={password}
                  disabled={loading || !!code}
                />
              </Grid>
              <Grid item>
                <TextField
                  placeholder="Confirm Password"
                  type="password"
                  aria-label="signup-confirm-password"
                  autoComplete="off"
                  fullWidth
                  onChange={handleChangeConfirmPassword}
                  value={confirmPassword}
                  disabled={loading || !!code}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tos}
                      onChange={handleToggleTos}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={
                    <Typography variant="body2" color="text.secondary">
                      I agree to the{' '}
                      <Link href="/terms-of-service" target="_blank">
                        Terms of Service
                      </Link>{' '}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item>
                <Button fullWidth type="submit" disabled={loading || !!code}>
                  Continue
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </Paper>
      )}
      <Grid container justifyContent="center" padding={2}>
        <Grid item>
          <Typography>
            Already have an account? Login{' '}
            <Link onClick={handleNavLogin} href="/register">
              here
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </PageContainer>
  )
}

export default Register
