import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

const DAP = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={2}
        cnai-template-id="DAP"
        cnai-template-name="DAP Note"
        cnai-template-type="Progress Note"
      >
        <Grid item xs={12}>
          <Typography variant="h4">DAP Note</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Data</Typography>
          <TextField
            multiline
            fullWidth
            rows={6}
            placeholder="Enter data information here"
            inputProps={{
              'cnai-template-field-id': 'data',
              'cnai-template-field-name': 'Data',
              'cnai-template-field-type': 'string',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Assessment</Typography>
          <TextField
            multiline
            fullWidth
            rows={6}
            placeholder="Enter assessment information here"
            inputProps={{
              'cnai-template-field-id': 'assessment',
              'cnai-template-field-name': 'Assessment',
              'cnai-template-field-type': 'string',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Plan</Typography>
          <TextField
            multiline
            fullWidth
            rows={6}
            placeholder="Enter plan information here"
            inputProps={{
              'cnai-template-field-id': 'plan',
              'cnai-template-field-name': 'Plan',
              'cnai-template-field-type': 'string',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained">Save</Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DAP
