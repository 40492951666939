import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

const TreatmentPlan = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={2}
        cnai-template-id="Treatment Plan"
        cnai-template-name="Treatment Plan"
        cnai-template-type="Treatment Plan"
      >
        <Grid item xs={12}>
          <Typography variant="h4">Treatment Plan</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Presenting Problem</Typography>
          <TextField
            multiline
            fullWidth
            rows={6}
            placeholder="Presenting problem here"
            inputProps={{
              'cnai-template-field-id': 'presenting-problem',
              'cnai-template-field-name': 'Presenting Problem',
              'cnai-template-field-type': 'string',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Client's Goal</Typography>
          <TextField
            multiline
            fullWidth
            rows={6}
            placeholder="Enter the client's goal here"
            inputProps={{
              'cnai-template-field-id': 'clients-goal',
              'cnai-template-field-name': 'Goal',
              'cnai-template-field-type': 'string',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Treatment Goal</Typography>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  // @ts-expect-error cnai-template-field-id is not a valid prop
                  'cnai-template-field-id': 'treatment-goal',
                  'cnai-template-field-name': 'Treatment Goal',
                  'cnai-template-field-type': 'multiple_choice',
                  'cnai-template-field-option': 'Client will become less depressed',
                }}
              />
            }
            label="Client will become less depressed"
          />
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  // @ts-expect-error cnai-template-field-id is not a valid prop
                  'cnai-template-field-id': 'treatment-goal',
                  'cnai-template-field-name': 'Treatment Goal',
                  'cnai-template-field-type': 'multiple_choice',
                  'cnai-template-field-option': 'Client will become less anxious',
                }}
              />
            }
            label="Client will become less anxious"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Intervention</Typography>
          <TextField
            multiline
            fullWidth
            rows={6}
            placeholder="Enter intervention information here"
            inputProps={{
              'cnai-template-field-id': 'intervention',
              'cnai-template-field-name': 'Intervention',
              'cnai-template-field-type': 'string',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Frequency</Typography>
          <TextField
            multiline
            fullWidth
            rows={6}
            placeholder="Enter frequency information here"
            inputProps={{
              'cnai-template-field-id': 'frequency',
              'cnai-template-field-name': 'Frequency',
              'cnai-template-field-type': 'string',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained">Save</Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TreatmentPlan
