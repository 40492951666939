import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

export type FormatSelectorProps = {
  value: 'bullet' | 'numbered' | 'paragraph' | 'none'
  onChange: (value: 'bullet' | 'numbered' | 'paragraph' | 'none') => void
}

const FormatSelector = ({ value, onChange }: FormatSelectorProps) => {
  return (
    <Select
      value={value}
      onChange={(e) => onChange(e.target.value as 'bullet' | 'numbered' | 'paragraph' | 'none')}
      size="small"
      sx={{
        minWidth: 100,
      }}
    >
      <MenuItem value="bullet">Bullet</MenuItem>
      <MenuItem value="numbered">Numbered Bullet</MenuItem>
      <MenuItem value="paragraph">Paragraph</MenuItem>
      <MenuItem value="none">(Auto)</MenuItem>
    </Select>
  )
}

export default FormatSelector
