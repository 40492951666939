import { alpha, createTheme, responsiveFontSizes } from '@mui/material/styles'

let theme = createTheme({
  palette: {
    primary: {
      main: '#6553bd',
      dark: '#35009F',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FB5086',
      contrastText: '#fff',
    },
    background: {
      default: '#f3f2f5',
      paper: '#ffffff',
    },
    info: {
      main: '#575757',
      dark: '#3a3a3a',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#3a3a3a',
      secondary: '#757575',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Comfortaa, cursive',
    },
    h2: {
      fontFamily: 'Comfortaa, cursive',
    },
    h3: {
      fontFamily: 'Comfortaa, cursive',
    },
    h4: {
      fontFamily: 'Comfortaa, cursive',
    },
    h5: {
      fontFamily: 'Comfortaa, cursive',
    },
    h6: {
      fontFamily: 'Comfortaa, cursive',
    },
    fontFamily: 'Mukta, sans-serif',
    button: {
      textTransform: 'none',
      fontWeight: 500,
      fontSize: '1em',
      lineHeight: 1.75,
    },
  },
  shape: {
    borderRadius: 12,
  },
  spacing: 8,
  shadows: [
    'none',
    'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;',
    'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;',
    'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;',
    'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;',
    'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;',
    'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;',
    'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;',
    'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;',
    'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;',
    'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;',
    'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;',
    'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;',
    'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;',
    'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;',
    'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
    'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
    'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
    'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px',
    'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px',
    'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px',
  ],
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
  },
  components: {
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 16,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 16,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 16,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: theme.palette.action.focus,
            },
            '&:hover fieldset': {
              borderColor: theme.palette.action.disabled,
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.primary.main,
            },
          },
        }),
      },
    },
    MuiButtonBase: {
      defaultProps: {
        // The props to apply
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiAutocomplete-endAdornment': {
            '& .MuiButtonBase-root': {
              marginLeft: 8,
            },
          },
        }),
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'text', color: 'info' },
          style: ({ theme }) => ({
            color: theme.palette.text.secondary,
            backgroundColor: alpha(theme.palette.info.main, 0.1),
            ':hover': {
              borderColor: theme.palette.info.main,
              backgroundColor: alpha(theme.palette.info.main, 0.15),
            },
          }),
        },
        {
          props: { variant: 'text', color: 'warning' },
          style: ({ theme }) => ({
            color: theme.palette.warning.main,
            backgroundColor: alpha(theme.palette.warning.main, 0.1),
            ':hover': {
              borderColor: theme.palette.warning.main,
              backgroundColor: alpha(theme.palette.warning.main, 0.15),
            },
            ':disabled': {
              color: theme.palette.grey[500],
              backgroundColor: alpha(theme.palette.grey[500], 0.1),
            },
          }),
        },
        {
          props: { variant: 'text', color: 'error' },
          style: ({ theme }) => ({
            color: theme.palette.error.main,
            backgroundColor: alpha(theme.palette.error.main, 0.1),
            ':hover': {
              borderColor: theme.palette.error.main,
              backgroundColor: alpha(theme.palette.error.main, 0.15),
            },
            ':disabled': {
              color: theme.palette.grey[500],
              backgroundColor: alpha(theme.palette.grey[500], 0.1),
            },
          }),
        },
        {
          props: { variant: 'contained', color: 'error' },
          style: ({ theme }) => ({
            ':disabled': {
              color: theme.palette.error.contrastText,
              backgroundColor: alpha(theme.palette.error.main, 0.2),
            },
          }),
        },
        {
          props: { variant: 'contained', color: 'warning' },
          style: ({ theme }) => ({
            ':disabled': {
              color: theme.palette.warning.contrastText,
              backgroundColor: alpha(theme.palette.warning.main, 0.2),
            },
          }),
        },
        {
          props: { variant: 'text', color: 'secondary' },
          style: ({ theme }) => ({
            color: theme.palette.secondary.main,
            backgroundColor: alpha(theme.palette.secondary.main, 0.075),
            ':hover': {
              borderColor: theme.palette.secondary.main,
              backgroundColor: alpha(theme.palette.secondary.main, 0.15),
            },
            ':disabled': {
              color: theme.palette.grey[500],
              backgroundColor: alpha(theme.palette.grey[500], 0.1),
            },
          }),
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: ({ theme }) => ({
            color: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            ':hover': {
              borderColor: theme.palette.primary.main,
              backgroundColor: alpha(theme.palette.primary.main, 0.15),
            },
            ':disabled': {
              color: theme.palette.grey[500],
              backgroundColor: alpha(theme.palette.grey[500], 0.1),
            },
          }),
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: ({ theme }) => ({
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            borderWidth: 0,
            ':hover': {
              borderWidth: 0,
              borderColor: theme.palette.primary.main,
              backgroundColor: alpha(theme.palette.primary.main, 0),
            },
            ':disabled': {
              borderWidth: 0,
              color: theme.palette.grey[500],
              borderColor: theme.palette.grey[500],
            },
          }),
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: ({ theme }) => ({
            color: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
            borderWidth: 0,
            ':hover': {
              borderWidth: 0,
              borderColor: theme.palette.secondary.main,
              backgroundColor: alpha(theme.palette.secondary.main, 0),
            },
            ':disabled': {
              borderWidth: 0,
              color: theme.palette.grey[500],
              borderColor: theme.palette.grey[500],
            },
          }),
        },
        {
          props: { variant: 'outlined', color: 'error' },
          style: ({ theme }) => ({
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
            borderWidth: 0,
            ':hover': {
              borderWidth: 0,
              borderColor: theme.palette.error.main,
              backgroundColor: alpha(theme.palette.error.main, 0),
            },
            ':disabled': {
              borderWidth: 0,
              color: theme.palette.grey[500],
              borderColor: theme.palette.grey[500],
            },
          }),
        },
        {
          props: { variant: 'outlined', color: 'info' },
          style: ({ theme }) => ({
            color: theme.palette.info.main,
            borderColor: theme.palette.info.main,
            borderWidth: 0,
            ':hover': {
              borderWidth: 0,
              borderColor: theme.palette.info.main,
              backgroundColor: alpha(theme.palette.info.main, 0),
            },
            ':disabled': {
              borderWidth: 0,
              color: theme.palette.grey[500],
              borderColor: theme.palette.grey[500],
            },
          }),
        },
        {
          props: { variant: 'outlined', color: 'inherit' },
          style: ({ theme }) => ({
            color: theme.palette.text.secondary,
            borderColor: theme.palette.text.secondary,
            borderWidth: 0,
            ':hover': {
              borderWidth: 0,
              borderColor: theme.palette.text.secondary,
              backgroundColor: alpha(theme.palette.text.secondary, 0),
            },
            ':disabled': {
              borderWidth: 0,
              color: theme.palette.grey[500],
              borderColor: theme.palette.grey[500],
            },
          }),
        },
        {
          props: { color: 'inherit' },
          style: ({ theme }) => ({
            color: theme.palette.text.secondary,
            backgroundColor: alpha(theme.palette.text.secondary, 0.05),
            ':hover': {
              backgroundColor: alpha(theme.palette.text.secondary, 0.1),
            },
            ':disabled': {
              color: theme.palette.grey[500],
              backgroundColor: alpha(theme.palette.grey[500], 0.1),
            },
          }),
        },
      ],
      styleOverrides: {
        root: {
          padding: 8,
          paddingLeft: 16,
          paddingRight: 16,
        },
      },
    },
    // Make input label float above outline border and fill in with background color
    // MuiInputLabel: {
    //   styleOverrides: {
    //     root: ({ theme }) => ({
    //       transform: 'translate(16px, -16px)',
    //       background: 'transparent',
    //     }),
    //   },
    // },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: 500,
          fontSize: '0.75rem',
          paddingLeft: 2,
          paddingRight: 2,
          // Changed padding on icon
          '& .MuiChip-icon': {
            height: 16,
          },
        }),
      },
      variants: [
        {
          props: { color: 'primary', variant: 'outlined' },
          style: ({ theme }) => ({
            color: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          }),
        },
        {
          props: { color: 'secondary', variant: 'outlined' },
          style: ({ theme }) => ({
            color: theme.palette.secondary.main,
            backgroundColor: alpha(theme.palette.secondary.main, 0.1),
          }),
        },
        {
          props: { color: 'error', variant: 'outlined' },
          style: ({ theme }) => ({
            color: theme.palette.error.main,
            backgroundColor: alpha(theme.palette.error.main, 0.1),
          }),
        },
        {
          props: { color: 'info', variant: 'outlined' },
          style: ({ theme }) => ({
            color: theme.palette.info.main,
            backgroundColor: alpha(theme.palette.info.main, 0.1),
          }),
        },
        {
          props: { color: 'warning', variant: 'outlined' },
          style: ({ theme }) => ({
            color: theme.palette.warning.main,
            backgroundColor: alpha(theme.palette.warning.main, 0.1),
          }),
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: theme.palette.grey[300],
            },
            '&:hover fieldset': {
              borderColor: theme.palette.grey[400],
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.primary.main,
              color: theme.palette.text.primary,
            },
            '&.Mui-disabled fieldset': {
              borderColor: theme.palette.grey[300],
              backgroundColor: alpha(theme.palette.action.disabledBackground, 0.025),
              '& legend': {
                color: theme.palette.text.disabled,
              },
            },
          },
        }),
      },
    },
    MuiBadge: {
      variants: [
        {
          props: { color: 'info' },
          style: ({ theme }) => ({
            '& .MuiBadge-badge': {
              backgroundColor: alpha(theme.palette.info.main, 0.15),
            },
          }),
        },
      ],
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiBadge-badge': {
            backgroundColor: theme.palette.error.main,
          },
        }),
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.default,
          borderRadius: theme.shape.borderRadius,
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
        }),
      },
      variants: [
        {
          props: { size: 'small' },
          style: ({ theme }) => ({
            // Svg icon size
            '& .MuiSvgIcon-root': {
              fontSize: 20,
            },
            height: 32,
            width: 32,
          }),
        },
        {
          props: { color: 'primary' },
          style: ({ theme }) => ({
            color: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            ':hover': {
              backgroundColor: alpha(theme.palette.primary.main, 0.15),
            },
          }),
        },
        {
          props: { color: 'secondary' },
          style: ({ theme }) => ({
            color: theme.palette.secondary.main,
            backgroundColor: alpha(theme.palette.secondary.main, 0.075),
            ':hover': {
              backgroundColor: alpha(theme.palette.secondary.main, 0.15),
            },
          }),
        },
        {
          props: { color: 'error' },
          style: ({ theme }) => ({
            color: theme.palette.error.main,
            backgroundColor: alpha(theme.palette.error.main, 0.1),
            ':hover': {
              backgroundColor: alpha(theme.palette.error.main, 0.15),
            },
          }),
        },
        {
          props: { color: 'inherit' },
          style: ({ theme }) => ({
            color: theme.palette.text.secondary,
            backgroundColor: alpha(theme.palette.info.main, 0.05),
            ':hover': {
              backgroundColor: alpha(theme.palette.info.main, 0.1),
            },
          }),
        },
        {
          props: { color: 'default' },
          style: ({ theme }) => ({
            color: theme.palette.text.secondary,
            backgroundColor: alpha(theme.palette.info.main, 0.05),
            ':hover': {
              backgroundColor: alpha(theme.palette.info.main, 0.1),
            },
          }),
        },
        {
          props: { color: 'info' },
          style: ({ theme }) => ({
            color: theme.palette.text.secondary,
            backgroundColor: alpha(theme.palette.info.main, 0.05),
            ':hover': {
              backgroundColor: alpha(theme.palette.info.main, 0.1),
            },
          }),
        },
        {
          props: { color: 'warning' },
          style: ({ theme }) => ({
            color: theme.palette.warning.main,
            backgroundColor: alpha(theme.palette.warning.main, 0.1),
            ':hover': {
              backgroundColor: alpha(theme.palette.warning.main, 0.15),
            },
          }),
        },
      ],
    },
  },
})

theme = responsiveFontSizes(theme)

export default theme
