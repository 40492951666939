import Box from '@mui/material/Box'
import { NAV_ITEMS } from '../_helpers'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import LeftPanel from './LeftPanel'
import LeftPanelItem from './LeftPanelItem'
import Button from '@mui/material/Button'
import BackIcon from '@mui/icons-material/ArrowBackRounded'
import Grid from '@mui/material/Grid'
import { ThemeOptions, ThemeProvider, createTheme } from '@mui/material/styles'
import theme from '../../../util/theme'
import PageContainer from '../../../components/layout/PageContainer'
import { useMemo } from 'react'

const darkThemeObj: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#765bfc',
      dark: '#35009F',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FB5086',
      contrastText: '#fff',
    },
    background: {
      default: '#18191e',
      paper: '#292a30',
    },
    info: {
      main: '#b0b0b0',
      dark: '#3a3a3a',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#f5f5f5',
      secondary: '#cfcfcf',
      disabled: '#8a8a8a',
    },
  },
}

const darkTheme = createTheme(theme, darkThemeObj)

const DocsLayout = () => {
  const navigate = useNavigate()

  const navHandler = (path: string) => (e: React.MouseEvent) => {
    e.preventDefault()
    navigate(path)
  }

  const location = useLocation()

  const pathname = location.pathname

  const nextPage = useMemo(() => {
    const nextNavItem = NAV_ITEMS.map((item, index) => {
      const childIndex = item?.children?.findIndex((child, childIndex) => {
        if (child.href === pathname) {
          return true
        }
        return false
      })

      if (childIndex !== undefined && childIndex !== -1) {
        const nextChild = item?.children?.[childIndex + 1] || undefined
        if (nextChild) return nextChild
        return NAV_ITEMS[index + 1] || undefined
      }

      if (item.href === pathname) {
        if (item.children) return item.children[0]
        return NAV_ITEMS[index + 1] || undefined
      }

      return undefined
    }).find((i) => i)

    return nextNavItem
  }, [pathname])

  const previousPage = useMemo(() => {
    const previousNavItem = NAV_ITEMS.map((item, index) => {
      const childIndex = item?.children?.findIndex((child, childIndex) => {
        if (child.href === pathname) {
          return true
        }
        return false
      })

      if (childIndex !== undefined && childIndex !== -1) {
        const previousChild = item?.children?.[childIndex - 1] || undefined
        if (previousChild) return previousChild
        return NAV_ITEMS[index - 1] || undefined
      }

      if (item.href === pathname) {
        if (item.children) return item.children[item.children.length - 1]
        return NAV_ITEMS[index - 1] || undefined
      }

      return undefined
    }).find((i) => i)

    return previousNavItem
  }, [pathname])

  const handleNavNext = () => {
    if (nextPage) {
      navigate(nextPage.href)
    }
  }

  const handleNavPrevious = () => {
    if (previousPage) {
      navigate(previousPage.href)
    }
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          minHeight: '100vh',
          alignContent: 'stretch',
          justifyContent: 'stretch',
          alignItems: 'stretch',
          flexDirection: 'row',
          alignSelf: 'stretch',
          width: '100%',
        }}
      >
        <LeftPanel>
          <Grid
            container
            spacing={2}
            padding={2}
            alignContent="stretch"
            alignSelf="stretch"
            justifyContent="stretch"
          >
            <Grid item xs={12}>
              <LeftPanelItem
                item={{
                  title: 'Back',
                  icon: <BackIcon />,
                  href: '/platform',
                }}
                active={true}
              />
            </Grid>
            {NAV_ITEMS.map((item) => (
              <Grid item xs={12} key={item.title}>
                <LeftPanelItem
                  item={item}
                  active={window.location.pathname.startsWith(item.href)}
                />
              </Grid>
            ))}
          </Grid>
        </LeftPanel>
        <Box
          sx={{
            pl: '240px',
            backgroundColor: 'background.default',
            width: '100%',
            overflowX: 'hidden',
          }}
        >
          <Outlet />
          <PageContainer>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button disabled={!previousPage} onClick={handleNavPrevious}>
                  Previous
                </Button>
              </Grid>
              <Grid item>
                <Button disabled={!nextPage} onClick={handleNavNext}>
                  Next
                </Button>
              </Grid>
            </Grid>
          </PageContainer>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default DocsLayout
