import { RCustomer } from '@counsel-project/counsel-external-api/common/database/RCustomer'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { useCallback, useState } from 'react'
import ClosableDialog from '../../../components/ClosableDialog'
import LabeledTextField from '../../../components/layout/LabeledTextField'
import { externalRequest } from '../../../util/api/external-api'
import getToken from '../../../util/auth/getToken'
import handleError from '../../../util/handleError'

type CreateCustomerDialogProps = {
  accessId: string
  open: boolean
  onClose: () => void
  onCreated: (customer: RCustomer) => void
}

const CreateCustomerDialog = ({
  accessId,
  open,
  onClose,
  onCreated,
}: CreateCustomerDialogProps) => {
  const [stagedId, setStagedId] = useState('')
  const [loading, setLoading] = useState(false)

  const handleCreateCustomer = useCallback(async () => {
    try {
      setLoading(true)

      const token = await getToken()
      if (!token) return

      const { result } = await externalRequest.user.customers.add({
        token,
        identifier: stagedId,
        accessId,
      })

      onCreated(result)
      onClose()
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [accessId, stagedId, onCreated, onClose])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Create Customer">
      <DialogContent>
        <LabeledTextField
          label="Customer ID"
          value={stagedId}
          onChange={(e) => setStagedId(e.target.value)}
          placeholder="Enter a unique identifier for the customer"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleCreateCustomer} disabled={loading}>
          Create
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default CreateCustomerDialog
