import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    outlineBottom: 'none',
    border: 'none',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}))

export default StyledTableCell
