import { alpha, styled } from '@mui/material/styles'
import TableRow from '@mui/material/TableRow'

const StyledEmptyTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd) td': {
    backgroundColor: alpha(theme.palette.background.paper, 0.5),
  },
  td: {
    border: 0,
    borderRadius: theme.shape.borderRadius,
  },
}))

export default StyledEmptyTableRow
