import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'

type InitializeDemoDialogProps = {
  open: boolean
  onAgree: () => void
  onCancel: () => void
}

const InitializeDemoDialog = ({ open, onAgree, onCancel }: InitializeDemoDialogProps) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Create Test API Access Key</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          In order to display a proper demo, we will need to create a temporary API access key.
        </Typography>
        <Typography variant="body1">This key will be prefixed with "Demo Key"</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAgree}>Create Temporary Key</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

export default InitializeDemoDialog
