import { RCustomer } from '@counsel-project/counsel-external-api/common/database/RCustomer'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import Breadcrumbs, { BreadcrumbPath } from '../../../components/Breadcrumbs'
import PageContainer from '../../../components/layout/PageContainer'
import CustomerTable from '../../../components/tables/CustomerTable'
import { externalRequest } from '../../../util/api/external-api'
import getToken from '../../../util/auth/getToken'
import handleError from '../../../util/handleError'
import Button from '@mui/material/Button'
import CreateCustomerDialog from './CreateCustomerDialog'

const PlatformDashboard = () => {
  useTitle('API Dashboard')

  const { id } = useParams()

  const [customers, setCustomers] = useState<RCustomer[]>([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(true)
  const [createCustomerOpen, setCreateCustomerOpen] = useState(false)

  const navigate = useNavigate()

  const populateCustomers = useCallback(async () => {
    try {
      if (!id) return

      setLoading(true)

      const token = await getToken()
      if (!token) return

      const { results } = await externalRequest.user.customers.list({
        token,
        accessId: id,
        offset: page * limit,
        limit,
        search: search || undefined,
      })

      setCustomers(results)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [id, page, limit, search])

  useEffect(() => {
    const timeout = setTimeout(populateCustomers, 10)
    return () => clearTimeout(timeout)
  }, [populateCustomers])

  const handleOnCustomerCreated = useCallback(
    (customer: RCustomer) => {
      setCustomers([customer, ...customers])
    },
    [customers]
  )

  const paths: BreadcrumbPath[] = [
    {
      name: 'API',
      path: '/platform',
    },
    {
      name: 'Console',
      path: `/platform/${id}`,
    },
    {
      name: 'Dashboard',
    },
  ]

  return (
    <PageContainer>
      <Breadcrumbs paths={paths} />
      <Grid container spacing={1}>
        <Grid item xs>
          <Typography variant="h4" gutterBottom>
            Customer IDs
          </Typography>
          <Typography variant="body1" gutterBottom>
            Click on a customer ID to view their templates.
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={() => setCreateCustomerOpen(true)}>Add Customer</Button>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ p: 2 }}>
            <CustomerTable
              rows={customers}
              total={total}
              page={page}
              rowsPerPage={limit}
              onSubmitSearch={setSearch}
              onChangePage={setPage}
              onChangeRowsPerPage={setLimit}
              rowsPerPageOptions={[10, 25, 50]}
              loaded={!loading}
              onClickRow={(row) => {
                navigate(`/platform/${id}/templates/${row.identifier}`)
              }}
            />
          </Paper>
        </Grid>
      </Grid>
      {id && (
        <CreateCustomerDialog
          open={createCustomerOpen}
          onClose={() => setCreateCustomerOpen(false)}
          accessId={id}
          onCreated={handleOnCustomerCreated}
        />
      )}
    </PageContainer>
  )
}

export default PlatformDashboard
