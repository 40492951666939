import Grid from '@mui/material/Grid'
import Cookies from 'js-cookie'
import { useCallback, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import PageContainer from '../components/layout/PageContainer'
import { authRequest } from '../util/api/auth-api'
import useUser from '../util/auth/useUser'
import handleError from '../util/handleError'

const AutoLogin = () => {
  const [, setUser] = useUser()

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const handlePopulateUser = useCallback(async () => {
    try {
      const token = Cookies.get('token')
      if (!token) return

      const { user } = await authRequest.user.account.get({ token })

      setUser(user)

      const decodedToken = JSON.parse(atob(token.split('.')[1]))

      const expiresAt = new Date(decodedToken.exp * 1000)

      Cookies.set('expiresAt', expiresAt.toISOString())

      const cb = searchParams.get('cb')

      if (cb) {
        navigate(cb)
      } else {
        navigate('/')
      }
    } catch (err) {
      handleError(err)
    }
  }, [setUser, searchParams, navigate])

  useEffect(() => {
    const timeout = setTimeout(() => {
      handlePopulateUser()
    }, 10)

    return () => clearTimeout(timeout)
  }, [handlePopulateUser])

  return (
    <PageContainer>
      <Grid container alignItems="center">
        <Grid item>Logging in...</Grid>
      </Grid>
    </PageContainer>
  )
}

export default AutoLogin
