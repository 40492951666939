import { RTemplate } from '@counsel-project/counsel-external-api'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { useCallback, useState } from 'react'
import ClosableDialog from '../../../../components/ClosableDialog'
import LabeledTextField from '../../../../components/layout/LabeledTextField'
import { externalRequest } from '../../../../util/api/external-api'
import getToken from '../../../../util/auth/getToken'
import handleError from '../../../../util/handleError'

type CreateTemplateDialogProps = {
  customerIdentifier: string
  accessId: string
  open: boolean
  onClose: () => void
  onCreate: (template: RTemplate) => void
}

const CreateTemplateDialog = ({
  accessId,
  customerIdentifier,
  open,
  onClose,
  onCreate,
}: CreateTemplateDialogProps) => {
  const [stagedId, setStagedId] = useState('')
  const [stagedName, setStagedName] = useState('')
  const [stagedType, setStagedType] = useState('Progress Note')
  const [loading, setLoading] = useState(false)

  const handleCreate = useCallback(async () => {
    try {
      setLoading(true)

      const token = await getToken()
      if (!token) return

      const { result } = await externalRequest.user.templates.add({
        name: stagedName,
        type: stagedType,
        token,
        accessId,
        identifier: stagedId,
        customerIdentifier,
      })

      onCreate(result)
      onClose()
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [accessId, customerIdentifier, stagedId, stagedName, stagedType, onCreate, onClose])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Create Template">
      <DialogContent>
        <LabeledTextField
          label="Template ID"
          description="Leave this blank to auto-generate"
          value={stagedId}
          onChange={(e) => setStagedId(e.target.value)}
          placeholder="Auto-generate"
          sx={{ mb: 2 }}
        />
        <LabeledTextField
          label="Template Name"
          description="What is the name of this template?"
          value={stagedName}
          onChange={(e) => setStagedName(e.target.value)}
          placeholder="Template name here..."
          sx={{ mb: 2 }}
        />
        <LabeledTextField
          label="Template Type"
          description="What kind of template is this?"
          value={stagedType}
          onChange={(e) => setStagedType(e.target.value)}
          placeholder='e.g. "Progress Note"'
          sx={{ mb: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleCreate} color="primary" disabled={loading || !stagedName}>
          Create
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default CreateTemplateDialog
