import { isApiError } from '@counsel-project/utils/api'
import Cookies from 'js-cookie'
import { toast } from 'react-hot-toast'
import { authRequest } from '../api/auth-api'
import log from '../logging'
import { setStore } from '../store/auth'
import wait from '../wait'
import logout from './logout'

const REFRESH_TOKEN_THRESHOLD = 1000 * 60 * 30 // 30 minutes

const getToken = async (allowMissing?: boolean) => {
  const token = Cookies.get('token')
  if (!token) {
    if (allowMissing) return null
    toast.error('You have been logged out due to inactivity')
    logout(true)
    return null
  }

  const expiresAt = Cookies.get('expiresAt')
  if (!expiresAt) {
    if (allowMissing) return null
    toast.error('You have been logged out due to inactivity')
    logout(true)
    return null
  }

  const expiresAtDate = new Date(expiresAt)
  const now = new Date()

  // If its x minutes before the token expires, refresh it
  if (expiresAtDate.getTime() - now.getTime() < REFRESH_TOKEN_THRESHOLD) {
    console.log('Refreshing token...')
    // Check if it has already expired
    if (expiresAtDate.getTime() < new Date().getTime()) {
      toast.error('You have been logged out due to inactivity')
      logout(true)
      return null
    }

    const refreshToken = Cookies.get('refreshToken')

    if (!refreshToken) {
      if (allowMissing) return null
      toast.error('You have been logged out due to inactivity')
      logout(true)
      return null
    }

    try {
      const data = await authRequest.user.login.withToken({
        token,
        refreshToken,
      })

      Cookies.set('token', data.token)
      Cookies.set('expiresAt', data.expiresAt)
      Cookies.set('refreshToken', data.refreshToken)
      setStore({
        user: data.user,
      })

      return data.token
    } catch (err) {
      log.info('Auth login with token failed, retrying...')
      try {
        await wait(300)
        const data = await authRequest.user.login.withToken({
          token,
          refreshToken,
        })

        Cookies.set('token', data.token)
        Cookies.set('expiresAt', data.expiresAt)
        Cookies.set('refreshToken', data.refreshToken)
        setStore({
          user: data.user,
        })

        return data.token
      } catch (err) {
        log.error(err)
        if (allowMissing) return null
        if (isApiError(err) && (err.status === 401 || err.status === 402 || err.status === 403)) {
          toast.error('You have been logged out due to inactivity')
          logout(true)
          return null
        } else {
          return token
        }
      }
    }
  }

  return token
}

export default getToken
