import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import { MuiColorInput } from 'mui-color-input'
import Typography from '@mui/material/Typography'
import { TemplateStyles } from '../../_helpers'

type ColorSectionProps = {
  sectionId: keyof TemplateStyles
  label: string
  value: string
  onChange: (sectionId: keyof TemplateStyles, value: string) => void
}

const ColorSection = ({ sectionId, label, value, onChange }: ColorSectionProps) => {
  const [stagedValue, setStagedValue] = useState(value)

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(sectionId, stagedValue)
    }, 500)
    return () => clearTimeout(timeout)
  }, [stagedValue, sectionId, onChange])

  useEffect(() => {
    setStagedValue((prev) => (prev !== value ? value : prev))
  }, [value])

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Typography variant="body1">{label}</Typography>
      </Grid>
      <Grid item xs={12}>
        <MuiColorInput
          value={stagedValue}
          onChange={(color) => {
            setStagedValue(color)
          }}
          format="hex"
          fullWidth
        />
      </Grid>
    </Grid>
  )
}

export default ColorSection
