import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded'
import DownloadIcon from '@mui/icons-material/DownloadRounded'
import FeedRoundedIcon from '@mui/icons-material/FeedRounded'
import PaymentIcon from '@mui/icons-material/PaymentRounded'
import PersonIcon from '@mui/icons-material/PersonRounded'
import AdminIcon from '@mui/icons-material/SettingsRounded'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { alpha, useTheme } from '@mui/material/styles'
import Cookies from 'js-cookie'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useUser from '../util/auth/useUser'

export type AccountButtonProps = {
  disabled?: boolean
}

const AccountButton = ({ disabled }: AccountButtonProps) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [user] = useUser()

  const username = user?.firstName ? `${user?.firstName} ${user?.lastName}`.trim() : user?.email
  const alt = user?.firstName?.[0] ?? user?.email?.[0] ?? 'A'
  const picture = user?.picture
  const email = user?.email
  const admin = user?.admin

  const [accountOpen, setAccountOpen] = useState(false)
  const accountButtonEl = useRef<HTMLAnchorElement>(null)

  const handleClickAccountButton = (e: React.MouseEvent) => {
    e.preventDefault()
    if (disabled) return
    setAccountOpen(true)
  }

  const handleCloseAccountMenu = () => {
    setAccountOpen(false)
  }

  const createAccountNavHandler = (path: string) => (e: React.MouseEvent) => {
    e.preventDefault()
    handleCloseAccountMenu()
    navigate(path)
  }

  const handleOpenConsentDocument = (e: React.MouseEvent) => {
    e.preventDefault()
    handleCloseAccountMenu()
    window.open('/consent-document.pdf', '_blank')
  }

  const handleOpenBAA = (e: React.MouseEvent) => {
    e.preventDefault()
    handleCloseAccountMenu()
    Cookies.remove('dl-baa')
    navigate('/')
  }

  return (
    <>
      <Box
        onClick={handleClickAccountButton}
        ref={accountButtonEl}
        id="account-button"
        component="a"
        href="#open-profile-menu"
        sx={{
          userSelect: 'none',
          p: 1,
          backgroundColor: alpha(theme.palette.primary.main, 0.075),
          borderRadius: 200,
          mr: 2,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          transition: 'all 0.2s ease-in-out',
          justifyContent: 'center',
          textDecoration: 'none',
          ':hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.15),
          },
        }}
      >
        <Avatar
          alt={alt}
          src={picture ?? undefined}
          sx={(theme) => ({ bgcolor: theme.palette.primary.main, width: 26, height: 26 })}
        />
      </Box>
      <Menu
        aria-expanded={accountOpen ? 'true' : undefined}
        id="account-menu"
        anchorEl={accountButtonEl.current}
        open={accountOpen}
        onClose={handleCloseAccountMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiMenu-paper': {
            backgroundColor: theme.palette.background.default,
            boxShadow: theme.shadows[24],
          },
        }}
      >
        <Typography sx={{ px: 2, pt: 1 }} variant="body2" fontWeight={500}>
          {username}
        </Typography>
        {username !== email && (
          <Typography sx={{ px: 2 }} variant="subtitle1" color="text.secondary" fontWeight={500}>
            {email}
          </Typography>
        )}
        <MenuItem
          id="account-nav-profile"
          color="primary"
          LinkComponent="a"
          href="/profile"
          onClick={createAccountNavHandler('/profile')}
        >
          <PersonIcon sx={{ mr: 1 }} color="primary" />
          <Typography color="primary">My Profile</Typography>
        </MenuItem>

        {admin && (
          <MenuItem
            id="account-nav-admin-access"
            color="primary"
            LinkComponent="a"
            href="/admin/access"
            onClick={createAccountNavHandler('/admin/access')}
          >
            <AdminIcon sx={{ mr: 1 }} color="primary" />
            <Typography color="primary">Admin API Access</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export default AccountButton
