import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

type TabProps = {
  active: boolean
}

const Tab = styled(Box, { shouldForwardProp: (prop) => prop !== 'active' })<TabProps>(
  ({ theme, active }) => ({
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottom: active ? `2px solid ${theme.palette.primary.main}` : 'none',
    backgroundColor: active ? theme.palette.background.paper : theme.palette.background.default,
    padding: theme.spacing(1),
    cursor: 'pointer',
    textAlign: 'center',
    color: active ? theme.palette.text.primary : theme.palette.text.secondary,
    width: '50%',
  })
)

const TabContainer = styled(Box)(({ theme }) => ({
  borderTopRightRadius: theme.shape.borderRadius,
  borderTopLeftRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  width: '100%',
}))

export type TabItem = {
  id: string
  label: string
}

export type TabsPopperProps = {
  activeTab: string
  tabs: TabItem[]
  onClick: (id: string) => void
}

const TabsPopper = ({ activeTab, tabs, onClick }: TabsPopperProps) => {
  return (
    <TabContainer>
      {tabs.map((tab) => (
        <Tab key={tab.id} active={tab.id === activeTab} onClick={() => onClick(tab.id)}>
          <Typography variant="body1">{tab.label}</Typography>
        </Tab>
      ))}
    </TabContainer>
  )
}

export default TabsPopper
