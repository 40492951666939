import { request } from '@counsel-project/utils/api'
import { url } from './url'

const getEnvironment = async () => {
  const response = await request({
    url: `${url}/env`,
    method: 'GET',
  })
  return response.body.env as string
}

export default getEnvironment
