import { RAccess } from '@counsel-project/counsel-keys-api'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import ConfirmDialog from '../../components/ConfirmDialog'
import PageContainer from '../../components/layout/PageContainer'
import AccessTable from '../../components/tables/AccessTable'
import { keysRequest } from '../../util/api/keys-api'
import getToken from '../../util/auth/getToken'
import useRequireAdmin from '../../util/auth/useRequireAdmin'
import CreateAccessDialog from './CreateAccessDialog'

const AdminAccessPage = () => {
  useTitle('Clinical Notes AI - API Access')
  useRequireAdmin('/')

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const [loading, setLoading] = useState(true)
  const [accesses, setAccesses] = useState<RAccess[]>([])
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)

  const [createOpen, setCreateOpen] = useState(false)
  const [activeAccess, setActiveAccess] = useState<RAccess | null>(null)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)

  const handleToggleConfirmDelete = () => {
    setConfirmDeleteOpen((prev) => !prev)
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setLimit(newRowsPerPage)
  }

  const populateAccesses = useCallback(async () => {
    try {
      const token = await getToken()
      if (!token) return

      setLoading(true)

      const data = await keysRequest.admin.access.list({
        token,
        limit,
        offset: page * limit,
        search: searchParams.get('search') || '',
      })

      setAccesses(data.results)
      setTotal(data.total)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [limit, page, searchParams])

  useEffect(() => {
    populateAccesses()
  }, [populateAccesses])

  const handleClickRow = (row: RAccess) => {
    navigate(`/platform/${row._id}`)
  }

  const handleDeleteAccess = useCallback(async () => {
    try {
      if (!activeAccess) return

      const token = await getToken()
      if (!token) return
      setLoading(true)

      await keysRequest.admin.access.remove({
        token,
        accessId: activeAccess._id,
      })

      populateAccesses()
      setCreateOpen(false)
      setConfirmDeleteOpen(false)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [activeAccess, populateAccesses])

  const handleSubmitSearch = (search: string) => {
    setSearchParams((prev) => {
      prev.set('search', search)
      return prev
    })
    setPage(0)
  }

  const handleOnCreated = useCallback(
    (newAccess: RAccess) => {
      setActiveAccess(newAccess)
      populateAccesses()
    },
    [populateAccesses]
  )

  const handleClickDelete = (row: RAccess) => {
    setActiveAccess(row)
    setConfirmDeleteOpen(true)
  }

  return (
    <PageContainer>
      <Paper sx={{ p: 2 }} elevation={0}>
        <Button onClick={() => setCreateOpen(true)} sx={{ mb: 2 }}>
          Add API Organization
        </Button>
        <AccessTable
          loaded={!loading}
          rows={accesses}
          total={total}
          page={page}
          onSubmitSearch={handleSubmitSearch}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onClickRow={handleClickRow}
          onClickDelete={handleClickDelete}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </Paper>
      <CreateAccessDialog
        open={createOpen}
        onClose={() => setCreateOpen(false)}
        onCreated={handleOnCreated}
      />
      <ConfirmDialog
        open={confirmDeleteOpen}
        onClose={handleToggleConfirmDelete}
        onConfirm={handleDeleteAccess}
        titleText={`Delete ${activeAccess?.organization}`}
        text="Are you sure you want to delete this API Access?"
      />
    </PageContainer>
  )
}

export default AdminAccessPage
