import { useMemo, useRef, useState } from 'react'
import { CNAIIcon } from './icons'
import RecorderButton from './RecorderButton'
import Popper from '@mui/material/Popper'
import RecorderPanel from './RecorderPanel'
import RecorderPopper from './RecorderPopper'
import { DEFAULT_RECORDER_OPTIONS, RecorderShowOptions } from './_helpers'

type RecorderOverlayProps = {
  properties?: RecorderShowOptions[]
  absolute?: boolean
  hidden?: boolean
  spacing?: number
}

const RecorderOverlay = ({
  properties = DEFAULT_RECORDER_OPTIONS,
  absolute,
  hidden,
  spacing,
}: RecorderOverlayProps) => {
  const anchorEl = useRef<HTMLButtonElement | null>(null)

  const [open, setOpen] = useState(false)

  const position = useMemo(() => {
    if (properties.includes('top') && properties.includes('left')) {
      return 'top-left'
    } else if (properties.includes('top') && !properties.includes('left')) {
      return 'top-right'
    } else if (!properties.includes('top') && properties.includes('left')) {
      return 'bottom-left'
    } else {
      return 'bottom-right'
    }
  }, [properties])

  const popperPlacement = useMemo(() => {
    if (position === 'top-left') {
      return 'bottom-start'
    } else if (position === 'top-right') {
      return 'bottom-end'
    } else if (position === 'bottom-left') {
      return 'top-start'
    } else {
      return 'top-end'
    }
  }, [position])

  return (
    <>
      <RecorderButton
        ref={anchorEl}
        absolute={absolute}
        position={position}
        hidden={hidden || (properties.includes('panel') && open)}
        spacing={spacing}
        onClick={() => setOpen((prev) => !prev)}
      >
        <CNAIIcon />
      </RecorderButton>
      {properties.includes('panel') ? (
        <RecorderPanel
          properties={properties}
          hidden={!open}
          onClose={() => setOpen((prev) => !prev)}
          absolute={absolute}
        />
      ) : (
        <Popper
          id="cnai-widget-menu"
          open={open}
          anchorEl={anchorEl.current}
          popperOptions={{
            placement: popperPlacement,
          }}
          disablePortal
        >
          <RecorderPopper />
        </Popper>
      )}
    </>
  )
}

export default RecorderOverlay
