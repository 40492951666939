import { InputField } from '@counsel-project/counsel-external-api'
import DownIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import UpIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import SettingsIcon from '@mui/icons-material/SettingsRounded'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import FormatSelector from './FormatSelector'
import {
  ALL_TEMPLATE_OPTIONS,
  areFieldsEqual,
  getFieldTypeLabel,
  TemplateShowOptions,
} from './_helpers'
import TemperatureSlider from './TemperatureSlider'
import TemplateFieldSettingsDialog from './TemplateFieldSettingsDialog'
import Paper from '@mui/material/Paper'

type TemplateFieldProps = {
  properties?: TemplateShowOptions[]
  allFields: InputField[]
  total: number
  index: number
  field: InputField
  onChange: (value: InputField) => void
  onChangeOrder: (from: number, to: number) => void
  onRemoveField: (field: InputField) => void
}

const TemplateField = ({
  properties = ALL_TEMPLATE_OPTIONS,
  allFields,
  total,
  index,
  field,
  onChange,
  onChangeOrder,
  onRemoveField,
}: TemplateFieldProps) => {
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [highlighted, setHighlighted] = useState(false)

  const [stagedField, setStagedField] = useState<InputField>(field)
  const { name, type, instructions, temperature } = stagedField
  const typeLabel = getFieldTypeLabel(type)

  const showSettings =
    properties.includes('options') ||
    properties.includes('id') ||
    properties.includes('remove') ||
    properties.includes('name')

  useEffect(() => {
    setStagedField((prev) => {
      if (areFieldsEqual(prev, field)) return prev
      return field
    })
  }, [field])

  useEffect(() => {
    if (!highlighted) return

    const timeout = setTimeout(() => {
      setHighlighted(false)
    }, 2000)

    return () => clearTimeout(timeout)
  }, [highlighted, index])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(stagedField)
    }, 100)
    return () => clearTimeout(timeout)
  }, [stagedField, onChange])

  const options = 'options' in stagedField ? stagedField.options : []

  const handleChangeInstructions = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setStagedField((prev) => ({
      ...prev,
      instructions: e.target.value,
    }))
  }, [])

  const handleChangeTemperature = useCallback((value: number) => {
    setStagedField((prev) => ({
      ...prev,
      temperature: value,
    }))
  }, [])

  const handleChangeFormat = useCallback((value: 'bullet' | 'numbered' | 'paragraph' | 'none') => {
    setStagedField((prev) => ({
      ...prev,
      format: value === 'none' ? undefined : value,
    }))
  }, [])

  const handleChangeOmitNames = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setStagedField((prev) => ({
      ...prev,
      names: !e.target.checked,
    }))
  }, [])

  const handleRemoveField = useCallback(
    (field: InputField) => {
      onRemoveField(field)
    },
    [onRemoveField]
  )

  return (
    <Paper
      sx={{
        overflow: 'hidden',
        width: '100%',
        height: 310,
      }}
      variant="outlined"
    >
      <TemplateFieldSettingsDialog
        allFields={allFields}
        field={stagedField}
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        onUpdateField={setStagedField}
        onRemoveField={handleRemoveField}
        properties={properties}
      />
      <Box
        sx={{
          p: 2,
        }}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Typography variant="body1" fontWeight={500}>
              {name}
            </Typography>
          </Grid>
          <Grid item xs>
            <Chip label={typeLabel} size="small" />
          </Grid>
          {showSettings && (
            <Grid item>
              <IconButton onClick={() => setSettingsOpen(true)} size="small">
                <SettingsIcon />
              </IconButton>
            </Grid>
          )}
          {properties.includes('reorder') && (
            <Grid item>
              <IconButton
                size="small"
                disabled={index === total - 1}
                onClick={() => {
                  onChangeOrder?.(index, index + 1)
                  setHighlighted(true)
                }}
              >
                <DownIcon />
              </IconButton>
            </Grid>
          )}
          {properties.includes('reorder') && (
            <Grid item>
              <IconButton
                size="small"
                disabled={index === 0}
                onClick={() => {
                  onChangeOrder?.(index, index - 1)
                  setHighlighted(true)
                }}
              >
                <UpIcon />
              </IconButton>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography
              variant="body1"
              fontSize={14}
              sx={{
                mb: 1,
              }}
            >
              AI Instructions
            </Typography>
            <TextField
              value={instructions || ''}
              fullWidth
              type="text"
              placeholder="If needed, give AI instructions on how this field should be filled in"
              multiline
              rows={3}
              onChange={handleChangeInstructions}
            />
          </Grid>
          {stagedField.type === 'string' && properties.includes('format') && (
            <Grid item xs>
              <FormatSelector value={stagedField.format || 'none'} onChange={handleChangeFormat} />
            </Grid>
          )}
          {stagedField.type === 'string' && properties.includes('omit-names') && (
            <Grid item>
              <FormControlLabel
                control={<Checkbox checked={!stagedField.names} onChange={handleChangeOmitNames} />}
                label="Omit names"
              />
            </Grid>
          )}
          {options.length !== 0 && (
            <Grid item xs={12} container spacing={1}>
              <Grid item>
                <Typography
                  variant="body1"
                  fontSize={14}
                  sx={{
                    mt: 0.5,
                  }}
                >
                  AI Will select one of these values:
                </Typography>
              </Grid>
              {options.map((option) => (
                <Grid item key={option}>
                  <Chip label={option} size="small" />
                </Grid>
              ))}
            </Grid>
          )}
          {properties.includes('temperature') && (
            <Grid item xs={12}>
              <TemperatureSlider temperature={temperature} onChange={handleChangeTemperature} />
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  )
}

export default TemplateField
