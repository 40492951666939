import Button, { ButtonProps } from '@mui/material/Button'
import AppleLogo from '../logos/AppleLogo'

type AppleButtonProps = ButtonProps

const AppleButton = ({ ...props }: AppleButtonProps) => {
  return (
    <Button
      startIcon={<AppleLogo />}
      variant="contained"
      color="info"
      sx={{
        display: 'flex',
        justifyContent: 'start',
        fontWeight: 500,
        backgroundColor: '#232324',
        color: 'white',
        '&:hover': {
          backgroundColor: '#232324',
        },
        p: 2,
        px: 3,
      }}
      {...props}
    >
      {props.children || 'Sign in with Apple'}
    </Button>
  )
}

export default AppleButton
