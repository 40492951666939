import { FieldType, InputField, OutputField } from '@counsel-project/counsel-external-api'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { externalRequest } from '../../util/api/external-api'
import getToken from '../../util/auth/getToken'
import handleError from '../../util/handleError'
import { areFieldsEqual, randomUUID } from './_helpers'
import { ExampleSession, hannahExample } from './examples'
import ExampleSelector from './ExampleSelector'
import TemplateFieldExample from './TemplateFieldExample'

type Example = {
  name: string
  type: FieldType
  id: string
  value: string[]
  progressValue: number // 0-100
  progressing: boolean
  loadingId: string | null
}

const makeExamplesFromInitialValues = (fields: InputField[], outputs: OutputField[]): Example[] => {
  return fields.map((field) => {
    const output = outputs.find((output) => output.id === field.id)
    if (!output)
      return {
        id: field.id,
        value: [],
        progressValue: 0,
        loadingId: null,
        progressing: false,
        type: field.type,
        name: field.name,
      }

    return {
      id: field.id,
      value: output.value,
      progressValue: 0,
      loadingId: null,
      progressing: false,
      type: output.type,
      name: output.name,
    }
  })
}

type TemplateFieldExamplesProps = {
  accessId: string
  name: string
  instructions: string
  fields: InputField[]
  initialExamples: OutputField[]
  disableExamples?: boolean
  onExamplesUpdated: (examples: OutputField[]) => void
}

const TemplateFieldExamples = ({
  accessId,
  name,
  instructions,
  fields,
  initialExamples,
  disableExamples,
  onExamplesUpdated,
}: TemplateFieldExamplesProps) => {
  const initialFields = useRef<InputField[]>(fields)

  const [updatedFields, setUpdatedFields] = useState<InputField[]>([])

  const examplesFromInitialFields = makeExamplesFromInitialValues(fields, initialExamples)
  const [examples, setExamples] = useState<Example[]>(examplesFromInitialFields)
  const [exampleFields, setExampleFields] = useState<OutputField[]>([])
  const firstLoadingExampleId = useMemo(
    () => examples.find((example) => example.loadingId)?.id,
    [examples]
  )
  const [examplesContext, setExamplesContext] = useState<Example[]>([])
  const [templateFields, setTemplateFields] = useState<InputField[]>([])

  const exampleSessionInitialValue = useRef<ExampleSession | null>(hannahExample)
  const [exampleSession, setExampleSession] = useState<ExampleSession | null>(hannahExample)

  useEffect(() => {
    if (initialFields.current.length === fields.length) return
    if (fields.length === 0) return

    initialFields.current = fields

    const timeout = setTimeout(() => {
      setExamples(makeExamplesFromInitialValues(fields, initialExamples))
    }, 10)

    return () => clearTimeout(timeout)
  }, [fields, initialExamples])

  useEffect(() => {
    if (exampleFields.length === 0) return
    if (exampleFields.length !== initialFields.current.length) return

    const timeout = setTimeout(() => {
      onExamplesUpdated(exampleFields)
    }, 10)

    return () => clearTimeout(timeout)
  }, [exampleFields, onExamplesUpdated])

  const fieldOrder = useMemo(() => {
    return fields.map((field) => field.id).join(',')
  }, [fields])

  useEffect(() => {
    const idOrder = fieldOrder.split(',')

    setExamples((prev) => {
      const sorted = prev.sort((a, b) => {
        return idOrder.indexOf(a.id) - idOrder.indexOf(b.id)
      })

      return [...sorted]
    })
  }, [fieldOrder])

  useEffect(() => {
    const timeout = setTimeout(() => {
      // Set the examples context to every example that is before the firstLoadingExampleId
      let found = false
      setExamplesContext(
        examples.filter((example) => {
          if (found) return false
          if (!example.value[0]) return false
          if (example.id === firstLoadingExampleId) {
            found = true
            return false
          }
          return true
        })
      )

      setTemplateFields(fields)
    }, 10)

    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoadingExampleId])

  useEffect(() => {
    if (
      initialFields.current.length === fields.length &&
      fields.every((f) => {
        const initialField = initialFields.current.find((c) => c.id === f.id)
        if (!initialField) return false

        return areFieldsEqual(f, initialField)
      })
    ) {
      return
    }

    const timeout = setTimeout(() => {
      if (initialFields.current.length === 0) {
        setUpdatedFields(fields)
      } else {
        setUpdatedFields(
          fields.filter((field) => {
            const initialField = initialFields.current.find((c) => c.id === field.id)
            if (!initialField) return true

            return !areFieldsEqual(field, initialField)
          })
        )
      }

      initialFields.current = fields
    }, 10)

    return () => clearTimeout(timeout)
  }, [fields])

  useEffect(() => {
    if (examples.length === 0) return
    if (disableExamples) return

    const progressingExamples = examples.filter((example) => example.progressing)
    if (progressingExamples.length === 0) return

    const progressingNonCompletedExamples = progressingExamples.filter(
      (example) => example.progressValue < 100 && !example.loadingId
    )
    if (progressingNonCompletedExamples.length === 0) return

    const timeout = setTimeout(() => {
      setExamples((prev) =>
        prev.map((example) => {
          if (!example.progressing) return example
          if (example.progressValue >= 100) return example
          if (example.loadingId) return example

          return {
            ...example,
            progressValue: example.progressValue + 10,
          }
        })
      )
    }, 400)

    return () => clearTimeout(timeout)
  }, [examples, disableExamples])

  useEffect(() => {
    if (updatedFields.length === 0) return

    setExamples((prev) => {
      return prev.map((example) => {
        if (!updatedFields.find((field) => field.id === example.id))
          return {
            ...example,
            progressing: false,
            progressValue: 0,
          }

        return {
          ...example,
          progressing: true,
          progressValue: 0,
        }
      })
    })
  }, [updatedFields])

  const handlePopulateExample = useCallback(async () => {
    try {
      if (!exampleSession) return

      const customerToken = new URLSearchParams(window.location.search).get('t')

      let result: OutputField

      if (customerToken) {
        const res = await externalRequest.customer.template.generateExample({
          customerToken,
          context: exampleSession.context,
          transcript: exampleSession.transcript,
          outputs: examplesContext.map((example) => ({
            id: example.id,
            value: example.value,
            name: example.name,
            type: example.type,
          })),
          template: {
            name,
            instructions,
            fields: templateFields,
          },
        })

        result = res.result
      } else {
        const token = await getToken()
        if (!token) return

        const res = await externalRequest.user.templates.generateExample({
          token,
          accessId,
          context: exampleSession.context,
          transcript: exampleSession.transcript,
          outputs: examplesContext.map((example) => ({
            id: example.id,
            value: example.value,
            name: example.name,
            type: example.type,
          })),
          template: {
            name,
            instructions,
            fields: templateFields,
          },
        })

        result = res.result
      }

      setExampleFields((prev) => {
        return [...prev.filter((field) => result.id !== field.id), result]
      })

      setExamples((prev) =>
        prev.map((example) => {
          if (example.id !== result.id) return example

          return {
            ...example,
            value: result.value,
            type: result.type,
            name: result.name,
            progressing: false,
            progressValue: 0,
            loadingId: null,
          }
        })
      )
    } catch (err) {
      handleError(err)
      setExamples((prev) =>
        prev.map((example) => {
          return {
            ...example,
            progressing: false,
            progressValue: 0,
            loadingId: null,
          }
        })
      )
    }
  }, [accessId, examplesContext, templateFields, name, instructions, exampleSession])

  useEffect(() => {
    const completedProgressingExamples = examples.filter(
      (example) => example.progressing && example.progressValue >= 100
    )
    if (!completedProgressingExamples.length) return

    setExamples((prev) => {
      let itemFound = false
      return prev.map((example) => {
        if (itemFound) return example
        if (example.loadingId) return example
        if (example.progressValue >= 100) {
          itemFound = true
          return {
            ...example,
            progressing: false,
            loadingId: randomUUID(),
            progressValue: 0,
          }
        }
        if (!example.value[0]) {
          return {
            ...example,
            progressing: false,
            loadingId: randomUUID(),
            progressValue: 0,
          }
        }
        return example
      })
    })
  }, [examples])

  useEffect(() => {
    if (!firstLoadingExampleId) return

    const timeout = setTimeout(() => {
      handlePopulateExample()
    }, 100)

    return () => clearTimeout(timeout)
  }, [firstLoadingExampleId, handlePopulateExample])

  const isAnyExampleLoading = examples.some((example) => example.loadingId)

  const handleGenerateAllExamples = useCallback(() => {
    setExamples((prev) =>
      prev.map((example) => {
        if (example.loadingId) return example

        return {
          ...example,
          progressing: true,
          progressValue: 100,
        }
      })
    )
  }, [])

  const handleRefreshExample = useCallback(
    (fieldId: string) => {
      setExamples((prev) =>
        prev.map((example) => {
          if (example.id !== fieldId) return example

          return {
            ...example,
            progressing: true,
            progressValue: 100,
          }
        })
      )
    },
    [setExamples]
  )

  useEffect(() => {
    if (!exampleSession) return
    if (exampleSession === exampleSessionInitialValue.current) return

    setExamples((prev) =>
      prev.map((example) => {
        if (example.loadingId) return example

        return {
          ...example,
          progressing: true,
          progressValue: 0,
          loadingId: null,
        }
      })
    )

    exampleSessionInitialValue.current = exampleSession
  }, [exampleSession])

  return (
    <Grid container spacing={1}>
      <Grid item xs></Grid>
      <Grid item>
        <ExampleSelector value={exampleSession} onChange={setExampleSession} />
      </Grid>
      <Grid item>
        <Button
          disabled={isAnyExampleLoading || disableExamples}
          onClick={handleGenerateAllExamples}
        >
          Generate All
        </Button>
      </Grid>
      {examples.map((example) => (
        <Grid item xs={12} key={example.id}>
          <TemplateFieldExample
            name={example.name}
            fieldId={example.id}
            loadingId={example.loadingId}
            progressValue={example.progressValue}
            value={example.value}
            type={example.type}
            disabled={disableExamples}
            onClickRefresh={handleRefreshExample}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default TemplateFieldExamples
