import { useParams } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { useCallback, useEffect, useMemo, useState } from 'react'
import handleError from '../../../util/handleError'
import PageContainer from '../../../components/layout/PageContainer'
import { externalRequest } from '../../../util/api/external-api'
import {
  DEFAULT_TEMPLATE_OPTIONS,
  DEFAULT_TEMPLATE_STYLES,
  TemplateShowOptions,
  TemplateStyles,
} from '../../platform/_helpers'
import Box from '@mui/material/Box'
import { createStyledTheme } from '../../platform/[id]/configure'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import { OutputField, RTemplate } from '@counsel-project/counsel-external-api'
import TemplateEditor, { SavedOptions } from '../../platform/TemplateEditor'
import toast, { Toaster } from 'react-hot-toast'

const CustomerTemplatePage = () => {
  const { id } = useParams()

  const [loading, setLoading] = useState(true)
  const [styles, setStyles] = useState<TemplateStyles | null>(null)
  const [properties, setProperties] = useState<TemplateShowOptions[]>([])
  const [template, setTemplate] = useState<RTemplate | null>(null)
  const [saving, setSaving] = useState(false)

  const handleSave = useCallback(
    async ({ instructions, fields, examples }: SavedOptions) => {
      if (!id) return

      try {
        setSaving(true)

        const token = new URLSearchParams(window.location.search).get('t')
        if (!token) {
          throw new Error('Token not found')
        }

        await externalRequest.customer.template.submit({
          customerToken: token,
          templateIdentifier: id,
          instructions,
          fields,
          examples,
        })

        toast.success('Template saved', { id: 'template-saved' })
      } catch (err) {
        handleError(err)
      } finally {
        setSaving(false)
      }
    },
    [id]
  )

  const handleUpdateExamples = useCallback(
    async (outputs: OutputField[]) => {
      try {
        if (outputs.length === 0) return

        if (!id) return

        const token = new URLSearchParams(window.location.search).get('t')
        if (!token) return

        const { result } = await externalRequest.customer.template.submit({
          customerToken: token,
          templateIdentifier: id,
          examples: outputs,
        })

        setTemplate(result)
      } catch (err) {
        handleError(err)
      }
    },
    [id]
  )

  const theme = useMemo(() => {
    if (!styles) return createStyledTheme(DEFAULT_TEMPLATE_STYLES)
    return createStyledTheme(styles)
  }, [styles])

  const handleGetConfig = useCallback(async () => {
    try {
      // Take the token from the query t= parameter
      const token = new URLSearchParams(window.location.search).get('t')
      if (!token) {
        throw new Error('Token not found')
      }

      const { metadata } = await externalRequest.customer.config.get({ customerToken: token })

      const templateStyles =
        typeof metadata?.templateStyles === 'string'
          ? JSON.parse(metadata.templateStyles)
          : DEFAULT_TEMPLATE_STYLES

      const templateOptions =
        typeof metadata?.templateOptions === 'string'
          ? JSON.parse(metadata.templateOptions)
          : DEFAULT_TEMPLATE_OPTIONS

      setStyles(templateStyles)
      setProperties(templateOptions)
    } catch (err) {
      handleError(err)
    }
  }, [])

  useEffect(() => {
    handleGetConfig()
  }, [handleGetConfig])

  const handleGetTemplate = useCallback(async () => {
    try {
      if (!id) return

      const token = new URLSearchParams(window.location.search).get('t')
      if (!token) {
        throw new Error('Token not found')
      }

      const { result } = await externalRequest.customer.template.get({
        customerToken: token,
        templateIdentifier: id,
      })

      setTemplate(result)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [id])

  useEffect(() => {
    handleGetTemplate()
  }, [handleGetTemplate])

  if (!id) return <Box>Template not found</Box>

  if (!styles || !properties || !template) {
    return (
      <Box sx={{ backgroundColor: '#1c1c1c', minHeight: '100vh' }}>
        <Container sx={{ p: { xs: 1, sm: 2 } }}>
          <Grid container spacing={1} alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={8}>
              <Grid container spacing={1} alignItems="start">
                <Grid item xs>
                  <Skeleton variant="text" height={80} sx={{ backgroundColor: '#232323' }} />
                  <Skeleton variant="text" height={30} sx={{ backgroundColor: '#232323' }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Skeleton variant="text" height={240} sx={{ backgroundColor: '#232323' }} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Skeleton variant="text" height={44} sx={{ backgroundColor: '#232323' }} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Skeleton variant="text" height={44} sx={{ backgroundColor: '#232323' }} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: theme.palette.background.default, minHeight: '100vh' }}>
        <PageContainer>
          <Box sx={{ height: 40 }}></Box>
        </PageContainer>
        <TemplateEditor
          template={template}
          onSave={handleSave}
          disabled={saving}
          loading={saving}
          onExamplesUpdated={handleUpdateExamples}
          properties={properties}
        />
      </Box>
      <Toaster />
    </ThemeProvider>
  )
}

export default CustomerTemplatePage
