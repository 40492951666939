import Container, { ContainerProps } from '@mui/material/Container'

type PageContainerProps = ContainerProps

const PageContainer = ({ children, ...props }: PageContainerProps) => {
  return (
    <Container maxWidth="md" sx={{ p: { xs: 2, sm: 3 } }} {...props}>
      {children}
    </Container>
  )
}

export default PageContainer
