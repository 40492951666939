let audioContext: AudioContext | null = null
let sourceNode: MediaStreamAudioSourceNode | null = null
let analyserNode: AnalyserNode | null = null
let recorder: ScriptProcessorNode | null = null
let audioStream: MediaStream | null = null
let isRecording = false
let device: MediaDeviceInfo | null = null
let audioDetectionInterval: NodeJS.Timeout | null = null
let devices: MediaDeviceInfo[] = []

export const SAMPLE_RATE = 44100 // 44.1 kHz, commonly used for audio recording
export const CHANNELS = 1 // Mono audio
export const BIT_DEPTH = 16 // 16-bit audio

type RecordingOptions = {
  deviceId?: string
  onDataAvailable?: (buffer: ArrayBuffer) => void
  onAudioDetected?: () => void
  onError?: (error: Error) => void
  browserAudio?: boolean
  sampleRate?: number
  channels?: number
  bitDepth?: number
}

type RecordingResult = {
  device: MediaDeviceInfo | null
  format: AudioFormat
  analyser: AnalyserNode | null
  devices: MediaDeviceInfo[]
}

type AudioFormat = {
  encoding: string
  sampleRate: number
  channels: number
  bitDepth: number
}

const onAudioProcessHook = (cb?: (buffer: ArrayBuffer) => void) => (e: AudioProcessingEvent) => {
  const inputBuffer = e.inputBuffer
  const audioData = inputBuffer.getChannelData(0)
  const dataArray = new Float32Array(audioData)

  // Convert Float32Array to Int16Array
  const pcmData = new Int16Array(dataArray.length)
  for (let i = 0; i < dataArray.length; i++) {
    const s = Math.max(-1, Math.min(1, dataArray[i]))
    pcmData[i] = s < 0 ? s * 0x8000 : s * 0x7fff
  }

  cb?.(pcmData.buffer)
}

export const getIsRecording = (): boolean => isRecording

export const startRecording = async ({
  deviceId,
  onDataAvailable,
  onAudioDetected,
  onError,
  browserAudio,
  sampleRate = SAMPLE_RATE,
  channels = CHANNELS,
  bitDepth = BIT_DEPTH,
}: RecordingOptions): Promise<RecordingResult> => {
  if (!('AudioContext' in window)) {
    throw new Error('AudioContext is not supported')
  }

  if (isRecording) {
    await stopRecording()
  }

  isRecording = true

  // Initialize the audio context
  // @ts-expect-error AudioContext is not in the window object
  audioContext = new (window.AudioContext || window.webkitAudioContext)({
    sampleRate,
  })

  // Get the microphone audio stream
  audioStream = await navigator.mediaDevices.getUserMedia({
    audio: deviceId ? { deviceId } : true,
  })

  // Create a source node from the microphone stream
  sourceNode = audioContext.createMediaStreamSource(audioStream)

  // Create the script processor node (recorder)
  recorder = audioContext.createScriptProcessor(4096, channels, channels)

  let inputNode: AudioNode = sourceNode

  if (browserAudio) {
    try {
      // Get the browser audio stream
      const speakerStream = await navigator.mediaDevices.getDisplayMedia({
        audio: true,
        video: { frameRate: 1, width: 320 }, // Minimal video constraints
      })

      // Create a source node from the browser audio stream
      const speakerSource = audioContext.createMediaStreamSource(speakerStream)

      // Create a media stream destination to merge audio streams
      const combinedStream = audioContext.createMediaStreamDestination()

      // Connect both microphone and browser audio sources to the combined stream
      sourceNode.connect(combinedStream)
      speakerSource.connect(combinedStream)

      // Create a new source node from the combined stream
      const combinedSourceNode = audioContext.createMediaStreamSource(combinedStream.stream)

      // Set the input node to the combined source node
      inputNode = combinedSourceNode
    } catch (err) {
      if (err instanceof DOMException && err.name === 'NotAllowedError') {
        onError?.(err)
      } else {
        throw err
      }
    }
  }

  // Create the analyser node and connect it to the input node
  analyserNode = audioContext.createAnalyser()
  inputNode.connect(analyserNode)

  // Connect the input node to the recorder
  inputNode.connect(recorder)

  // **Create a silent gain node and connect it**
  const silentGain = audioContext.createGain()
  silentGain.gain.value = 0
  recorder.connect(silentGain)
  silentGain.connect(audioContext.destination)

  // Handle audio processing
  recorder.onaudioprocess = onAudioProcessHook(onDataAvailable)

  // Audio detection logic
  const frequencyDataArray = new Uint8Array(analyserNode.frequencyBinCount)
  const detectAudio = () => {
    analyserNode!.getByteFrequencyData(frequencyDataArray)
    const average =
      frequencyDataArray.reduce((acc, value) => acc + value, 0) / frequencyDataArray.length
    if (average > 0) {
      onAudioDetected?.()
      if (audioDetectionInterval) {
        clearInterval(audioDetectionInterval)
      }
    }
  }

  audioDetectionInterval = setInterval(detectAudio, 1000)

  // Retrieve the active device and list of available devices
  const activeDeviceId = audioStream.getAudioTracks()[0].getSettings().deviceId
  devices = (await navigator.mediaDevices.enumerateDevices()).filter(
    (device) => device.kind === 'audioinput'
  )
  device = devices.find((device) => device.deviceId === activeDeviceId) || null

  const format: AudioFormat = {
    encoding: 'linear16',
    sampleRate,
    channels: channels,
    bitDepth,
  }

  return { device, format, analyser: analyserNode, devices }
}

export const stopRecording = async (): Promise<void> => {
  if (!isRecording) {
    return
  }

  isRecording = false

  if (audioDetectionInterval) {
    clearInterval(audioDetectionInterval)
  }

  if (recorder) {
    recorder.disconnect()
    recorder = null
  }

  if (sourceNode) {
    sourceNode.disconnect()
    sourceNode = null
  }

  if (analyserNode) {
    analyserNode.disconnect()
    analyserNode = null
  }

  if (audioContext) {
    await audioContext.close()
    audioContext = null
  }

  if (audioStream) {
    audioStream.getTracks().forEach((track) => track.stop())
    audioStream = null
  }

  if (device) {
    device = null
  }
}

export const setOnDataAvailable = (cb: (buffer: ArrayBuffer) => void) => {
  if (recorder) {
    recorder.onaudioprocess = onAudioProcessHook(cb)
  }
}

export const getDevices = async (): Promise<MediaDeviceInfo[]> => {
  if (!devices.length) {
    devices = (await navigator.mediaDevices.enumerateDevices()).filter(
      (device) => device.kind === 'audioinput'
    )
  }

  return devices
}
