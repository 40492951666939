import TextField, { TextFieldProps } from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

type LabeledTextFieldProps = {
  label: string
  description?: string
} & Omit<TextFieldProps, 'label'>

const LabeledTextField = ({ label, description, ...props }: LabeledTextFieldProps) => {
  return (
    <Box>
      <Typography variant="body1" fontWeight={500} gutterBottom={!description}>
        {label}
      </Typography>
      {description && (
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {description}
        </Typography>
      )}
      <TextField fullWidth aria-label={label} {...props} />
    </Box>
  )
}

export default LabeledTextField
