import RefreshIcon from '@mui/icons-material/RefreshRounded'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'

export const markdownToHTML = (markdown: string) => {
  return markdown
    .replace(/#([^#]+)#/g, '<h6>$1</h6>')
    .replace(/\*\*([^*]+)\*\*/g, '<strong>$1</strong>')
    .replace(/\n/g, '<br>')
}

type TemplateFieldExampleProps = {
  name: string
  fieldId: string
  value: string[]
  progressValue: number
  type: string
  loadingId: string | null
  disabled?: boolean
  onClickRefresh: (fieldId: string) => void
}

const TemplateFieldExample = ({
  name,
  fieldId,
  value,
  progressValue,
  type,
  loadingId,
  disabled,
  onClickRefresh,
}: TemplateFieldExampleProps) => {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        overflow: 'hidden',
        width: '100%',
        height: 310,
      })}
    >
      <LinearProgress
        variant="determinate"
        value={progressValue}
        color="info"
        sx={{
          borderRadius: 1,
          position: 'relative',
          width: '100%',
          opacity: 0.2,
        }}
      />
      <Box
        sx={(theme) => ({
          p: 2,
          overflow: 'auto',
          height: '100%',
          overflowY: 'auto',
        })}
      >
        <Grid container spacing={1}>
          <Grid item xs>
            <Typography variant="h6" color="text.primary">
              {name}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              color="primary"
              onClick={() => onClickRefresh(fieldId)}
              disabled={loadingId !== null || disabled}
            >
              <RefreshIcon />
            </IconButton>
          </Grid>
          {!value.length && !loadingId ? (
            <Grid item xs={12}>
              <Typography variant="body2" color="text.secondary">
                Nothing generated yet...
              </Typography>
            </Grid>
          ) : !loadingId && (type === 'multiple_choice' || type === 'single_choice') ? (
            value.map((val, index) => (
              <Grid item xs={12} key={index}>
                <Chip label={val} />
              </Grid>
            ))
          ) : !loadingId ? (
            value.map((val, index) => (
              <Grid item xs={12} key={index}>
                <Typography variant="body2" color="text.primary">
                  <div dangerouslySetInnerHTML={{ __html: markdownToHTML(val) }} />
                </Typography>
              </Grid>
            ))
          ) : (
            <></>
          )}
          {loadingId && (
            <Grid item xs={12}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  )
}

export default TemplateFieldExample
