import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Components } from 'react-markdown'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { CopyBlock, dracula } from 'react-code-blocks'
import { useNavigate } from 'react-router-dom'

// Define the type for the 'code' component props
interface CodeProps {
  inline: boolean
  className?: string
  children: React.ReactNode
}

type MarkdownViewProps = {
  markdown: string
}

const MarkdownView = ({ markdown }: MarkdownViewProps) => {
  const navigate = useNavigate()

  const components: Components = {
    h1: ({ children }) => (
      <Typography variant="h3" gutterBottom color="text.primary">
        {children}
      </Typography>
    ),
    h2: ({ children }) => (
      <Typography variant="h4" gutterBottom color="text.primary">
        {children}
      </Typography>
    ),
    h3: ({ children }) => (
      <Typography variant="h5" gutterBottom color="text.primary">
        {children}
      </Typography>
    ),
    h4: ({ children }) => (
      <Typography variant="h6" gutterBottom color="text.primary">
        {children}
      </Typography>
    ),
    p: ({ children }) => (
      <Typography variant="body1" sx={{ mb: 2 }} color="text.primary">
        {children}
      </Typography>
    ),
    a: ({ href, children }) => (
      <Link
        href={href}
        underline="hover"
        onClick={(e) => {
          e.preventDefault()
          navigate(href || '/docs/welcome')
        }}
        sx={{ color: '#b3a6f7' }}
      >
        {children}
      </Link>
    ),
    hr: () => <Divider sx={{ mb: 2, backgroundColor: '#2b2d33' }} />,
    ul: ({ children }) => (
      <Box component="ul" sx={{ pl: 2 }}>
        {children}
      </Box>
    ),
    ol: ({ children }) => (
      <Box component="ol" sx={{ pl: 2 }}>
        {children}
      </Box>
    ),
    li: ({ children }) => (
      <Typography component="li" variant="body1" color="text.secondary">
        {children}
      </Typography>
    ),
    blockquote: ({ children }) => (
      <Box
        component="blockquote"
        sx={{
          borderLeft: '5px solid #ccc',
          margin: '1.5em 10px',
          padding: '0.5em 10px',
          color: '#666',
        }}
      >
        {children}
      </Box>
    ),
    code: ({ className, children, node }) => {
      const match = /language-(\w+)/.exec(className || '')
      if (Object.keys(node?.properties || {}).length === 0) {
        return (
          <Typography
            component="code"
            variant="body2"
            sx={{
              backgroundColor: '#282a36',
              color: '#ffffff',
              padding: '0.2em 0.4em',
              borderRadius: '4px',
              fontFamily: 'SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace',
            }}
          >
            {children}
          </Typography>
        )
      } else {
        return (
          <CopyBlock
            text={String(children).replace(/\n$/, '')}
            language={match ? match[1] : 'text'}
            showLineNumbers
            theme={dracula}
            wrapLongLines
          />
        )
      }
    },
    table: ({ children }) => (
      <TableContainer component={Paper}>
        <Table>{children}</Table>
      </TableContainer>
    ),
    thead: ({ children }) => <TableHead>{children}</TableHead>,
    tbody: ({ children }) => <TableBody>{children}</TableBody>,
    tr: ({ children }) => <TableRow>{children}</TableRow>,
    th: ({ children }) => (
      <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>{children}</TableCell>
    ),
    td: ({ children }) => <TableCell>{children}</TableCell>,
    img: ({ src, alt }) => (
      <Box component="img" src={src} alt={alt} sx={{ maxWidth: '100%', height: 'auto' }} />
    ),
  }

  return <ReactMarkdown components={components}>{markdown}</ReactMarkdown>
}

export default MarkdownView
