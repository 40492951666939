import Cookies from 'js-cookie'
import { StateStorage } from 'zustand/middleware'

const cookiesPrefix = '_state_'

const cookieStorage: StateStorage = {
  getItem: (key: string): string | null => {
    if (typeof window === 'undefined') {
      return null
    }
    const value = Cookies.get(cookiesPrefix + key)
    if (value) {
      return value
    }
    return null
  },
  setItem: (key: string, value: string): void => {
    if (typeof window === 'undefined') {
      return
    }
    Cookies.set(cookiesPrefix + key, value)
  },
  removeItem: (key: string): void => {
    if (typeof window === 'undefined') {
      return
    }
    Cookies.remove(cookiesPrefix + key)
  },
}

export default cookieStorage
