import { RUser } from '@counsel-project/counsel-auth-api'
import { isApiError } from '@counsel-project/utils/api'
import Cookies from 'js-cookie'
import { authRequest } from '../api/auth-api'
import log from '../logging'
import { setStore } from '../store/auth'

type LoginOptions = {
  user?: RUser
  token: string
  expiresAt: string
  refreshToken: string
}

const login = ({ user, token, expiresAt, refreshToken }: LoginOptions) => {
  if (typeof window === 'undefined') return

  if (user) {
    setStore({
      user,
    })
  }

  if (token) {
    Cookies.set('token', token)
    authRequest.user.subscriptions.checkout
      .verify({ token })
      .then(() => {
        log.info('Verified subscription')
      })
      .catch((err) => {
        if (isApiError(err)) {
          console.log(err.msg)
        }
      })
  }

  if (expiresAt) {
    Cookies.set('expiresAt', expiresAt)
  }

  if (refreshToken) {
    Cookies.set('refreshToken', refreshToken)
  }
}

export default login
