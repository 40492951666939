import ContentPasteSearchRoundedIcon from '@mui/icons-material/ContentPasteSearchRounded'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export type NoDataDisplayProps = {
  message?: string
  children?: React.ReactNode
}

const NoDataDisplay = ({ message = '', children }: NoDataDisplayProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <ContentPasteSearchRoundedIcon sx={{ fontSize: 48, mb: 2 }} color="disabled" />
      {message.split('\n').map((line, i) => (
        <Typography key={i} variant="subtitle2" color="text.secondary" textAlign="center">
          {line}
        </Typography>
      ))}
      {children}
    </Box>
  )
}

export default NoDataDisplay
