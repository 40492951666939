import React, { useEffect, useRef, useState } from 'react'
import { useTheme } from '@mui/material/styles'

type AudioVisualizerProps = {
  analyser: AnalyserNode | null
  width: number
  height: number
}

const AudioVisualizer: React.FC<AudioVisualizerProps> = ({ analyser, width, height }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const theme = useTheme()
  const [isActive, setIsActive] = useState(true)

  useEffect(() => {
    if (!analyser || !canvasRef.current) return

    const canvas = canvasRef.current
    const canvasCtx = canvas.getContext('2d', { alpha: false })
    if (!canvasCtx) return

    const bufferLength = analyser.frequencyBinCount
    const dataArray = new Uint8Array(bufferLength)

    const barCount = 34 // Total number of bars (should be even for symmetry)
    const barWidth = width / barCount
    const centerY = height / 2
    const centerX = width / 2
    const maxBarHeight = height * 0.7 // Maximum height for the bars

    // Pre-calculate bar positions
    const barPositions = new Array(barCount).fill(0).map((_, i) => {
      if (i < barCount / 2) {
        return centerX - (i + 0.5) * barWidth
      } else {
        return centerX + (i - barCount / 2 + 0.5) * barWidth
      }
    })

    let animationFrameId: number

    const draw = () => {
      if (!isActive) {
        animationFrameId = requestAnimationFrame(draw)
        return
      }

      analyser.getByteFrequencyData(dataArray)

      canvasCtx.fillStyle = theme.palette.background.paper
      canvasCtx.fillRect(0, 0, width, height)

      for (let i = 0; i < barCount; i++) {
        const index = Math.floor((i * bufferLength) / barCount)
        const rawIntensity = dataArray[index] / 255
        const intensity = Math.pow(rawIntensity, 0.7)
        const barHeight = maxBarHeight * intensity
        const x = barPositions[i]

        // Draw the "always present" part of the bar with a dim color
        canvasCtx.fillStyle = theme.palette.background.default
        canvasCtx.fillRect(x, centerY - maxBarHeight, barWidth - 1, maxBarHeight)
        canvasCtx.fillRect(x, centerY, barWidth - 1, maxBarHeight)

        // Draw the colored part of the bar
        canvasCtx.fillStyle = theme.palette.primary.main
        canvasCtx.fillRect(x, centerY - barHeight, barWidth - 1, barHeight)
        canvasCtx.fillRect(x, centerY, barWidth - 1, barHeight)
      }

      animationFrameId = requestAnimationFrame(draw)
    }

    draw()

    return () => {
      cancelAnimationFrame(animationFrameId)
    }
  }, [analyser, width, height, theme, isActive])

  // Pause rendering when the tab is not visible
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsActive(!document.hidden)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return <canvas ref={canvasRef} width={width} height={height} />
}

export default AudioVisualizer
