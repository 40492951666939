import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { useEffectOnce, useTitle } from 'react-use'
import PageContainer from '../components/layout/PageContainer'
import { useRouteError } from 'react-router-dom'
import log from '../util/logging'

const ErrorPage = () => {
  useTitle('Clinical Notes AI - Error')

  const error = useRouteError()

  useEffectOnce(() => {
    log.error('ErrorPage', error)
  })

  return (
    <PageContainer>
      <Box sx={{ mt: 4, p: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom textAlign="center">
          Error occurred
        </Typography>
        <Typography variant="body1" gutterBottom textAlign="center">
          Sorry, it looks like an error occurred with the application
        </Typography>
        <Typography variant="body1" gutterBottom textAlign="center">
          <Link href="/">Go to the home page</Link>
        </Typography>
      </Box>
    </PageContainer>
  )
}

export default ErrorPage
