export const CNAIIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="543 747 800 800" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <style>
        {`
          .st0{fill:#FFFFFF;}
          .st1{fill:#300C50;}
          .st2{fill:url(#SVGID_3_);}
          .st3{fill:url(#SVGID_6_);}
          .st4{fill:url(#SVGID_9_);}
          .st5{fill:#F64D92;}
          .st6{fill:#AF3BF7;}
          .st7{fill:#FC9B69;}
          .st8{fill:url(#SVGID_12_);}
          .st9{fill:url(#SVGID_15_);}
          .st10{fill:url(#SVGID_18_);}
        `}
      </style>
      <linearGradient
        id="SVGID_3_"
        gradientUnits="userSpaceOnUse"
        x1="1231.9697"
        y1="1598.7207"
        x2="1238.614"
        y2="1598.7207"
        gradientTransform="matrix(20.1001 44.9568 44.9568 -20.1001 -95863.375 -22259.9727)"
      >
        <stop offset="0" stopColor="#FCD253" />
        <stop offset="0.0074" stopColor="#FCD253" />
        <stop offset="1" stopColor="#FB5086" />
      </linearGradient>
      <linearGradient
        id="SVGID_6_"
        gradientUnits="userSpaceOnUse"
        x1="1241.1373"
        y1="1618.1379"
        x2="1247.7816"
        y2="1618.1379"
        gradientTransform="matrix(31.5155 -49.6347 -49.6347 -31.5155 42109.2578 113838.8359)"
      >
        <stop offset="0" stopColor="#C835F8" />
        <stop offset="0.0054" stopColor="#C835F8" />
        <stop offset="1" stopColor="#FB5086" />
      </linearGradient>
      <linearGradient
        id="SVGID_9_"
        gradientUnits="userSpaceOnUse"
        x1="1245.1971"
        y1="1645.3658"
        x2="1251.8414"
        y2="1645.3658"
        gradientTransform="matrix(10.5301 -16.5842 -16.5842 -10.5301 15053.2646 38913.1289)"
      >
        <stop offset="0" stopColor="#C835F8" />
        <stop offset="0.0054" stopColor="#C835F8" />
        <stop offset="1" stopColor="#FB5086" />
      </linearGradient>
      <linearGradient
        id="SVGID_12_"
        gradientUnits="userSpaceOnUse"
        x1="1213.3965"
        y1="1629.5594"
        x2="1220.0408"
        y2="1629.5594"
        gradientTransform="matrix(19.165 -2.1381 -2.1381 -19.165 -18935.791 34839.6328)"
      >
        <stop offset="0" stopColor="#FCC259" />
        <stop offset="1" stopColor="#E746B2" />
      </linearGradient>
      <linearGradient
        id="SVGID_15_"
        gradientUnits="userSpaceOnUse"
        x1="1234.5585"
        y1="1606.3873"
        x2="1241.2028"
        y2="1606.3873"
        gradientTransform="matrix(63.7889 39.1985 39.1985 -63.7889 -140966.0938 55202.2656)"
      >
        <stop offset="0" stopColor="#0463EF" />
        <stop offset="0.9993" stopColor="#C835F8" />
        <stop offset="1" stopColor="#C835F8" />
      </linearGradient>
      <linearGradient
        id="SVGID_18_"
        gradientUnits="userSpaceOnUse"
        x1="1203.7908"
        y1="1577.7371"
        x2="1210.4351"
        y2="1577.7371"
        gradientTransform="matrix(5.8311 9.0966 9.0966 -5.8311 -20395.4375 -562.0949)"
      >
        <stop offset="0" stopColor="#5251F3" />
        <stop offset="0.9993" stopColor="#CA36F3" />
        <stop offset="1" stopColor="#CD38EC" />
      </linearGradient>
    </defs>
    <g>
      <g>
        <path
          className="st2"
          d="M767.715,997.978c-33.779,3.13-61.473,26.271-70.555,58.961l-48.304,173.821 c-7.349,26.424-1.708,52.569,15.886,73.619c15.82,18.929,37.427,29.089,61.493,29.089c2.691,0,5.395-0.133,8.133-0.385 l46.065-4.272l-3.867-41.666l-46.058,4.279c-13.408,1.242-25.036-3.561-33.66-13.873c-8.624-10.325-11.275-22.63-7.681-35.58 l48.31-173.827c4.458-16.046,17.528-26.962,34.098-28.497l208.69-19.335l-49.52,107.597l-190.332,18.032l117.696,165.216 l34.078-24.278l-75.592-106.129l142.074-13.455l89.91-195.335L767.715,997.978z"
        />
      </g>
      <g>
        <path
          className="st3"
          d="M949.164,827.094l7.176,41.228c13.335-2.325,25.202,1.502,34.663,11.142l126.381,128.746 c11.661,11.88,14.584,28.657,7.634,43.779l-87.611,190.398l-68.642-97.006l79.824-173.422l-201.68,18.684l3.867,41.666l129.49-12 l-59.612,129.517l124.467,175.9l117.902-256.249c14.179-30.816,7.987-66.37-15.773-90.581l-126.387-128.746 c-15.468-15.754-35.939-24.292-57.46-24.292C958.691,825.858,953.934,826.263,949.164,827.094"
        />
      </g>
      <g>
        <path
          className="st4"
          d="M889.485,873.052l-25.188,54.775l38.012,17.481l25.202-54.775c5.621-12.219,15.594-19.906,28.836-22.212 l-7.182-41.228C922.141,831.811,900.946,848.136,889.485,873.052"
        />
      </g>
      <g>
        <polygon
          className="st8"
          points="822.046,992.941 825.906,1034.608 980.272,1020.316 960.093,980.164"
        />
      </g>
      <g>
        <path
          className="st9"
          d="M740.534,1145.919l162.917,230.157c15.508,21.906,39.965,34.251,65.772,34.251 c6.804,0,13.714-0.864,20.564-2.625l174.684-45.081c26.564-6.857,46.383-24.81,55.818-50.57 c9.428-25.753,5.887-52.257-9.96-74.648l-32.125-45.374l-34.158,24.172l32.132,45.387c7.774,10.97,9.441,23.441,4.817,36.078 c-4.631,12.631-13.953,21.082-26.976,24.438l-174.684,45.081c-16.132,4.159-32.118-1.701-41.726-15.288l-121.138-171.136 l118.155-11.189l58.509,82.688l34.158-24.165l-72.502-102.461L740.534,1145.919z"
        />
      </g>
      <g>
        <rect
          className="st10"
          x="979.498"
          y="1166.729"
          transform="matrix(0.8163 -0.5776 0.5776 0.8163 -524.5926 803.1652)"
          width="41.846"
          height="119.212"
        />
      </g>
      <g transform="matrix(1.206565, 0, 0, 1.206565, -253.031509, -237.303207)">
        <path
          className="st5"
          d="M 841.686 902.971 C 820.35 908.689 807.692 930.617 813.41 951.953 C 819.12 973.279 841.057 985.946 862.382 980.229 C 883.717 974.508 896.375 952.581 890.667 931.247 C 885.876 913.38 869.713 901.598 852.059 901.598 C 848.634 901.598 845.155 902.043 841.686 902.971"
        />
        <path
          className="st6"
          d="M 1227.733 1083.798 C 1206.398 1089.517 1193.738 1111.444 1199.458 1132.78 C 1205.168 1154.106 1227.104 1166.774 1248.43 1161.055 C 1269.764 1155.337 1282.423 1133.409 1276.714 1112.073 C 1271.924 1094.209 1255.759 1082.426 1238.108 1082.426 C 1234.682 1082.426 1231.203 1082.868 1227.733 1083.798"
        />
        <path
          className="st7"
          d="M 857.063 1326.133 C 835.728 1331.852 823.069 1353.779 828.788 1375.116 C 834.497 1396.442 856.434 1409.109 877.76 1403.391 C 899.095 1397.672 911.754 1375.745 906.044 1354.408 C 901.254 1336.544 885.089 1324.761 867.438 1324.761 C 864.012 1324.761 860.533 1325.203 857.063 1326.133"
        />
      </g>
    </g>
  </svg>
)
