import { RTemplate } from '@counsel-project/counsel-external-api'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import Breadcrumbs, { BreadcrumbPath } from '../../../../components/Breadcrumbs'
import PageContainer from '../../../../components/layout/PageContainer'
import TemplateTable from '../../../../components/tables/TemplateTable'
import { externalRequest } from '../../../../util/api/external-api'
import getToken from '../../../../util/auth/getToken'
import handleError from '../../../../util/handleError'
import CreateTemplateDialog from './CreateTemplateDialog'
import ConfirmDialog from '../../../../components/ConfirmDialog'

const PlatformCustomerTemplatesPage = () => {
  useTitle('Customer Templates')

  const { id, customerIdentifier } = useParams()

  const [templates, setTemplates] = useState<RTemplate[]>([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(true)
  const [createTemplateOpen, setCreateTemplateOpen] = useState(false)
  const [deleteTemplateOpen, setDeleteTemplateOpen] = useState(false)
  const [stagedDeleteTemplate, setStagedDeleteTemplate] = useState<RTemplate | null>(null)

  const navigate = useNavigate()

  const handleCloseCreateTemplate = useCallback(() => {
    setCreateTemplateOpen(false)
  }, [])

  const handleCreateTemplate = useCallback(
    (template: RTemplate) => {
      setTemplates([template, ...templates])
    },
    [templates]
  )

  const handleOpenCreateTemplate = useCallback(() => {
    setCreateTemplateOpen(true)
  }, [])

  const handleDeleteStagedTemplate = useCallback(async () => {
    if (!stagedDeleteTemplate) return
    if (!id) return

    try {
      setDeleteTemplateOpen(false)

      const token = await getToken()
      if (!token) return

      await externalRequest.user.templates.remove({
        token,
        accessId: id,
        identifier: stagedDeleteTemplate.identifier,
      })

      setTemplates(templates.filter((t) => t.identifier !== stagedDeleteTemplate.identifier))
    } catch (err) {
      handleError(err)
    }
  }, [id, stagedDeleteTemplate, templates])

  const populateTemplates = useCallback(async () => {
    try {
      if (!id) return
      if (!customerIdentifier) return

      setLoading(true)

      const token = await getToken()
      if (!token) return

      const { results } = await externalRequest.user.templates.list({
        token,
        accessId: id,
        offset: page * limit,
        limit,
        search: search || undefined,
        customerIdentifier,
      })

      setTemplates(results)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [id, page, limit, search, customerIdentifier])

  useEffect(() => {
    const timeout = setTimeout(populateTemplates, 10)
    return () => clearTimeout(timeout)
  }, [populateTemplates])

  const handleClickDelete = useCallback((template: RTemplate) => {
    setStagedDeleteTemplate(template)
    setDeleteTemplateOpen(true)
  }, [])

  const paths: BreadcrumbPath[] = [
    {
      name: 'API',
      path: '/platform',
    },
    {
      name: 'Console',
      path: `/platform/${id}`,
    },
    {
      name: 'Dashboard',
      path: `/platform/${id}/dashboard`,
    },
    {
      name: 'Templates',
    },
  ]

  return (
    <PageContainer>
      <Breadcrumbs paths={paths} />
      <Grid container spacing={1}>
        <Grid item xs>
          <Typography variant="h4" gutterBottom>
            Customer Templates
          </Typography>
          <Typography variant="body1" gutterBottom>
            Click on a template to view or edit it.
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={handleOpenCreateTemplate}>Create Template</Button>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ p: 2 }}>
            <TemplateTable
              rows={templates}
              total={total}
              page={page}
              rowsPerPage={limit}
              onSubmitSearch={setSearch}
              onChangePage={setPage}
              onChangeRowsPerPage={setLimit}
              rowsPerPageOptions={[10, 25, 50]}
              loaded={!loading}
              onClickRow={(row) => {
                navigate(`/platform/${id}/templates/template/${row.identifier}`)
              }}
              onClickDelete={handleClickDelete}
            />
          </Paper>
        </Grid>
      </Grid>
      {id && customerIdentifier && (
        <CreateTemplateDialog
          accessId={id}
          customerIdentifier={customerIdentifier}
          open={createTemplateOpen}
          onClose={handleCloseCreateTemplate}
          onCreate={handleCreateTemplate}
        />
      )}
      <ConfirmDialog
        titleText="Delete Template"
        text={`Are you sure you want to delete this ${stagedDeleteTemplate?.name} template?`}
        open={deleteTemplateOpen}
        onClose={() => setDeleteTemplateOpen(false)}
        onConfirm={handleDeleteStagedTemplate}
      />
    </PageContainer>
  )
}

export default PlatformCustomerTemplatesPage
