import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useRequireAuth from '../util/auth/useRequireAuth'

const Root = () => {
  useRequireAuth('/')

  const navigate = useNavigate()

  useEffect(() => {
    navigate('/platform')
  }, [navigate])

  return null
}

export default Root
