import { RCustomer } from '@counsel-project/counsel-external-api/common/database/RCustomer'
import CustomerRow from './CustomerRow'
import TableBase from './TableBase'
import useMediaQuery from '@mui/material/useMediaQuery'

export type CustomerTableProps = {
  searchDisabled?: boolean
  rows: RCustomer[]
  page: number
  rowsPerPage: number
  rowsPerPageOptions: number[]
  total: number
  loaded: boolean
  onChangePage?: (page: number) => void
  onChangeRowsPerPage?: (rowsPerPage: number) => void
  onClickRow?: (row: RCustomer) => void
  onSubmitSearch?: (search: string) => void
  onClickDelete?: (row: RCustomer) => void
}

const CustomerTable = ({
  searchDisabled,
  rows,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  total,
  loaded,
  onChangePage,
  onChangeRowsPerPage,
  onClickRow,
  onSubmitSearch,
  onClickDelete,
}: CustomerTableProps) => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <TableBase
      searchDisabled={searchDisabled}
      columns={[
        { field: 'identifier', label: 'ID', sortable: false, align: 'left' },
        { field: 'createdAt', label: 'Created On', sortable: false, align: 'right' },
        {
          field: 'createdAt',
          label: 'Operations',
          sortable: false,
          align: 'right',
        },
      ]}
      rows={rows}
      renderRow={(row: RCustomer) => (
        <CustomerRow
          key={row._id}
          isMobile={isMobile}
          data={row}
          onClick={onClickRow}
          onClickDelete={onClickDelete}
        />
      )}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      total={total}
      loaded={loaded}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSubmitSearch={onSubmitSearch}
    />
  )
}

export default CustomerTable
