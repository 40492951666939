import useStore from '../store/auth'

const useUser = () => {
  const user = useStore((state) => state.user)
  const setUser = useStore((state) => state.setUser)

  return [user, setUser] as const
}

export default useUser
