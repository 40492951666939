import ClosableDialog from '../../components/ClosableDialog'
import { useCallback, useState } from 'react'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import getToken from '../../util/auth/getToken'
import { keysRequest } from '../../util/api/keys-api'
import { RAccess } from '@counsel-project/counsel-keys-api'

type AddMemberDialogProps = {
  accessId: string
  open: boolean
  onClose: () => void
  onUpdateAccess: (access: RAccess) => void
}

const AddMemberDialog = ({ accessId, open, onClose, onUpdateAccess }: AddMemberDialogProps) => {
  const [email, setEmail] = useState('')
  const [permission, setPermission] = useState<'admin' | 'member'>('member')

  const [loading, setLoading] = useState(false)

  const handleAddMember = useCallback(async () => {
    try {
      if (!email) return
      if (!permission) return

      setLoading(true)

      const token = await getToken()
      if (!token) return

      const { result } = await keysRequest.user.access.members.add({
        token,
        accessId,
        email,
        permission,
      })

      // Add member
      onUpdateAccess(result)
      onClose()
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [accessId, email, permission, onUpdateAccess, onClose])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Add Member">
      <DialogContent>
        <Typography variant="body1" gutterBottom fontWeight={500}>
          Email
        </Typography>
        <TextField
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
        />
        <Typography variant="body1" gutterBottom fontWeight={500} sx={{ mt: 2 }}>
          Permission
        </Typography>
        <TextField
          select
          placeholder="Permission"
          value={permission}
          onChange={(e) => setPermission(e.target.value as 'admin' | 'member')}
          fullWidth
        >
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="member">Member</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleAddMember}>
          Add
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default AddMemberDialog
