import ClosableDialog from '../../components/ClosableDialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { RAccess } from '@counsel-project/counsel-keys-api'
import { useCallback, useState } from 'react'
import getToken from '../../util/auth/getToken'
import { keysRequest } from '../../util/api/keys-api'

type CreateAccessDialogProps = {
  open: boolean
  onClose: () => void
  onCreated: (key: RAccess) => void
}

const CreateAccessDialog = ({ open, onClose, onCreated }: CreateAccessDialogProps) => {
  const [organization, setOrganization] = useState('')
  const [loading, setLoading] = useState(false)

  const handleCreate = useCallback(async () => {
    try {
      setLoading(true)

      const token = await getToken()
      if (!token) return

      const { result } = await keysRequest.admin.access.add({
        token,
        organization,
        limits: [
          {
            type: 'field_generation',
            limit: 100,
            interval: 1000 * 60 * 60 * 24,
            current: 0,
            reset: new Date(Date.now() + 1000 * 60 * 60 * 24).toString(),
          },
          {
            type: 'transcription_minutes',
            limit: 300,
            interval: 1000 * 60 * 60 * 24,
            current: 0,
            reset: new Date(Date.now() + 1000 * 60 * 60 * 24).toString(),
          },
        ],
      })

      onCreated(result)
      onClose()
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [organization, onCreated, onClose])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Create API Access">
      <DialogContent>
        <Typography variant="body1">Organization Name:</Typography>
        <TextField
          fullWidth
          value={organization}
          onChange={(e) => setOrganization(e.target.value)}
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleCreate} disabled={loading}>
          Create API Access
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default CreateAccessDialog
