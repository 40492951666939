import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import Cookies from 'js-cookie'

const useRequireAdmin = (altPage: string) => {
  const navigate = useNavigate()

  useEffect(() => {
    const tokenCookie = Cookies.get('token')

    if (!tokenCookie) {
      navigate(altPage)
      return
    }

    const decodedToken = JSON.parse(atob(tokenCookie.split('.')[1]))

    if (!decodedToken.admin) {
      navigate(altPage)
    }
  }, [navigate, altPage])
}

export default useRequireAdmin
